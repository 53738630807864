const InitialUploadSidebar = () => (
  <div className='initial-upload-sidebar'>
    <strong>Welcome to the</strong>
    <h2>Uploader</h2>
    <span>IMPORTANT</span>
    <p>Please make sure your photos meet the following requirements:</p>
    <ul>
      <li>
        <span className='outlined-number'>1</span>
        <p>All photos were taken at the same <strong>Location</strong></p>
      </li>
      <li>
        <span className='outlined-number'>2</span>
        <p>All photos are of the same <strong>Activity</strong></p>
      </li>
      <li>
        <span className='outlined-number'>3</span>
        <p>All photos contain <strong>EXIF data</strong></p>
      </li>
    </ul>
  </div>
);

export default InitialUploadSidebar;
