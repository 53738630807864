import { ReactElement, SVGProps } from 'react';

export default function MoneyIcon({
  className = 'money-icon',
  height = '59',
  width = '59',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M50.1308 19.7158C50.1302 19.7158 50.1295 19.7158 50.1288 19.7158H0.84473C0.378749 19.7153 0.000493323 20.0925 4.82312e-07 20.5585C4.82312e-07 20.5591 4.82312e-07 20.5599 4.82312e-07 20.5605V44.2169C-0.000492359 44.6828 0.376778 45.0611 0.842759 45.0616C0.843375 45.0616 0.844114 45.0616 0.84473 45.0616H34.3579C34.8244 45.0616 35.2027 44.6833 35.2027 44.2169C35.2027 43.7504 34.8244 43.3721 34.3579 43.3721H1.68946V21.4052H49.2841V23.5175C49.2836 23.9835 49.6609 24.3618 50.1269 24.3623C50.1275 24.3623 50.1282 24.3623 50.1288 24.3623C50.5948 24.3628 50.9731 23.9855 50.9736 23.5195C50.9736 23.5189 50.9736 23.5182 50.9736 23.5175V20.5605C50.9741 20.0945 50.5968 19.7164 50.1308 19.7158Z"
          fill="black"
        />
        <path
          d="M44.4 15.7937L4.97271 6.92254C4.75388 6.87363 4.52459 6.9133 4.33497 7.03294C4.1456 7.15356 4.01167 7.34417 3.96238 7.56323L1.99102 16.4344C1.88974 16.89 2.17706 17.3414 2.6327 17.4427C3.08833 17.544 3.53977 17.2567 3.64105 16.801L5.42809 8.7569L44.0294 17.4417C44.0905 17.4559 44.153 17.4629 44.2157 17.4624C44.6822 17.4616 45.0597 17.0827 45.0589 16.6162C45.0582 16.2218 44.7847 15.8805 44.4 15.7937Z"
          fill="black"
        />
        <path
          d="M39.6125 10.9057L15.9561 1.04888C15.545 0.877491 15.0712 1.05356 14.8719 1.45202L12.9005 5.39475C12.6779 5.80652 12.8311 6.32092 13.243 6.54356C13.6548 6.76621 14.1692 6.61293 14.3919 6.20104C14.4006 6.1849 14.4089 6.16839 14.4165 6.15163V6.1536L16.034 2.91662L38.96 12.4689C39.3917 12.649 39.8878 12.4452 40.0679 12.0135C40.248 11.582 40.0442 11.086 39.6125 10.9057Z"
          fill="black"
        />
        <path
          d="M40.2101 23.6585H32.3867C31.9202 23.6585 31.542 24.0368 31.542 24.5032C31.542 24.9697 31.9202 25.348 32.3867 25.348H40.2101C40.6765 25.348 41.0548 24.9697 41.0548 24.5032C41.0548 24.0368 40.6765 23.6585 40.2101 23.6585Z"
          fill="black"
        />
        <path
          d="M24.5007 23.7146C19.7101 23.7146 15.8267 27.5981 15.8267 32.3886C15.8316 37.177 19.7122 41.0577 24.5007 41.0626C29.2912 41.0626 33.1747 37.1791 33.1747 32.3886C33.1747 27.5981 29.2912 23.7146 24.5007 23.7146ZM24.5007 39.4855C20.5811 39.4855 17.4038 36.3082 17.4038 32.3886C17.4081 28.4709 20.5829 25.296 24.5007 25.2917C28.4202 25.2917 31.5976 28.469 31.5976 32.3886C31.5976 36.3082 28.4202 39.4855 24.5007 39.4855Z"
          fill="black"
        />
        <path
          d="M48.1575 26.4745C42.9333 26.4745 37.3149 27.7086 37.3149 30.4173V54.0737C37.3149 56.7823 42.9333 58.0164 48.1575 58.0164C53.3816 58.0164 59 56.7823 59 54.0737V30.4173C59 27.7086 53.3816 26.4745 48.1575 26.4745ZM57.0286 54.0589C56.8798 54.6059 53.9237 56.045 48.1575 56.045C42.3912 56.045 39.4351 54.6059 39.2863 54.0737V52.5242C41.3838 53.5808 44.8485 54.0737 48.1575 54.0737C51.4664 54.0737 54.9321 53.5759 57.0286 52.5232V54.0589ZM57.0286 50.1161C56.8798 50.6632 53.9237 52.1023 48.1575 52.1023C42.3912 52.1023 39.4351 50.6632 39.2863 50.1309V48.5814C41.3838 49.6381 44.8485 50.1309 48.1575 50.1309C51.4664 50.1309 54.9321 49.6332 57.0286 48.5805V50.1161ZM57.0286 46.1734C56.8798 46.7205 53.9237 48.1596 48.1575 48.1596C42.3912 48.1596 39.4351 46.7205 39.2863 46.1882V44.6387C41.3838 45.6954 44.8485 46.1882 48.1575 46.1882C51.4664 46.1882 54.9321 45.6904 57.0286 44.6377V46.1734ZM57.0286 42.2307C56.8798 42.7777 53.9237 44.2168 48.1575 44.2168C42.3912 44.2168 39.4351 42.7777 39.2863 42.2455V40.696C41.3838 41.7526 44.8485 42.2455 48.1575 42.2455C51.4664 42.2455 54.9321 41.7477 57.0286 40.695V42.2307ZM57.0286 38.288C56.8798 38.835 53.9237 40.2741 48.1575 40.2741C42.3912 40.2741 39.4351 38.835 39.2863 38.3027V36.7532C41.3838 37.805 44.8485 38.3027 48.1575 38.3027C51.4664 38.3027 54.9321 37.805 57.0286 36.7523V38.288ZM57.0286 34.3452C56.8798 34.8923 53.9237 36.3314 48.1575 36.3314C42.3912 36.3314 39.4351 34.8923 39.2863 34.36V32.8105C41.3838 33.8622 44.8485 34.36 48.1575 34.36C51.4664 34.36 54.9321 33.8622 57.0286 32.8095V34.3452ZM48.1575 32.3886C42.4001 32.3886 39.445 30.9535 39.2863 30.435V30.4291C39.445 29.8801 42.4001 28.4459 48.1575 28.4459C53.8744 28.4459 56.8315 29.8594 57.0286 30.4173C56.8315 30.9752 53.8744 32.3886 48.1575 32.3886Z"
          fill="black"
        />
        <path
          d="M16.6153 39.4284H9.16454L5.63185 35.5646V29.1576L9.53319 25.348H16.6153C17.0818 25.348 17.4601 24.9697 17.4601 24.5032C17.4601 24.0368 17.0818 23.6585 16.6153 23.6585H9.19017C8.96938 23.658 8.75708 23.744 8.59876 23.898L4.19571 28.1926C4.03123 28.3849 3.94104 28.6297 3.94141 28.8826V35.8928C3.94141 36.1036 4.02014 36.3068 4.1622 36.4625L8.16703 40.8429C8.32708 41.0181 8.55354 41.118 8.79097 41.1179H16.6153C17.0818 41.1179 17.4601 40.7396 17.4601 40.2732C17.4601 39.8067 17.0819 39.4284 16.6153 39.4284Z"
          fill="black"
        />
        <path
          d="M24.9938 31.403H24.0082C23.7016 31.403 23.5153 31.2315 23.5153 31.1565C23.5153 31.0816 23.7016 30.9101 24.0082 30.9101H26.4724C27.0167 30.9101 27.458 30.4688 27.458 29.9244C27.458 29.3801 27.0167 28.9387 26.4724 28.9387H25.4867C25.4867 28.3944 25.0453 27.9531 24.501 27.9531C23.9567 27.9531 23.5153 28.3944 23.5153 28.9387V28.9831C22.4221 29.1375 21.5938 30.0486 21.5439 31.1516C21.6145 32.444 22.7153 33.4369 24.0082 33.3743H24.9938C25.3004 33.3743 25.4867 33.5458 25.4867 33.6207C25.4867 33.6957 25.3004 33.8672 24.9938 33.8672H22.5296C21.9853 33.8672 21.5439 34.3085 21.5439 34.8529C21.5439 35.3972 21.9853 35.8385 22.5296 35.8385H23.5153C23.5153 36.3829 23.9567 36.8242 24.501 36.8242C25.0453 36.8242 25.4867 36.3829 25.4867 35.8385V35.7942C26.5799 35.6398 27.4081 34.7287 27.458 33.6257C27.3874 32.3333 26.2867 31.3404 24.9938 31.403Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="59" height="59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
