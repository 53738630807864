import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import TermsContactIcon from '../icons/terms_contact_icon';
import { Link } from 'react-router-dom';

const TermsContactSection = () => {
  return (
    <div className='col text-center'>
      <Card>
        <TermsContactIcon />
        <CardBody>
          <CardTitle>Terms & Conditions</CardTitle>
          <CardText>
            These are our rules in case
            <br />
            you were wondering.
          </CardText>
          <Link to='/terms-and-conditions'><Button variant="primary" className="text-center">View</Button></Link>
        </CardBody>
      </Card>
    </div>
  )
}

export default TermsContactSection;
