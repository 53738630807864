import { latLng, latLngBounds, LatLngBounds } from 'leaflet';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/root_reducer';
import { PhotoLocation } from '../../../types';

type UseLocationsReturnType = {
  bounds: LatLngBounds;
  locations: PhotoLocation[];
};

export default function useLocations(): UseLocationsReturnType {
  const locations = useSelector(
    ({ entities }: RootState) => entities.locations.collection
  );

  const bounds = useMemo(() => {
    return latLngBounds(locations.map(({ lat, lng }) => latLng(lat, lng)));
  }, [locations]);

  return { bounds, locations };
}
