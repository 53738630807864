import * as SessionApiUtil from '../util/api/session/session_api_util';
import * as FollowedSearchApiUtil from '../util/api/followed_search_api_util';

// action types
export const RECEIVE_CURRENT_USER = 'session/receiveCurrentUser';
export const RECEIVE_FOLLOWED_SEARCH = 'session/receiveFollowedSearch';
export const REMOVE_FOLLOWED_SEARCH = 'session/removeFollowedSearch';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS';
export const CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';
export const UPDATE_FOLLOWED_SEARCH = 'session/updateFollowedSearch';

// action creators
export const logoutCurrentUser = () => ({
  type: LOGOUT_CURRENT_USER,
});

export const receiveSessionErrors = (errors) => ({
  type: RECEIVE_SESSION_ERRORS,
  errors,
});

export const clearSessionErrors = () => ({
  type: CLEAR_SESSION_ERRORS,
});

export const receiveCurrentUser = (currentUser) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser,
});

export const receiveFollowedSearch = (followedSearch) => ({
  type: RECEIVE_FOLLOWED_SEARCH,
  followedSearch,
});

export const removeFollowedSearch = (followedSearchId) => ({
  type: REMOVE_FOLLOWED_SEARCH,
  followedSearchId,
});

export const updateFollowedSearch = (followedSearch) => ({
  type: UPDATE_FOLLOWED_SEARCH,
  followedSearch,
});

// thunk action creators
export const logout = () => (dispatch) => (
  SessionApiUtil.logout()
    .then(() => dispatch(logoutCurrentUser()))
);

export const getCurrentUser = () => (dispatch) => (
  SessionApiUtil.currentUser()
    .then((currentUser) => dispatch(receiveCurrentUser(currentUser)))
);

export const createFollowedSearch = (followedSearchParams) => (dispatch) => (
  FollowedSearchApiUtil.postFollowedSearch(followedSearchParams)
    .then((followedSearch) => dispatch(receiveFollowedSearch(followedSearch)))
);

export const destroyFollowedSearch = (id) => (dispatch) => (
  FollowedSearchApiUtil.deleteFollowedSearch(id)
    .then((deletedFs) => dispatch(removeFollowedSearch(deletedFs.id)))
);

export const updateFollowedSearchNotifications = (id) => (dispatch) => (
  FollowedSearchApiUtil.updateFollowedSearchNotifications(id)
    .then((updatedFs) => dispatch(updateFollowedSearch(updatedFs)))
)
