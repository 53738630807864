import { DateTime, Duration } from 'luxon';

class DateTools {
  constructor() {
    this.exifDateTemplate = 'yyyy:MM:DD HH:mm:ss';
    this.implodeTemplate = 'yyyy-M-d h:mma'
    this.timeFormat = 'hh:mm:ssA';
  }

  /**
   * Compare Times and return earlier and later time
   *
   * @param {String} timeOneISO
   * @param {String} timeTwoISO
   * @returns {[]}
   */
  getShifts(timeOneISO, timeTwoISO) {
    const t1 = DateTime.fromISO(timeOneISO);
    const t2 = DateTime.fromISO(timeTwoISO);

    const start = t1 < t2 ? t1 : t2;
    const end = t1 < t2 ? t2 : t1;

    const shifts = end.diff(start, ['years', 'months', 'days', 'hours', 'minutes']);

    return {
      shifts,
      plusOrMinus: t1 < t2 ? '+ plus' : '- minus',
    }
  }

  /**
   * Parse
   * @param {String} time
   * @returns Object
   */
  parseExifDate(time) {
    const dt = DateTime.fromFormat(time, 'yyyy:MM:dd HH:mm:ss');
    return {
      date: dt.toFormat('DDD'),
      dateShort: dt.toFormat('D'),
      time: dt.toFormat('h:mm:ssa'),
      timestamp: dt.toISO(),
    };
  }

  implodeDate(date) {
    return DateTime.fromFormat(date, this.implodeTemplate);
  }

  calculateDeltaInMillis(firstIso, newIso) {
    const end = DateTime.fromISO(newIso);
    const start = DateTime.fromISO(firstIso);
    return end.diff(start);
  }

  calculateDeltaInMilliseconds(firstIso, newIso) {
    const end = DateTime.fromISO(newIso);
    const start = DateTime.fromISO(firstIso);
    return end.diff(start).values.milliseconds;
  }

  createParsedTimesAndObject(jsDate) {
    const dt = DateTime.fromISO(jsDate);
    return this.formatParsedTimesAndObject(dt);
  }

  addDeltaMillis(isoDate, millis = 0) {
    const dt = DateTime.fromISO(isoDate).plus(millis);
    return this.formatParsedTimesAndObject(dt);
  }

  addDeltaMilliseconds(isoDate, millis = 0) {
    const dt = DateTime.fromISO(isoDate).plus(Duration.fromMillis(millis));
    return this.formatParsedTimesAndObject(dt);
  }


  formatParsedTimesAndObject(dateTime) {
    return {
      dateObject: dateTime.toJSDate(),
      parsedDate: dateTime.toFormat('DDD'),
      parsedTime: dateTime.toFormat('h:mma'),
      parsedTimestamp: dateTime.toISO(),
      photoTimestamp: dateTime.toISO(),
    }
  }

  formatSearchDate(isoDate) {
    const dt = DateTime.fromISO(isoDate);
    return dt.toFormat('yyyy-MM-dd');
  }

  formatDisplayDate(isoDate) {
    const dt = DateTime.fromISO(isoDate);
    return dt.toFormat('MMMM d, yyyy');
  }

  formatLabelTime(isoTime) {
    const dt = DateTime.fromISO(isoTime);
    return dt.toFormat('h:mma');
  }
}

export default new DateTools();
