import React from 'react';
import privacyBanner from 'images/privacy-policy-banner.png';

const PrivacyPolicyBanner = ({ text }) => {
  return (
    <div id="bannerContainer">
      <h1 id="bannerText">{text}</h1>
      <img id="privacyBanner" src={privacyBanner} alt="Privacy Policy Banner" />
    </div>
  );
}

export default PrivacyPolicyBanner;