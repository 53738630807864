import { RECEIVE_SEARCH_RESULT_PHOTOS, RECEIVE_SEARCH_RESULT_TIMES } from '../actions/search_actions';

const initialState = {
  photos: [],
  times: [],
};

const searchPhotosReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_SEARCH_RESULT_PHOTOS:
      return Object.assign({}, state, { photos: action.photos });
    case RECEIVE_SEARCH_RESULT_TIMES:
      return Object.assign({}, state, { times: action.times });
    default:
      return state;
  }
}

export default searchPhotosReducer;
