import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CartIcon from './../icons/cart_icon';


class StreamPhoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
    }

    this.handleImageClick = this.handleImageClick.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.photoFocused = this.photoFocused.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  addToCart() {
    const { photo } = this.props;
    const formData = {
      product_id: photo.id,
      price: photo.price,
    };
    this.props.createLineItem(formData);
  }

  handleImageClick(index) {
    return () => this.props.receiveFocusedPhoto(index);
  }

  photoFocused(id) {
    return this.props.focusedPhoto === id;
  }

  renderButton(photo) {
    return this.props.cartIds[photo.id] ?
      <Link to={`/cart`} className='btn outline-button outline-button-active photo-button'>
        View Cart
      </Link>
      :
      <button onClick={this.addToCart} className='btn outline-button photo-button'>
        Add to Cart ${photo.price}
      </button>
  }

  renderMobileButton(photo) {
    return this.props.cartIds[photo.id] ?
      <Link to={`/cart`} className='btn outline-button outline-button-active photo-button'>
        View Cart
      </Link>
      :
      <button onClick={this.addToCart} className='btn outline-button photo-button'>
        <CartIcon className="cart-icon" /> Add to Cart
      </button>
  }

  render() {
    const { photo, index, large, isMobile } = this.props;

    const containerClasses = classNames('search-photo-container', {
      'large-photo': large,
      'mobile-container': isMobile,
    });

    const imageClasses = classNames('search-photo', {
      // bug fix for images with 0 width and 0 height - shouldnt happen anymore
      'width-60': large && ((photo.width > photo.height) || photo.width === 0) && !isMobile,
      'width-40': large && (photo.height > photo.width) && !isMobile,
      'highlight-small-img': (this.photoFocused(index) && !large),
    });

    const largeMetadataClasses = classNames('search-photo-metadata', {
      'width-60': large && (photo.width < photo.height) && !isMobile,
      'width-40': large && (photo.height < photo.width) && !isMobile,
    })

    let metaDataComponent = null;

    if (large) {
      metaDataComponent = <div className={largeMetadataClasses}>
        <span className='search-photo-time'>
          {photo.photoTime} | <span>{photo.photoDate}</span>
        </span>
        <table>
          <tbody>
            <tr>
              <td>Location:</td>
              <td>{photo.location}</td>
            </tr>
            <tr>
              <td>Activity:</td>
              <td>{photo.activity}</td>
            </tr>
            <tr>
              <td>Photographer:</td>
              <td>{photo.photographer}</td>
            </tr>
            <tr>
              <td>File:</td>
              <td>JPEG | {photo.width}x{photo.height}</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>{photo.name}</td>
            </tr>
            <tr>
              <td>License:</td>
              <td>Personal Use</td>
            </tr>
          </tbody>
        </table>
        <div className='photo-buttons'>
          {this.renderButton(photo)}
        </div>
      </div>;
    }

    if (large && isMobile) {
      metaDataComponent = <div className='search-photo-mobile-metadata'>
        <div className='d-flex'>
          <div className=''>
            <p className="mobile-time">{photo.photoTime} | <span>{photo.photoDate}</span></p>
            <p className="mobile-metadata">JPEG | {photo.width}x{photo.height} | {photo.fileSize}</p>
            <p className='mobile-price'>${photo.price}</p>
          </div>
          <div className='mobile-photo-buttons'>
            {this.renderMobileButton(photo)}
          </div>
        </div>
      </div>
    }

    return (
      <div className={containerClasses}>
        <div className={'search-photo-inner row no-gutters'}>
          <img
            loading="lazy"
            className={imageClasses}
            src={large ? photo.watermarkUrl : photo.thumbnailUrl}
            onClick={this.handleImageClick(index)}
          />
          <div className='search-photo-hover'>
            <span>{photo.photoTime}</span>
            <span>${photo.price}</span>
          </div>
          {metaDataComponent}
        </div>
      </div>
    );
  }
}

export default StreamPhoto;
