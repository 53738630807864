import { ReactElement, RefObject } from 'react';
import {
  Button,
  Detail,
  Heading,
  Input,
  Metadata,
  Modal,
  ModalRef,
  ModalProps,
  Panel,
} from '../../components/library';

export default function PhotoModal({
  modalRef,
  photo,
  closePhotoModal,
}): ReactElement {
  const tags = photo.tags || [];

  return (
    <Modal className='photo-modal' ref={modalRef}>
      <form className="photo-modal__form">
        <Heading level={1}>Image Properties</Heading>
        <Input
          readOnly
          autoFocus
          name="name"
          value={photo.name}
        >
          Name
        </Input>
        <Input
          readOnly
          className="input--price"
          name="price"
          type="number"
          step="0.01"
          placeholder="0.00"
          value={photo.inputPrice}
        >
          Price
        </Input>
        <div className="photo-modal__tags">
          <label className="input__label">Tags</label>
          {tags.map((tag, i) => <span key={i}>#{tag}</span>)}
        </div>
        <Panel>
          <Metadata className="static-data">
            <Detail label="Location:">{photo.locationName}</Detail>
            <Detail label="Activity:">{photo.activityName}</Detail>
            <Detail label="Time taken:">{photo.dateTime}</Detail>
            <Detail label="Dimensions:">{photo.width}x{photo.height}</Detail>
            <Detail label="File Type:">JPG</Detail>
          </Metadata>
        </Panel>
        <Button className="photo-modal__button" onClick={closePhotoModal}>
          Close
        </Button>
      </form>
      <div className="photo-modal__image">
        <img src={photo.watermarkUrl} />
      </div>
    </Modal>
  )
}
