import React from 'react';

const dataStorageData = {
    rows: [
        {
            title: "Data Storage",
            content: "WunderPics, Inc. uses third party vendors to host and store media that you upload to Site. We may require our vendors to maintain certain standards with respect to your content, but the Site does not guarantee that your content will be secure and/or available at all times. It is your sole responsibility to create and maintain backup copies of any such content on a regular basis."
        } 
    ]
}

export default dataStorageData;