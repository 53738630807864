import { Dispatch } from 'redux';
import { PhotoLocation, User } from '../types';
import * as LocationApiUtil from '../util/api/location_api_util';

// action types
export const RECEIVE_LOCATIONS_WITH_PHOTOS = 'entities/locations/receiveLocationsWithPhotos';

// action creators
type AddLocation = {
  type: 'ADD_LOCATION';
  location: PhotoLocation;
};

type RemoveLocation = {
  type: 'REMOVE_LOCATION';
  locationId: PhotoLocation['id'];
};

type SetLocations = {
  type: 'SET_LOCATIONS';
  locations: PhotoLocation[];
};

type ReceiveLocationsWithPhotos = {
  type: 'entities/locations/receiveLocationsWithPhotos';
  locations: PhotoLocation[];
}

type SetLocationSelection = {
  type: 'SET_LOCATION_SELECTION';
  location: PhotoLocation;
};

type UnsetLocationSelection = {
  type: 'UNSET_LOCATION_SELECTION';
};

export type LocationAction =
  | AddLocation
  | RemoveLocation
  | SetLocations
  | SetLocationSelection
  | UnsetLocationSelection
  | ReceiveLocationsWithPhotos;

// action creators
const addLocation = (location: PhotoLocation): AddLocation => ({
  type: 'ADD_LOCATION',
  location,
});

const removeLocation = (locationId: PhotoLocation['id']): RemoveLocation => ({
  type: 'REMOVE_LOCATION',
  locationId,
});

const setLocations = (locations: PhotoLocation[]): SetLocations => ({
  type: 'SET_LOCATIONS',
  locations,
});

const receiveLocationsWithPhotos = (locations: PhotoLocation[]) => ({
  type: RECEIVE_LOCATIONS_WITH_PHOTOS,
  locations,
})

export const setLocationSelection = (
  location: PhotoLocation
): SetLocationSelection => ({
  type: 'SET_LOCATION_SELECTION',
  location,
});

export const unsetLocationSelection = (): UnsetLocationSelection => ({
  type: 'UNSET_LOCATION_SELECTION',
});

// thunk action creator

export const createLocation =
  (location: Pick<PhotoLocation, 'lat' | 'lng' | 'name'>) =>
  async (dispatch: Dispatch<LocationAction>): Promise<PhotoLocation> => {
    const newLocation = await LocationApiUtil.postLocation(location);
    dispatch(addLocation(newLocation));

    return newLocation;
  };

export const destroyLocation =
  (locationId: PhotoLocation['id'], userId: User['id']) =>
  async (dispatch: Dispatch<LocationAction>): Promise<void> => {
    await LocationApiUtil.deleteLocation(locationId, userId);
    dispatch(removeLocation(locationId));
  };

export const fetchLocations =
  () =>
  async (dispatch: Dispatch<LocationAction>): Promise<void> => {
    const locations = await LocationApiUtil.getLocations();
    dispatch(setLocations(locations));
  };

export const fetchLocationsWithPhotos =
  () =>
  async (dispatch: Dispatch<LocationAction>): Promise<void> => {
    const locations = await LocationApiUtil.getLocationsWithPhotos();
    dispatch(receiveLocationsWithPhotos(locations));
  };
