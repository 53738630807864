import { connect } from 'react-redux';
import { deleteLineItem } from '../../actions/cart_actions';
import CartPage from './cart_page';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  deleteLineItem: (lineItem) => dispatch(deleteLineItem(lineItem)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartPage);
