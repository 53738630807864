import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { LayerGroup, Tooltip, useMapEvents } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { createLocation } from '../../actions/location_actions';
import { selectOptionFormat } from '../../reducers/selectors';
import { useNewMarker } from '../cartography/hooks';
import GenericMap from '../generic/generic_map';
import WpMapIconSelected from '../icons/wp_map_icon_selected';
import WPMarker from '../icons/wp_marker';
import { getGeoLocationFromIp } from '../../util/api/location_api_util';


interface SetLocationProps {
  value?: { label: string, value: number };
  handleSelectLocation: any;
  locations: any[];
  addLocation: any;
}

export default function SetLocation({
  value,
  handleSelectLocation,
  locations,
  cancelClick = null,
  handleSubmit,
  displayModalButtons = true,
}: SetLocationProps): ReactElement {

  const dispatch = useDispatch();
  const [addingLocation, setAddingLocation] = useState<boolean>(false);
  const [userLat, setUserLat] = useState('');
  const [userLng, setUserLng] = useState('');
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const setCenter = async () => {
  //     if (loading) {
  //       getGeoLocationFromIp().then(response => {
  //         let lat = response.data.geo.latitude || 33.6554414727159;
  //         let lng = response.data.geo.longitude || -118.003168702126;
  //         setUserLat(lat);
  //         setUserLng(lng);
  //         setLoading(false);
  //       });
  //     }
  //   }

  //   setCenter();
  // }, [userLat, userLng]);

  const {
    newMarkerEventHandlers,
    newMarkerMapEventHandlers,
    newMarkerName,
    newMarkerPosition,
    setNewMarkerName,
    setNewMarkerPosition,
  } = useNewMarker();

  const handleToggleAddLocation = () => {
    setAddingLocation(true);
    handleSelectLocation('');
  }

  const handleCreateLocation = async () => {
    const location = await dispatch(
      createLocation({
        lat: newMarkerPosition.lat,
        lng: newMarkerPosition.lng,
        name: newMarkerName,
      })
    );

    setNewMarkerName('');
    setNewMarkerPosition(null);

    handleSelectLocation({ value: location.id, label: location.name });
    setAddingLocation(false);
  }

  let centerLocation;
  if (value) {
    centerLocation = locations.filter(loc => loc.id === value.value)[0];
  }

  const modalBtnClasses = classNames('btn', { 'invisible': addingLocation })

  // if (loading) return <></>;

  return (
    <>
      <div className='row'>
        {addingLocation ?
        <div className='col-6 flex-column'>
          <h4>Add a Location</h4>
          <p>Double click the map to add a pin. Then drag the pin to your new location and click <strong>Save</strong>.</p>
          <input
            className='form-control'
            onChange={(e) => setNewMarkerName(e.target.value)}
            value={newMarkerName}
            placeholder='Enter the name of this location'
          />
          {newMarkerPosition ?
            <p>{`${newMarkerPosition.lat}, ${newMarkerPosition.lng}`}</p> : <p>&nbsp;</p>}
          <div className='adding-location-buttons'>
            <button
              className='btn btn-primary'
              onClick={() => setAddingLocation(false)}
            >
              Back
            </button>
            <button
              className='btn btn-warning'
              disabled={!newMarkerName || !newMarkerPosition}
              onClick={() => handleCreateLocation()}
            >
              Save New Location
            </button>
          </div>
        </div> :
        <div className='col-6'>
          <h4>Where did you take these photos?</h4>
          <CreatableSelect
            name='upload-location'
            placeholder='Search locations'
            value={value}
            className='upload-location-select upload-select'
            classNamePrefix='upload-select'
            onChange={handleSelectLocation}
            options={selectOptionFormat(locations)}
            formatCreateLabel={() => <strong>No Results  - Add new location</strong>}
            onCreateOption={(value) => {
              setNewMarkerName(value)
              handleToggleAddLocation()
            }}
          />
          {value ?
            <div className='pre-upload-chosen-location'>
              <WpMapIconSelected /><br />
              <strong>{value.label}</strong>
            </div>
            : null}
        </div>}
        <div className='col-6 pre-upload-map'>
          <GenericMap
            addingLocation={addingLocation}
            doubleClickZoom={false}
            zoom={10}
            locations={locations}
            centerLocation={addingLocation ? '' : centerLocation}
            // lat={userLat}
            // lng={userLng}
            handleSelectLocation={addingLocation ? null : handleSelectLocation}
          >
            {addingLocation &&
              <NewLocationLayer
                newMarkerMapEventHandlers={newMarkerMapEventHandlers}
                newMarkerEventHandlers={newMarkerEventHandlers}
                newMarkerPosition={newMarkerPosition}
                newMarkerName={newMarkerName}
              />}
          </GenericMap>
        </div>
      </div>
      {displayModalButtons &&
      <>
        <hr />
        <div className='row modal-buttons'>
          <button
            className={modalBtnClasses}
            onClick={() => cancelClick()}
            disabled={addingLocation}
          >
            Close
          </button>
          <button
            onClick={() => handleSubmit()}
            className={modalBtnClasses}
            disabled={addingLocation}
          >
            Set Location
          </button>
        </div>
      </>}
    </>
  );
}

function NewLocationLayer({
  newMarkerName,
  newMarkerMapEventHandlers,
  newMarkerEventHandlers,
  newMarkerPosition,
}) {
  useMapEvents({
    ...newMarkerMapEventHandlers
  });

  return (
    <LayerGroup>
      {newMarkerPosition && (
        <WPMarker
          eventHandlers={{
            ...newMarkerEventHandlers,
          }}
          isEditing={true}
          position={newMarkerPosition}
          selected={true}
        >
          {newMarkerName && (
            <Tooltip direction="top" permanent>
              <div>{newMarkerName}</div>
            </Tooltip>
          )}
        </WPMarker>
      )}
    </LayerGroup>
  )
}
