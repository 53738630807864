import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
  loggedIn: Boolean(state.session.id),
});

/**
 * If user is already auth'ed, redirect to homepage
 *  This makes it so user doesn't view login page again
 */
const Auth = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={(props) =>
      loggedIn ? <Redirect to='/' /> : <Component {...props} />
    }
  />
);

/**
 * User is not able to access this page unless loggedIn
 */
const Protected = ({component: Component, path, loggedIn, exact }) => {
  if (!loggedIn) {
    return window.location.href = '/users/sign_in';
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to='/users/sign_in' />
      }
    />
  );
};

export const AuthRoute = withRouter(connect(mapStateToProps)(Auth));
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));
