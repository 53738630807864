import { combineReducers } from 'redux';
import usersReducer from './users_reducer';
import locationsReducer from './locations_reducer';
import activitiesReducer from './activities_reducer';
import tagsReducer from './tags_reducer';

const entitiesReducer = combineReducers({
  users: usersReducer,
  locations: locationsReducer,
  activities: activitiesReducer,
  tags: tagsReducer,
});

export default entitiesReducer;
