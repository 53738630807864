import { ReactElement, SVGProps } from 'react';

export default function TrashIcon ({
  className='trash-icon',
  height = '46',
  width = '42',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg className={className} width={width} height={width} viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.33301 11.3333H39.6663" stroke="#9B9B9B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.333 20.6667V34.6667" stroke="#9B9B9B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.666 20.6667V34.6667" stroke="#9B9B9B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.66602 11.3333L6.99935 39.3333C6.99935 40.571 7.49101 41.758 8.36618 42.6332C9.24135 43.5083 10.4283 44 11.666 44H30.3327C31.5704 44 32.7573 43.5083 33.6325 42.6332C34.5077 41.758 34.9994 40.571 34.9994 39.3333L37.3327 11.3333" stroke="#9B9B9B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 11.3333V4.33333C14 3.71449 14.2458 3.121 14.6834 2.68342C15.121 2.24583 15.7145 2 16.3333 2H25.6667C26.2855 2 26.879 2.24583 27.3166 2.68342C27.7542 3.121 28 3.71449 28 4.33333V11.3333" stroke="#9B9B9B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
