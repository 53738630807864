import { LeafletEventHandlerFnMap, LeafletMouseEventHandlerFn } from 'leaflet';
import { ReactElement, useCallback, useState } from 'react';
import { LayerGroup, LayerGroupProps, useMapEvents } from 'react-leaflet';
import { callAll } from '../../util/function_util';

interface MarkerLayerProps extends LayerGroupProps {
  mapEventHandlers?: LeafletEventHandlerFnMap;
  children: (props: MarkerLayerChildrenProps) => ReactElement;
}

interface MarkerLayerChildrenProps {
  activeMarkerId: number | null;
  setActiveMarkerId: (marker: number | null) => void;
}

export default function MarkerLayer({
  mapEventHandlers = {},
  children,
}: MarkerLayerProps): ReactElement {
  const [activeMarkerId, setActiveMarkerId] = useState<number | null>(null);

  const handleClick: LeafletMouseEventHandlerFn = useCallback(
    () => setActiveMarkerId(null),
    []
  );
  useMapEvents({
    ...mapEventHandlers,
    click: callAll(mapEventHandlers?.click, handleClick),
  });

  return (
    <LayerGroup>{children({ activeMarkerId, setActiveMarkerId })}</LayerGroup>
  );
}
