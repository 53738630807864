import React from 'react';

class UserProfilePhotoItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { photo } = this.props;
    return (
      <div className='user-profile-stream-photo-flex'>
        <div className='user-profile-stream-photo'>
          <img src={photo.thumbnail_url} />
        </div>
      </div>
    );
  }
}

export default UserProfilePhotoItem;
