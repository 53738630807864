import Faq from 'react-faq-component';
import SEO from '../generic/seo';
import ScrollToTop from '../scroll_to_top';
import Footer from './../footer';
import HelpBanner from './help_banner';
import { BuyerGuideData, BuyerGuideStyles } from './help_buyer_guide_data';
import { faqData, styles } from './help_faq_data';
import HelpLeftNavBar from './help_left_nav_bar';
import { SellerGuideData, SellerGuideStyles } from './help_seller_guide_data';

const Help = (props) => {
  return (
    <>
      <ScrollToTop />
      <SEO title='Help' />
      <div id='content'>
        <HelpBanner text='Hi! How can we help?' />
        <div className='page-container'>
          <div className='row no-gutters'>
            <div className='col-lg-3' id='help'>
              <div className="sticky-top">
                <HelpLeftNavBar faqContent={faqData} sellerGuideContent={SellerGuideData} buyerGuideContent={BuyerGuideData}></HelpLeftNavBar>
              </div>
            </div>
            <div className='col-lg-9'>
              <dl>
                <div>
                  <br />
                  <br id="faq" />
                  <Faq data={faqData} styles={styles} />
                  <br />
                  <br />
                  <br />
                  <div id="sellerGuide" >
                    <Faq data={SellerGuideData} styles={SellerGuideStyles} />
                  </div>
                  <br />
                  <br />
                  <br />
                  <div id="buyerGuide">
                    <Faq data={BuyerGuideData} styles={BuyerGuideStyles} />
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Help;
