import { ReactElement } from 'react';

export default function UploadingIcon(props: any): ReactElement {
  return (
    <svg {...props} width="72" height="49" viewBox="0 0 72 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.5438 14.3042V20.1035H5.54281V18.021L9.49605 14.0138C9.56882 13.9401 9.68389 13.9401 9.75655 14.0138L12.9372 17.2378L13.2932 17.5986L13.6491 17.2378L21.4132 9.36786C21.486 9.29411 21.6011 9.29417 21.6738 9.36782L26.5438 14.3042ZM4.1261 24.8202H27.9605C29.7619 24.8202 31.2106 23.342 31.2106 21.5327V4.80712C31.2106 2.99782 29.7619 1.51953 27.9605 1.51953H4.1261C2.32473 1.51953 0.875977 2.99782 0.875977 4.80712V21.5327C0.875977 23.342 2.32473 24.8202 4.1261 24.8202ZM5.08445 8.52391C5.08445 6.99751 6.30343 5.77172 7.79293 5.77172C9.28243 5.77172 10.5014 6.99751 10.5014 8.52391C10.5014 10.0503 9.28243 11.2761 7.79293 11.2761C6.30343 11.2761 5.08445 10.0503 5.08445 8.52391Z" fill="#BEBEBE" stroke="#E9E9E9" strokeLinecap="round"/>
      <path d="M20.5796 47.0761H56.8727C60.0643 47.0761 62.9093 44.6675 62.9093 41.4357V14.5232C62.9093 11.2915 60.0643 8.88281 56.8727 8.88281H20.5796C17.388 8.88281 14.543 11.2915 14.543 14.5232V41.4357C14.543 44.6675 17.388 47.0761 20.5796 47.0761Z" fill="#DDDDDD" stroke="#F0F0F0" strokeWidth="3"/>
      <path d="M51.1766 22.928C53.0605 22.928 54.5876 21.3679 54.5876 19.4435C54.5876 17.519 53.0605 15.959 51.1766 15.959C49.2928 15.959 47.7656 17.519 47.7656 19.4435C47.7656 21.3679 49.2928 22.928 51.1766 22.928Z" fill="#F3F3F3"/>
      <path d="M33.0983 18.3965L20.4775 39.6519H45.719L33.0983 18.3965Z" fill="#939393"/>
      <path d="M46.2306 25.7148L37.1914 39.6528H55.2697L46.2306 25.7148Z" fill="#C0C0C0"/>
      <circle cx="63.1463" cy="8.4774" r="7.67076" fill="white"/>
      <path d="M63.1462 16.9564C61.6117 16.9564 60.1932 16.5783 58.8905 15.8221C57.5879 15.0659 56.5585 14.0365 55.8023 12.7339C55.0461 11.4312 54.668 10.0127 54.668 8.4782C54.668 6.94374 55.0461 5.52518 55.8023 4.22254C56.5585 2.9199 57.5879 1.89049 58.8905 1.13429C60.1932 0.378097 61.6117 0 63.1462 0C64.6806 0 66.0992 0.378097 67.4018 1.13429C68.7045 1.89049 69.7339 2.9199 70.4901 4.22254C71.2463 5.52518 71.6244 6.94374 71.6244 8.4782C71.6244 10.0127 71.2463 11.4312 70.4901 12.7339C69.7339 14.0365 68.7045 15.0659 67.4018 15.8221C66.0992 16.5783 64.6806 16.9564 63.1462 16.9564ZM55.7277 8.4782C55.7277 9.48278 55.9237 10.4432 56.3156 11.3595C56.7075 12.2757 57.2346 13.065 57.897 13.7274C58.5593 14.3898 59.3486 14.9169 60.2649 15.3088C61.1812 15.7007 62.1416 15.8966 63.1462 15.8966C64.1508 15.8966 65.1112 15.7007 66.0274 15.3088C66.9437 14.9169 67.733 14.3898 68.3954 13.7274C69.0577 13.065 69.5849 12.2757 69.9768 11.3595C70.3687 10.4432 70.5646 9.48278 70.5646 8.4782H63.1462V1.05978C62.1416 1.05978 61.1812 1.25572 60.2649 1.64762C59.3486 2.03952 58.5593 2.56664 57.897 3.229C57.2346 3.89136 56.7075 4.68068 56.3156 5.59694C55.9237 6.5132 55.7277 7.47363 55.7277 8.4782Z" fill="black"/>
    </svg>
  );
}
