import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';

interface MetadataProps {
  className: string;
}

const MetadataContext = createContext<MetadataProps>({ className: '' });

export function Metadata({
  className,
  children,
}: PropsWithChildren<MetadataProps>): ReactElement {
  if (className === '' || className === null || className === undefined) {
    throw new Error('"className" is a required prop.');
  }

  return (
    <MetadataContext.Provider value={{ className }}>
      <div className={className}>{children}</div>
    </MetadataContext.Provider>
  );
}

interface DetailProps {
  label?: string;
}

export function Detail({
  label,
  children,
}: PropsWithChildren<DetailProps>): ReactElement {
  const { className } = useMetadataContext();

  return (
    <div className={`${className}__detail`}>
      <label className={`${className}__label`}>{label}</label>
      <div className={`${className}__data`}>{children}</div>
    </div>
  );
}

function useMetadataContext() {
  const metadataContext = useContext(MetadataContext);

  if (metadataContext === undefined) {
    throw new Error(
      'Consume MetadataContext within a MetadataContext.Provider!'
    );
  }

  return metadataContext;
}
