export default function GroupImagesIcon() {
  return (
    <svg width="73" height="63" viewBox="0 0 73 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.1669 60.6726H64.338C67.7963 60.6726 70.8594 58.0648 70.8594 54.5898V24.8016C70.8594 21.3265 67.7963 18.7188 64.338 18.7188H24.1669C20.7087 18.7188 17.6455 21.3265 17.6455 24.8016V54.5898C17.6455 58.0648 20.7087 60.6726 24.1669 60.6726Z" fill="#DDDDDD" stroke="#F0F0F0" strokeWidth="3"/>
      <path d="M58.0333 34.1033C60.1184 34.1033 61.8088 32.3765 61.8088 30.2465C61.8088 28.1164 60.1184 26.3896 58.0333 26.3896C55.9482 26.3896 54.2578 28.1164 54.2578 30.2465C54.2578 32.3765 55.9482 34.1033 58.0333 34.1033Z" fill="#F3F3F3"/>
      <path d="M38.023 29.0908L24.0537 52.6174H51.9923L38.023 29.0908Z" fill="#939393"/>
      <path d="M52.5587 37.1885L42.5537 52.6157H62.5638L52.5587 37.1885Z" fill="#C0C0C0"/>
      <path d="M9.20069 47.6326H53.6812C57.4357 47.6326 60.7413 44.8037 60.7413 41.0582V8.07443C60.7413 4.32898 57.4357 1.5 53.6812 1.5H9.20069C5.4462 1.5 2.14062 4.32898 2.14062 8.07443V41.0582C2.14062 44.8037 5.4462 47.6326 9.20069 47.6326Z" fill="#DDDDDD" stroke="#F0F0F0" strokeWidth="3"/>
      <path d="M46.7 18.3741C49.0089 18.3741 50.8805 16.4621 50.8805 14.1036C50.8805 11.745 49.0089 9.83301 46.7 9.83301C44.3912 9.83301 42.5195 11.745 42.5195 14.1036C42.5195 16.4621 44.3912 18.3741 46.7 18.3741Z" fill="#F3F3F3"/>
      <path d="M24.543 12.8232L9.0752 38.8736H40.0109L24.543 12.8232Z" fill="#939393"/>
      <path d="M40.6379 21.7891L29.5596 38.8713H51.7162L40.6379 21.7891Z" fill="#C0C0C0"/>
    </svg>
  );
}
