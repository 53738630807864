import { ChangeEventHandler, ReactElement, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Heading, Input, Panel } from '../library';

export default function Notifications(): ReactElement {
  const alert = useAlert();

  const [notificationInputs, setNotificationInputs] = useState({
    newsletter: false,
    followedSearch: false,
    sale: false,
  });

  const handleNotificationInputChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setNotificationInputs({
      ...notificationInputs,
      [e.target.name]: e.target.checked,
    });
  };

  const handleNotificationsFormSubmit = () => {
    alert.show('Coming soon!');
  }

  return (
    <Panel className="notifications">
      <Heading level={1}>Email notifications</Heading>
      <Input
        name="newsletter"
        onChange={handleNotificationInputChange}
        type="checkbox"
      >
        WunderPics Newsletter
      </Input>
      <Input
        name="followedSearch"
        onChange={handleNotificationInputChange}
        type="checkbox"
      >
        Your &quot;Followed Search&quot; gets update with new photos
      </Input>
      <Input
        name="sale"
        onChange={handleNotificationInputChange}
        type="checkbox"
      >
        Your photo has sold
      </Input>
      <Button onClick={handleNotificationsFormSubmit}>Save</Button>
    </Panel>
  );
}
