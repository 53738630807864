import React from 'react';

const internationalData = {
    rows: [
        {
            title: "International Users",
            content: "The Service is controlled, operated and administered by WunderPics, Inc. from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the WunderPics, Inc. Content accessed through WunderPics.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations."
        }
    ]
}

export default internationalData;