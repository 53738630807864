import PublishPhotos1 from 'images/uploader_guide/publish-photos-1.png';

const PublishPhotosData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>In the last step of the Uploader, you will have the option to publish your photos for sale, to do this select “Publish for sale”</li>
        <li>If you would like to customize the Price and Tags of individual photos, you can do so in the Upload Editor. Select “Continue to Upload Editor”</li>
        <img src={PublishPhotos1} alt='Publish Photos Img1' />
      </ul>
    </div>
  )
}

export default PublishPhotosData