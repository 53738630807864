import Footer from '../footer';
import FAQfromContact from './faq_contact_section.jsx';
import ContactUs from './contact_us_section';
import TermsfromContact from './terms_contact_section';

const Contact = () => {
  return (
    <>
      <div className='contact-page'>
        <div className='container'>
          <div className='row row-1'>
            <h2>Questions?</h2>
          </div>
          <div className='row row-2'>
            <FAQfromContact />
            <TermsfromContact />
            <ContactUs />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
