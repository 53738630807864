import { connect } from 'react-redux';
import {
  searchPhotos,
  setSearchLocation,
  receiveSearchDate,
  setSearchActivity,
  receiveSearchTime,
  receiveSearchTimeEnd,
  setSearchPhotographer,
  setSearchTags,
} from '../../actions/search_actions';
import { receiveFocusedPhoto } from '../../actions/ui_actions';
import Stream from './stream';

const mapStateToProps = (state) => ({
  photos: state.ui.searchPhotos.photos,
  times: state.ui.searchPhotos.times,
  searched: state.ui.searchParams.searched,
  cart: state.cart,
  focusedPhoto: state.ui.focusedPhoto,
  bannerHeight: state.ui.dimensions.bannerHeight,
  searchLocation: state.ui.searchParams.location,
  searchString: state.ui.searchParams.searchString,
});

const mapDispatchToProps = (dispatch) => ({
  searchPhotos: () => dispatch(searchPhotos()),
  setSearchLocation: (locationId) => dispatch(setSearchLocation(locationId)),
  receiveSearchDate: date => dispatch(receiveSearchDate(date)),
  setSearchActivity: activityId => dispatch(setSearchActivity(activityId)),
  receiveSearchTime: time => dispatch(receiveSearchTime(time)),
  receiveSearchTimeEnd: timeEnd => dispatch(receiveSearchTimeEnd(timeEnd)),
  setSearchTags: tags => dispatch(setSearchTags(tags)),
  setSearchPhotographer: photographerId => dispatch(setSearchPhotographer(photographerId)),
  receiveFocusedPhoto: (photo) => dispatch(receiveFocusedPhoto(photo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stream);
