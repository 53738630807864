import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ProtectedRoute } from '../util/route_util';
import BannerContainer from './banner/banner_container';
import CartPageContainer from './cart/cart_page_container';
import Contact from './contact/contact';
import DashboardContainer from './dashboard/dashboard_container';
import EmailConfirmation from './email_confirmation/email_confirmation';
import Help from './help/help';
import LandingContainer from './landing/landing_container';
import LocationsPageContainer from './locations/locations_page_container';
import NotFound from './not_found';
import ThankYouPageContainer from './orders/thank_you_page_container';
import PrivacyPolicy from './privacy_policy/privacy_policy';
import { Settings } from './settings';
import StreamSearchMap from './stream_search_map/stream_search_map';
import Terms from './terms/terms';
import UploadManager from './upload/upload_manager';
import UploadContainer from './upload/upload_container';
import UserProfileContainer from './user/user_profile_container';
import UploaderGuide from './uploader_guide/uploader_guide';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  searchedLocation: state.ui.searchParams,
  uploading: state.ui.uploading,
  bannerHeight: state.ui.dimensions.bannerHeight,
  searchString: state.ui.searchParams.searchString,
});

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      searchedLocation,
      bannerHeight,
      location: { pathname },
    } = this.props;

    const loggedIn = Boolean(currentUser);
    const loggedInOrSearched = loggedIn || Boolean(searchedLocation.value);
    const landingOrDashboard = (pathname === '/' || pathname.includes('/dashboard'));
    const mainContainerClass = classNames('main-container', {
      'searching': loggedInOrSearched,
    });
    const appClasses = classNames('app', {
      'landing': landingOrDashboard,
      'cart-main': pathname === '/cart',
    });
    const topOffset = landingOrDashboard ? '0px' : `${bannerHeight}px`;

    return (
      <div className={appClasses}>
        <BannerContainer loggedInOrSearched={loggedInOrSearched} />
        <div className={mainContainerClass} style={{top: topOffset}}>
          <Switch>
            <Route exact path='/' render={() => loggedIn ? <Redirect to='/dashboard' /> : <LandingContainer />} />
            <Route exact path='/cart' component={CartPageContainer} />
            <Route exact path='/search' component={StreamSearchMap} />
            <Route exact path='/help' component={Help} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/terms-and-conditions' component={Terms} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/email-confirmation' component={EmailConfirmation} />
            <Route exact path='/uploader-guide' component={UploaderGuide} />
            <Route path='/user/:username' component={UserProfileContainer} />
            <ProtectedRoute exact path='/upload' component={UploadContainer} />
            <ProtectedRoute exact path='/upload-manager' component={UploadManager} />
            <ProtectedRoute exact path='/locations' component={LocationsPageContainer} />
            <ProtectedRoute path='/thank-you' component={ThankYouPageContainer} />
            <ProtectedRoute path='/dashboard' component={DashboardContainer} />
            <ProtectedRoute path='/settings' component={Settings} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  null,
)(App));
