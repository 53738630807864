import Footer from '../footer';
import ArticleNavBar from './article_nav_bar';
import UploadingPhotosData from './uploading_photos_data';
import SetLocationData from './set_location_data';
import ChooseActivityData from './choose_activity_data';
import DateAndTimeData from './date_and_time_data';
import SetPriceData from './set_price_data';
import AddTagsData from './add_tags_data';
import PublishPhotosData from './publish_photos_data';
import { Link } from 'react-router-dom';
import ArticleFooter from './article_footer';

const UploaderGuide = () => {
  return (
    <div>
      <div className='container article-container'>
        <div className='row page-path'>
          <p><Link to='/help'>WunderPics Help Center</Link>  /  <Link to=''>Get Started</Link>  / How to Upload Photos</p>
        </div>
        <div className='row'>
          <div className='col-9'>
            <h1 className='article-title'>How to Upload Photos</h1>
            <p>
              Ready to sell some photos? First you must have a WunderPics account and be signed in. This will allow you start uploading images.
            </p>
            <br />
            <div>
              <div id='uploadingPhotos'>
                <h2 className='article-content-title'>1. Uploading Photos</h2>
                <UploadingPhotosData />
              </div>
              <div id='setLocation' >
                <h2 className='article-content-title'>2. Set Location</h2>
                <SetLocationData />
              </div>
              <div id='chooseActivity'>
                <h2 className='article-content-title'>3. Choose Activity</h2>
                <ChooseActivityData />
              </div>
              <div id='dateAndTime'>
                <h2 className='article-content-title'>4. Confirm Date & Time</h2>
                <DateAndTimeData />
              </div>
              <div id='setPrice'>
                <h2 className='article-content-title'>5. Set your price</h2>
                <SetPriceData />
              </div>
              <div id='addTags'>
                <h2 className='article-content-title'>6. Add Tags</h2>
                <AddTagsData />
              </div>
              <div id='publishPhotos'>
                <h2 className='article-content-title'>7. Publish photos for sale</h2>
                <PublishPhotosData />
              </div>
              <div id='uploadEditor'>
                <button className='article-btn'>How to use Upload Editor</button>
              </div>
            </div>
          </div>
          <div className='col-3 contents-navbar sticky-top'>
            <div>
              <ArticleNavBar />
            </div>
          </div>
        </div>
      </div>
      <ArticleFooter />
      <Footer />
    </div>
  );
}

export default UploaderGuide;