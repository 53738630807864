import ChooseActivity1 from 'images/uploader_guide/choose-activity-1.png';

const ChooseActivityData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>Search for an activity that matches your photos</li>
        <li>Once you find the right activity click “Set Activity”</li>
        <img src={ChooseActivity1} alt='Choose Activity Img1' />
      </ul>
    </div>
  )
}

export default ChooseActivityData