const ArticleNavBar = () => {
  return (
    <div>
      <h3>Contents</h3>
      <ul id='articleNavLinks'>
        <li><a id="uploadingPhotosLink" href="#uploadingPhotos">Uploading Photos</a></li>
        <li><a id="setLocationLink" href="#setLocation">Set Location</a></li>
        <li><a id="chooseActivityLink" href="#chooseActivity">Choose Activity</a></li>
        <li><a id="dateAndTimeLink" href="#dateAndTime">Confirm Date & Time</a></li>
        <li><a id="setPriceLink" href="#setPrice">Set your price</a></li>
        <li><a id="addTagsLink" href="#addTags">Add Tags</a></li>
        <li><a id="publishPhotosLink" href="#publishPhotos">Publish photos for sale</a></li>
        <li><a id="uploadEditorLink" href="#uploadEditor">Upload Editor</a></li>
      </ul>
    </div>
  );
}

export default ArticleNavBar;