import { ReactElement, SVGProps } from 'react';

export default function CartIcon({
  className = "cart-icon",
  height = '36',
  width = '34',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.11679 3.07763H2.11591H1.41817C1.17655 3.07763 0.943634 2.98057 0.770982 2.80589C0.598135 2.63102 0.5 2.39261 0.5 2.14282C0.5 1.89302 0.598135 1.65461 0.770982 1.47974C0.943634 1.30506 1.17655 1.20801 1.41817 1.20801H2.1157C2.92952 1.20878 3.72164 1.4775 4.37231 1.97454C5.02314 2.4717 5.49729 3.17051 5.7219 3.96601L5.72196 3.96625L6.46225 6.58334L6.56519 6.94724H6.94337H30.5244C30.905 6.94749 31.2799 7.04202 31.6166 7.22292C31.9533 7.40383 32.2417 7.66578 32.4562 7.98635C32.6707 8.30696 32.8046 8.67628 32.8457 9.06206C32.8869 9.44779 32.834 9.83774 32.6919 10.1977C32.6918 10.1977 32.6918 10.1978 32.6918 10.1978L28.5111 20.7751C28.2317 21.4819 27.7497 22.0869 27.1283 22.5125C26.5069 22.9381 25.7743 23.1651 25.0252 23.165H25.0251H12.0658C11.2518 23.1649 10.4593 22.8968 9.80809 22.4002C9.1568 21.9035 8.68207 21.2049 8.45689 20.4095L3.95864 4.48377C3.95862 4.48369 3.9586 4.48361 3.95857 4.48353C3.95856 4.48348 3.95855 4.48343 3.95853 4.48339C3.84501 4.08056 3.60471 3.72495 3.27271 3.47093C2.94051 3.21675 2.53477 3.07836 2.11679 3.07763ZM7.75457 8.81686H7.09366L7.27341 9.45285L10.2231 19.8892C10.2231 19.8893 10.2232 19.8894 10.2232 19.8896C10.2232 19.8896 10.2232 19.8896 10.2232 19.8897C10.3367 20.2924 10.577 20.648 10.909 20.902C11.2412 21.1562 11.647 21.2946 12.0649 21.2953H12.0658H25.0251H25.0252C25.4096 21.2952 25.7846 21.1787 26.102 20.9614C26.4193 20.7441 26.6642 20.4364 26.806 20.0784L26.8061 20.0781L30.9869 9.50065L31.2572 8.81686H30.5219H7.75457Z" />
      <path d="M14.1983 34.3547C13.7433 34.5454 13.2557 34.6435 12.7635 34.6435C11.7696 34.6435 10.8152 34.2441 10.1107 33.5313C9.40591 32.8183 9.00895 31.85 9.00895 30.8391C9.00895 29.8282 9.40591 28.8599 10.1107 28.1469C10.8152 27.4341 11.7696 27.0347 12.7635 27.0347C13.2557 27.0347 13.7433 27.1328 14.1983 27.3235C14.6534 27.5142 15.0673 27.7939 15.4163 28.1469C15.7652 28.4999 16.0423 28.9193 16.2314 29.3813L16.6941 29.1919L16.2314 29.3813C16.4206 29.8433 16.518 30.3387 16.518 30.8391C16.518 31.3395 16.4206 31.8349 16.2314 32.2969C16.0423 32.7589 15.7652 33.1783 15.4163 33.5313C15.0673 33.8843 14.6534 34.164 14.1983 34.3547ZM11.4051 32.2051C11.7643 32.5686 12.2528 32.7739 12.7635 32.7739C13.2741 32.7739 13.7626 32.5686 14.1219 32.2051C14.4809 31.8419 14.6816 31.3504 14.6816 30.8391C14.6816 30.3278 14.4809 29.8363 14.1219 29.473C13.7626 29.1096 13.2741 28.9043 12.7635 28.9043C12.2528 28.9043 11.7643 29.1096 11.4051 29.473C11.046 29.8363 10.8453 30.3278 10.8453 30.8391C10.8453 31.3504 11.046 31.8419 11.4051 32.2051Z" />
      <path d="M25.5437 34.3547C25.0886 34.5454 24.601 34.6435 24.1088 34.6435C23.1149 34.6435 22.1606 34.2441 21.456 33.5313C20.7513 32.8183 20.3543 31.85 20.3543 30.8391C20.3543 29.8282 20.7513 28.8599 21.456 28.1469C22.1606 27.4341 23.1149 27.0347 24.1088 27.0347C24.601 27.0347 25.0886 27.1328 25.5437 27.3235C25.9988 27.5142 26.4127 27.7939 26.7616 28.1469C27.1105 28.4999 27.3876 28.9193 27.5767 29.3813L28.0395 29.1919L27.5767 29.3813C27.7659 29.8433 27.8633 30.3387 27.8633 30.8391C27.8633 31.3395 27.7659 31.8349 27.5767 32.2969C27.3876 32.7589 27.1105 33.1783 26.7616 33.5313C26.4127 33.8843 25.9988 34.164 25.5437 34.3547ZM22.7504 32.2051C23.1097 32.5686 23.5982 32.7739 24.1088 32.7739C24.6194 32.7739 25.108 32.5686 25.4672 32.2051C25.8263 31.8419 26.027 31.3504 26.027 30.8391C26.027 30.3278 25.8263 29.8363 25.4672 29.473C25.108 29.1096 24.6194 28.9043 24.1088 28.9043C23.5982 28.9043 23.1097 29.1096 22.7504 29.473C22.3913 29.8363 22.1906 30.3278 22.1906 30.8391C22.1906 31.3504 22.3913 31.8419 22.7504 32.2051Z" />
    </svg>
  );
}
