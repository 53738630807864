import { PhotoLocation, User } from '../../types';

export const getLocations = (): JQuery.jqXHR<PhotoLocation[]> =>
  $.ajax({
    method: 'GET',
    url: '/api/locations',
  });

export const getLocationsWithPhotos = (): JQuery.jqXHR<PhotoLocation[]> =>
  $.ajax({
    method: 'GET',
    url: '/api/locations_with_photos',
  });

export const deleteLocation = (
  locationId: PhotoLocation['id'],
  userId: User['id'],
): JQuery.jqXHR<PhotoLocation> =>
  $.ajax({
    method: 'DELETE',
    url: `/api/locations/${locationId}`,
    data: { locationId, userId },
  });

export const postLocation = (
  location: Pick<PhotoLocation, 'lat' | 'lng' | 'name'>
): JQuery.jqXHR<PhotoLocation> =>
  $.ajax({
    method: 'POST',
    url: '/api/locations',
    data: { location },
  });

// FIXME: Replace with a solution that allows more requests
export const getGeoLocationFromIp = (): JQuery.jqXHR<PhotoLocation> =>
  $.ajax({
    method: 'GET',
    url: '/api/geolocation_fallback',
  });
