/**
 * Trying to decide if I should have two separate APIs to handle location search
 * and bound search separately. or one API that can handle all of the logic
 * itself by determining which API to call based on which parameters are set.
 *
 * 11/22/19 - I am of the opinion we should use one API only right now and that's
 * why I named this function `searchPhotos`
 *
 * @param {object} params
 *  {
 *    locationId: 1,
 *    date: '2019-03-08',
 *    time: '12:00PM',
 *    activityId: 8,
 *    tags: ['super', 'duper'],
 *    photographerId: 1,
 *  },
 */
export const searchPhotos = (params) => (
  $.ajax({
    method: 'GET',
    url: '/api/location_search',
    data: params,
  })
);

export const searchBoundPhotos = (params) => (
  $.ajax({
    method: 'GET',
    url: '/api/bound_search',
    data: params,
  })
);
