
interface FollowedSearchParams {
  follow_begin: string;
  follow_end: string;
  activity_id: number;
  location_id: number;
  photographer_id?: number;
  tags?: number[],
  tag_names?: string[],
}

export const postFollowedSearch = (followedSearchParams: FollowedSearchParams):JQuery.jqXHR => {
  return $.ajax({
    method: 'POST',
    url: '/api/followed_searches',
    data: {
      followed_search: followedSearchParams,
    }
  })
}

export const getFollowedSearches = (): JQuery.jqXHR => {
  return $.ajax({
    method: 'GET',
    url: '/api/followed_searches'
  })
}

export const deleteFollowedSearch = (id: number): JQuery.jqXHR => {
  return $.ajax({
    method: 'DELETE',
    url: `/api/followed_searches/${id}`,
  });
}

export const updateFollowedSearchNotifications = (followedSearchId: number): JQuery.jqXHR => {
  return $.ajax({
    method: 'POST',
    url: '/api/followed_searches/update_notifications',
    data: {
      followed_search: {
        id: followedSearchId
      }
    }
  });
}
