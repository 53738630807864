import React from 'react';

const changesData = {
    rows: [
        {
            title: "Changes to Terms",
            content: "WunderPics, Inc. reserves the right, in its sole discretion, to change the Terms under which WunderPics.com is offered. The most current version of the Terms will supersede all previous versions. WunderPics, Inc. encourages you to periodically review the Terms to stay informed of our updates."
        }
    ]
}

export default changesData;