import { useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { RootState } from '../reducers/root_reducer';
import { selectOptionFormat } from '../reducers/selectors';
import { Activity } from '../types';

type UseActivitiesReturnType = {
  activities: {[key: number]: Activity};
  selectOptionActivities: OptionTypeBase[];
}

export default function useActivities(): UseActivitiesReturnType  {
  const activities = useSelector(
    ({ entities }: RootState) => entities.activities
  );

  return {
    activities,
    selectOptionActivities: selectOptionFormat(activities),
  }
}
