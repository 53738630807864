import React from 'react';

const terminationData = {
    rows: [
        {
            title: "Termination/Access Restriction",
            content: "WunderPics, Inc. reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Delaware and you hereby consent to the exclusive jurisdiction and venue of courts in Delaware in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section."
            +
            "<br/>"
            +
            "<br/>"
            +
            "You agree that no joint venture, partnership, employment, or agency relationship exists between you and WunderPics, Inc. as a result of this agreement or use of the Site. WunderPics, Inc.'s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of WunderPics, Inc.'s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by WunderPics, Inc. with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and WunderPics, Inc. with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and WunderPics, Inc. with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English."
        }
    ]
}

export default terminationData;