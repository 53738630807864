import React from 'react';

const contactUsData = {
    rows: [
        {
            title: "Contact Us",
            content: "WunderPics, Inc. welcomes your questions, comments, or concerns regarding the Terms of Services:"
            +
            "<br/>"
            +
            "<br/>"
            +
            "support@wundpics.com"
            +
            "<br/>"
            +
            "<br/>"
            +
            "Effective as of July 27, 2021"
        }
    ]
}

export default contactUsData;