import React from 'react';

const saleData = {
    rows: [
        {
            title: "Terms of Sale",
            content: "You hereby state that you have the right to sell the media you have uploaded to the Site, and that the media comply with all applicable laws within your jurisdiction. You are not allowed to sell any products that infringe or have the potential to infringe the rights of another. You agree to pay the associated commission fees to WunderPics Inc. on all media sold by you on the Site. By uploading media for sale on the Site you grant the buyer a non-exclusive perpetual Personal Use License to download and copy the accompanying media in accordance with the Personal Use License."
        }
    ]
}

export default saleData;