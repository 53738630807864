import { ReactElement, SVGProps } from 'react';

export default function StripeIcon({
  className = 'stripe-icon',
  height = '22',
  width = '54',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 54 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5851 20.8482V16.8558C35.1174 17.255 35.7828 17.7874 37.1136 17.7874C39.6422 17.7874 41.9045 15.7912 41.9045 11.2664C41.9045 7.14091 39.6422 4.87854 37.1136 4.87854C35.7828 4.87854 34.8513 5.54394 34.3189 6.07627L34.1859 5.27778H31.125V21.6467L34.5851 20.8482ZM36.1821 7.9394C37.5129 7.9394 38.3114 9.40328 38.3114 11.2664C38.3114 13.2626 37.5129 14.5934 36.1821 14.5934C35.3836 14.5934 34.8513 14.3273 34.452 13.928V8.6048C34.9843 8.33864 35.3836 7.9394 36.1821 7.9394Z"
        fill="white"
      />
      <path
        d="M48.6915 17.9192C50.4215 17.9192 51.6193 17.52 52.6839 16.9877V14.0599C51.7524 14.5922 50.6877 14.8584 49.2238 14.8584C47.7599 14.8584 46.6953 14.3261 46.4291 12.596H53.3493C53.3493 12.3299 53.3493 11.6645 53.3493 11.2652C53.3493 7.53896 51.6193 4.74426 48.2923 4.74426C44.9652 4.74426 42.8359 7.67204 42.8359 11.2652C42.8359 15.6569 45.2314 17.9192 48.6915 17.9192ZM48.2923 7.67204C49.0907 7.67204 50.0223 8.33744 50.0223 9.93441H46.296C46.4291 8.47052 47.3607 7.67204 48.2923 7.67204Z"
        fill="white"
      />
      <path
        d="M21.9406 9.00406C22.7391 7.80634 24.2029 8.0725 24.6022 8.20558V4.87856C24.0699 4.74548 22.4729 4.34624 21.8075 5.94321L21.5413 4.87856H18.4805V17.3881H21.9406V9.00406Z"
        fill="white"
      />
      <path
        d="M5.97166 13.9268C5.97166 14.5922 5.43934 14.7253 4.64086 14.7253C3.44313 14.7253 1.97924 14.193 0.781516 13.5276V16.9877C2.11232 17.52 3.44313 17.7862 4.64086 17.7862C7.56863 17.7862 9.56483 16.3223 9.56483 13.7937C9.56483 9.53516 4.24161 10.3336 4.24161 8.73668C4.24161 8.20436 4.64085 7.9382 5.43934 7.9382C6.50398 7.9382 7.83479 8.20436 8.89943 8.86976V5.40967C7.70171 4.87734 6.63706 4.74426 5.43934 4.74426C2.64464 4.74426 0.648438 6.20815 0.648438 8.73668C0.648438 12.8622 5.97166 12.1968 5.97166 13.9268Z"
        fill="white"
      />
      <path
        d="M14.4877 17.9197C15.5524 17.9197 16.4839 17.6536 17.0162 17.3874V14.4597C16.617 14.5927 14.3546 15.2581 14.3546 13.1288V8.07178H17.0162V5.01093H14.3546V1.95007L10.8945 2.74856V14.1935C10.8945 16.3228 12.3584 17.9197 14.4877 17.9197Z"
        fill="white"
      />
      <path
        d="M25.8008 1.15176V3.94645L29.394 3.28104V0.353271L25.8008 1.15176Z"
        fill="white"
      />
      <path
        d="M29.2609 5.01111H25.8008V17.3876H29.2609V5.01111Z"
        fill="white"
      />
    </svg>
  );
}
