import { ReactElement } from "react";

export default function PhotoGridPreview({
  className = null,
  photos = [],
}): ReactElement {

  return (
    <div className={`photo-grid ${className}`}>
      {photos.map((photo, i) =>
        <div key={i}>
          <div>
            <img src={photo.browserPath} alt={photo.name} />
          </div>
        </div>
      )}
    </div>
  );
}
