export const deleteLineItem = (lineItem) => (
  $.ajax({
    method: 'DELETE',
    url: `/api/line_items/${lineItem.id}`,
  })
);

export const createLineItem = (lineItem) => (
  $.ajax({
    method: 'POST',
    url: '/api/line_items',
    data: { line_item: lineItem },
  })
);
