import React from 'react';

const TagsIcon = (props) => (
  <svg {...props} width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.938 0.548828H12.938C12.526 0.548828 11.949 0.767815 11.658 1.03445L4.21901 7.85054C4.07875 7.97966 4 8.15443 4 8.33662C4 8.5188 4.07875 8.69357 4.21901 8.82269L10.658 14.7225C10.7989 14.851 10.9897 14.9232 11.1885 14.9232C11.3873 14.9232 11.5781 14.851 11.719 14.7225L19.158 7.90643C19.45 7.63888 19.688 7.11111 19.688 6.73361V1.23603C19.6875 1.05392 19.6083 0.879408 19.4678 0.750638C19.3272 0.621868 19.1368 0.549312 18.938 0.548828V0.548828ZM15.188 6.04641C14.991 6.04635 14.7959 6.01073 14.6138 5.94158C14.4318 5.87243 14.2664 5.77111 14.1271 5.6434C13.9879 5.51569 13.8774 5.3641 13.802 5.19727C13.7267 5.03044 13.6879 4.85165 13.688 4.6711C13.6881 4.49055 13.727 4.31178 13.8024 4.145C13.8779 3.97822 13.9885 3.82669 14.1279 3.69907C14.2672 3.57144 14.4327 3.47022 14.6148 3.40118C14.7968 3.33215 14.992 3.29664 15.189 3.2967C15.587 3.29683 15.9686 3.44179 16.2499 3.69971C16.5312 3.95763 16.6891 4.30738 16.689 4.67202C16.6889 5.03665 16.5307 5.3863 16.2492 5.64405C15.9677 5.9018 15.586 6.04653 15.188 6.04641Z" />
    <path d="M1.68801 8.33707L10.188 0.548828H8.93801C8.52601 0.548828 7.94901 0.767815 7.65801 1.03445L0.219014 7.85054C0.0787466 7.97966 0 8.15443 0 8.33662C0 8.5188 0.0787466 8.69357 0.219014 8.82269L6.65801 14.7225C6.79894 14.851 6.98968 14.9232 7.18851 14.9232C7.38735 14.9232 7.57809 14.851 7.71901 14.7225L8.18901 14.2919L1.68901 8.33616L1.68801 8.33707Z" />
  </svg>
);

export default TagsIcon;
