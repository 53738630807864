import { ReactElement, RefObject, useCallback } from 'react';
import { useKeyboardEvents, useSelectedDescendants } from '../../hooks';

interface FocusLockProps {
  isLocked?: boolean;
  children: (ref: RefObject<Element>) => ReactElement;
}

export default function FocusLock({
  isLocked = true,
  children,
}: FocusLockProps): ReactElement {
  const [parentRef, descendants] = useSelectedDescendants(
    '[href], [tabindex]:not([tabindex="-1"]), button, input, select'
  );

  const handleTabFocus = useCallback(
    (e: KeyboardEvent) => {
      if (!descendants) return;

      const { key, shiftKey } = e;
      const { length, 0: firstItem, [length - 1]: lastItem } = descendants;

      if (isLocked && key === 'Tab') {
        if (length === 1) {
          e.preventDefault();
          return;
        }

        if (!shiftKey && document.activeElement === lastItem) {
          e.preventDefault();
          firstItem.focus();
          return;
        }

        if (shiftKey && document.activeElement === firstItem) {
          e.preventDefault();
          lastItem.focus();
          return;
        }
      }
    },
    [descendants, isLocked]
  );
  useKeyboardEvents({ keydown: handleTabFocus });

  return children(parentRef);
}
