import { UploadBatch } from '../../types';
import axios from 'axios';

interface UploadBatchParams {
  capture_begin: string;
  capture_end: string;
  location_id: number;
  activity_id: number;
  num_photos: number;
  batch_size: number;
}

export const postUploadBatches = (uploadBatchParams: UploadBatchParams): JQuery.jqXHR => {
  return $.ajax({
    method: 'POST',
    url: '/api/upload_batches',
    data: {
      upload_batch: uploadBatchParams
    }
  })
};

export const getUploadBatches = (): JQuery.jqXHR<UploadBatch[]> => (
  $.ajax({
    method: 'GET',
    url: '/api/upload_batches/retrieve'
  })
);

export const getUploadBatchPhotos = (id: string): JQuery.jqXHR => (
  $.ajax({
    method: 'GET',
    url: `/api/upload_batches/${id}`,
  })
);

export const deleteUploadBatch = (id: number) => (
  axios.delete(`/api/upload_batches/${id}`)
);
