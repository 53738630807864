const ArticleFooter = () => {
  return (
    <div className='container article-footer-container'>
      <p>Was this article helpful?</p>
      <div className='row btn-row'>
        <button className='article-btn'>Yes, thank you!</button>
        <button className='article-btn'>No, not really</button>
      </div>
      <div className='related-articles'>
        <h3>Related Articles</h3>
        <div className='row'>
          <button className='col'>Uploaded Editor</button>
          <button className='col'>Searching to find yourself</button>
          <button className='col'>How to follow a Search</button>
        </div>
      </div>
    </div>
  )
}

export default ArticleFooter