import PlusPlan from 'images/plus-coming-soon.png';
import ProUnlimitedPlan from 'images/pro-unlimited-coming-soon.png';
import { ReactElement } from 'react';
import { Button, Heading, Panel } from '../library';

export default function Plans(): ReactElement {
  return (
    <Panel className="plans">
      <Heading level={1}>Upgrade your plan!</Heading>
      <Panel className="plan-group">
        <Panel className="plan">
          <Panel.Header>
            <Heading level={2}>Free</Heading>
            <p>$0/month</p>
          </Panel.Header>
          <Panel.Body>
            <div className="plan-details">
              <p>10GB Upload limit</p>
              <p>30MB Maximum file size</p>
              <p>15% commission</p>
            </div>
          </Panel.Body>
          <Panel.Footer>
            <Button>Current</Button>
          </Panel.Footer>
        </Panel>
        <img alt="Plus Plan Coming Soon" className="coming-soon" src={PlusPlan} />
        <img
          alt="Pro Unlimited Plan Coming Soon"
          className="coming-soon"
          src={ProUnlimitedPlan}
        />
      </Panel>
    </Panel>
  );
}
