import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EmptyProfilePicIcon from '../../icons/empty_profile_pic_icon';
import { Card, CardTitle, CardBody, CardText, Button } from 'reactstrap';

const EmptyProfileSection = () => {
  const {
    firstName,
    lastName,
    username,
  } = useSelector(state => state.session);
  return (
    <Card className='dashboard-home-card'>
      <CardBody className='dashboard-card-body'>
        <Link to='/settings/account'>
          <div className='profile-pic'>
            <EmptyProfilePicIcon />
            {/* <p>Add Photo</p> */}
          </div>
        </Link>
        <CardTitle className='dashboard-card-title'>
          {firstName ?
            `${firstName} ${lastName}` :
            username}
        </CardTitle>
        <CardText className='profile-card-text'>@{username}</CardText>
        <Link to='/settings/account'><Button className='dashboard-home-btn'>Edit Profile</Button></Link>
      </CardBody>
    </Card>
  )
}

export default EmptyProfileSection;
