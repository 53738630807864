import React from 'react';

const subscriptionsData = {
    rows: [
        {
            title: "Subscription Plans",
            content: "WunderPics, Inc. offers paid subscription plans that can be found here. By purchasing a Subscription Plan, you agree to pay all applicable Subscription Fees and other charges in accordance with your selected Subscription Plan."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Subscription Plans automatically renew and are charged on either a monthly or annual basis (as determined by the user). Failure to pay and renew plans will result in the restriction of account capabilities and deletion of content uploaded to the site. Users who have not renewed their Subscription Plans will be granted a 30 day grace period before the deletion of their previously uploaded content."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Subscription Plan fees are non-refundable."
        }
    ]
}

export default subscriptionsData;