import React from 'react';
import classNames from 'classnames';

import ExpandPhoto from 'images/icons/expand-photo.svg';

class UploadPhotoGrid extends React.Component {
  constructor(props) {
    super(props);

    this.formatTime = this.formatTime.bind(this);
  }

  formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ampm;
    return strTime;
  }

  render() {
    const {
      photo,
      selected,
      selectPhoto,
      setPhotoModal,
    } = this.props;

    const innerClasses = classNames('grid-photo-inner', {
      'selected': selected,
    });

    return(
      <div className='grid-photo-outer'>
        <div className={innerClasses}>
          <img loading="lazy" onClick={selectPhoto} className='grid-photo' src={photo.browserPath} />
          <div onClick={setPhotoModal} className='grid-photo-expand'>
            <img src={ExpandPhoto} />
          </div>
          <div
            onClick={selectPhoto}
            className='grid-photo-info'
          >
            <span>${photo.price}</span>
            <span className='photo-info-time'>{this.formatTime(photo.dateObject)}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadPhotoGrid;
