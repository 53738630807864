import { combineReducers } from 'redux';
import searchPhotosReducer from './search_photos_reducer';
import searchParamsReducer from './search_params_reducer';
import focusedPhotoReducer from './focused_photo_reducer';
import cartIdsReducer from './cart_ids_reducer';
import uploadingReducer from './uploading_reducer';
import dimensionsReducer from './dimensions_reducer';

const uiReducer = combineReducers({
  searchPhotos: searchPhotosReducer,
  searchParams: searchParamsReducer,
  focusedPhoto: focusedPhotoReducer,
  cartIds: cartIdsReducer,
  uploading: uploadingReducer,
  dimensions: dimensionsReducer,
});

export default uiReducer;
