import { ReactElement, SVGProps } from 'react';

export default function StorageIcon({
  className = 'storage-icon',
  height = '26',
  width = '30',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7821 18.2045C12.3861 18.2045 12.8757 17.6945 12.8757 17.0654C12.8757 16.4363 12.3861 15.9263 11.7821 15.9263C11.1781 15.9263 10.6885 16.4363 10.6885 17.0654C10.6885 17.6945 11.1781 18.2045 11.7821 18.2045Z" fill="none"/>
      <path d="M13.9693 20.4815L12.8757 18.9627L10.6885 22.0003H19.4374L16.1566 17.4438L13.9693 20.4815Z" fill="none"/>
      <path d="M20.8959 12.8898H9.23062C8.42644 12.8898 7.77246 13.571 7.77246 14.4086V23.5215C7.77246 24.3591 8.42644 25.0403 9.23062 25.0403H20.8959C21.7 25.0403 22.354 24.3591 22.354 23.5215V14.4086C22.354 13.571 21.7 12.8898 20.8959 12.8898ZM9.23062 23.5215V14.4086H20.8959L20.8973 23.5215H9.23062Z" fill="none"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0067 0C19.5837 0 23.4911 2.99576 25.0246 7.20864C27.8873 7.99682 30 10.7171 30 13.95C30 17.7955 27.016 20.9117 23.3304 20.9249V18.2744C23.8674 18.2727 24.399 18.1617 24.8952 17.9475C25.3986 17.7302 25.8557 17.411 26.24 17.0085C27.0234 16.1924 27.4554 15.1043 27.4554 13.95C27.4554 11.99 26.1864 10.2707 24.3717 9.76149L23.106 9.41274L22.644 8.1398C22.356 7.34814 21.9542 6.60182 21.4487 5.92874C20.9498 5.26262 20.3605 4.67673 19.6942 4.18848C18.3181 3.18059 16.6975 2.64701 15.0067 2.64701C13.3158 2.64701 11.6953 3.18059 10.3192 4.18848C9.65509 4.67516 9.06386 5.2616 8.56473 5.92874C8.05915 6.60182 7.65737 7.34466 7.36942 8.1398L6.90402 9.41623L5.63504 9.76149C4.76451 10.0021 3.98103 10.5427 3.42187 11.2855C2.87612 12.0144 2.57478 12.8793 2.54464 13.8C2.50781 14.9788 2.92634 16.1157 3.71317 16.9597C4.48 17.7821 5.50226 18.2465 6.59326 18.2732V20.9242C2.94285 20.8686 0 17.7689 0 13.95C0 10.7136 2.11942 7.98984 4.98884 7.20516C6.52232 2.99227 10.4297 0 15.0067 0Z" fill="none"/>
    </svg>
  )
}
