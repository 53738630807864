import { ReactElement } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, Heading, Input, Panel } from '../library';
import AcceptedCards from 'images/accepted-cards.png';

export default function PaymentMethod(): ReactElement {
  const stripe = useStripe();

  return (
    <Panel className="payment-method">
      <form>
        <Panel semantic element="section">
          <Heading level={1}>Add Credit Card</Heading>
          <div className="adjacent-elements">
            <Input className="input" name="nickname">
              Nickname (optional)
            </Input>
            <img
              alt="Visa, Mastercard, American Express, Discover, and more..."
              className="accepted-cards"
              src={AcceptedCards}
            />
          </div>
          <div className="input">
            <label className="input__label">Card Number</label>
            <CardNumberElement
              options={{
                placeholder: 'Card Number',
              }}
            />
          </div>
          <div className="adjacent-elements">
            <Input name="name">Name on card</Input>
            <div className="input">
              <label className="input__label">Expiration Date</label>
              <CardExpiryElement
                options={{
                  classes: { base: 'input__control input__control--expiry' },
                }}
              />
            </div>
            <div className="input">
              <label className="input__label">CVC</label>
              <CardCvcElement
                options={{
                  classes: { base: 'input__control input__control--cvc' },
                }}
              />
            </div>
          </div>
        </Panel>
        <Panel semantic element="section">
          <Heading level={1}>Billing Address</Heading>
          <div className="adjacent-elements">
            <Input name="address-street">Address</Input>
            <Input name="address-number">Apt, Suite, etc.</Input>
          </div>
          <div className="adjacent-elements">
            <Input name="address-city">City</Input>
            <Input name="address-state">State</Input>
            <Input name="address-zip">Zip code</Input>
          </div>
        </Panel>
        <Button disabled={!stripe}>Save</Button>
      </form>
    </Panel>
  );
}
