import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import EmailConfirmationIcon from '../icons/email_confirmation_icon';

const EmailConfirmation = () => {
  return (
    <div className='email-confirmation'>
      <div className='container'>
        <Card className='col-8 email-confirmation-card'>
          <CardBody className='email-confirmation-card-body'>
            <CardTitle className='email-confirmation-card-title'><EmailConfirmationIcon />Confirm Email Address</CardTitle>
            <CardText className='email-confirmation-card-text'>
              Awesome, you’re almost there! We’ve just sent you an email to confirm your email address.
              <br />
              <br />
              Please click the confirmation link in the email to complete registration.
            </CardText>
            <p id='no-email-receipt'>Didn’t receive an Email yet?</p>
            <a href='/users/confirmation/new'>
              <Button variant='primary' className='resend-email-btn'>Resend Email</Button>
            </a>
          </CardBody>
        </Card>
      </div>
    </div>
  )
};

export default EmailConfirmation;
