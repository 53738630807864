import SetLocation1 from 'images/uploader_guide/set-location-1.png';
import SetLocation2 from 'images/uploader_guide/set-location-2.png';
import SetLocation3 from 'images/uploader_guide/set-location-3.png';

const SetLocationData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>Type and select the appropriate location</li>
        <li>Click "Set Location" to proceed</li>
        <img src={SetLocation1} alt='Set Location Img1' />
        <p className='article-content-subtitle'>Adding a new location</p>
        <li>If you are unable to find a specfiic location, you can add a location by clicking “+ add location”</li>
        <li>A camera pin icon will appear on the map. You can move the pin anywhere on the map to pinpoint an exact location.</li>
        <img src={SetLocation2} alt='Set Location Img2' />
        <li>Once you’ve pinpointed the correct location, you can name the location.</li>
        <li>Click on the “apply” button to create the location</li>
        <li>To continue onto the next step, click on “Set Location” on the bottom right of the window</li>
        <img src={SetLocation3} alt='Set Location Img3' />
      </ul>
    </div>
  )
}

export default SetLocationData