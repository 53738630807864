import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import FAQContactIcon from '../icons/faq_contact_icon';
import { Link } from 'react-router-dom';

const FaqContactSection = () => {
  return (
    <div className='col text-center'>
      <Card>
        <FAQContactIcon />
        <CardBody>
          <CardTitle>FAQ</CardTitle>
          <CardText>
            Get answers to our most
            <br />
            frequently asked questions.
          </CardText>
          <Link to='/help'><Button variant="primary" className="text-center">View</Button></Link>
        </CardBody>
      </Card>
    </div>
  )
}

export default FaqContactSection;
