import {
  RECEIVE_SEARCH_RESULT_PHOTOS,
  RECEIVE_SEARCH_LOCATION,
  RECEIVE_CLEAR_SEARCH_LOCATION,
  RECEIVE_SEARCH_DATE,
  RECEIVE_CLEAR_SEARCH_DATE,
  RECEIVE_SEARCH_TIME,
  RECEIVE_CLEAR_SEARCH_TIME,
  RECEIVE_SEARCH_TIME_END,
  RECEIVE_CLEAR_SEARCH_TIME_END,
  RECEIVE_SEARCH_ACTIVITY,
  RECEIVE_CLEAR_SEARCH_ACTIVITY,
  RECEIVE_SEARCH_TAGS,
  RECEIVE_CLEAR_SEARCH_TAGS,
  RECEIVE_SEARCH_PHOTOGRAPHER,
  RECEIVE_CLEAR_SEARCH_PHOTOGRAPHER,
  RECEIVE_SEARCH_STRING,
  CLEAR_ALL_SEARCH_PARAMS,
  buildSearchParamsQueryString,
} from '../actions/search_actions';

const initialLocation = { label: 'Where were you...', value: '' };
const initialDate = { display: 'Date & Time', label: 'Date & Time', value: '' };
const initialTime = { label: '12:00AM', value: new Date(new Date().setHours(0, 0, 0, 0)) };
const initialTimeEnd = { label: '11:59PM', value: new Date(new Date().setHours(23, 59, 59, 0)) };
const initialActivity = { label: 'Activity', value: '' };
const initialTags = null;
const initialPhotographer = null;
const initialSearchString = null;

const initialState = {
  location: initialLocation,
  date: initialDate,
  time: initialTime,
  timeEnd: initialTimeEnd,
  activity: initialActivity,
  tags: initialTags,
  photographer: initialPhotographer,
  newSearchParams: false,
  searchString: initialSearchString,
};

const searchParamsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_SEARCH_RESULT_PHOTOS: {
      // disregard payload, we just want to know if we searched
      return Object.assign({}, state, { searched: true, newSearchParams: false, });
    }
    case RECEIVE_SEARCH_LOCATION: {
      return Object.assign({}, state, {
        location: {
          label: action.label,
          id: action.id,
          value: action.id, // need value for Select tags
          lat: action.lat,
          lng: action.lng
        },
        newSearchParams: true,
      });
    }
    case RECEIVE_CLEAR_SEARCH_LOCATION: {
      return Object.assign({}, state, { location: initialLocation, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_DATE: {
      return Object.assign({}, state, { date: action.date, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_DATE: {
      return Object.assign({}, state, { date: initialDate, newSearchParams: true,});
    }
    case RECEIVE_SEARCH_TIME: {
      return Object.assign({}, state, { time: action.time, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_TIME: {
      return Object.assign({}, state, { time: initialTime, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_TIME_END: {
      return Object.assign({}, state, { timeEnd: action.timeEnd, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_TIME_END: {
      return Object.assign({}, state, { timeEnd: initialTimeEnd, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_ACTIVITY: {
      return Object.assign({}, state, { activity: action.activity, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_ACTIVITY: {
      return Object.assign({}, state, { activity: initialActivity, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_TAGS: {
      return Object.assign({}, state, { tags: action.tags, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_TAGS: {
      return Object.assign({}, state, { tags: initialTags, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_PHOTOGRAPHER: {
      return Object.assign({}, state, { photographer: action.photographer, newSearchParams: true, });
    }
    case RECEIVE_CLEAR_SEARCH_PHOTOGRAPHER: {
      return Object.assign({}, state, { photographer: initialPhotographer, newSearchParams: true, });
    }
    case RECEIVE_SEARCH_STRING: {
      return Object.assign({}, state, { searchString: buildSearchParamsQueryString(state) });
    }
    case CLEAR_ALL_SEARCH_PARAMS: {
      return initialState;
    }
    default:
      return state;
  }
}

export default searchParamsReducer;
