import {
  LatLngLiteral,
  LeafletEventHandlerFn,
  LeafletEventHandlerFnMap,
  LeafletMouseEvent,
} from 'leaflet';
import { useCallback, useMemo, useState } from 'react';

type UseMarkerReturnType = {
  newMarkerEventHandlers: LeafletEventHandlerFnMap;
  newMarkerMapEventHandlers: LeafletEventHandlerFnMap;
  newMarkerName: string | null;
  newMarkerPosition: LatLngLiteral | null;
  setNewMarkerName: (name: string | null) => void;
  setNewMarkerPosition: (position: LatLngLiteral | null) => void;
};

export default function useNewMarker(): UseMarkerReturnType {
  const [newMarkerName, setNewMarkerName] = useState<string | null>('');
  const [newMarkerPosition, setNewMarkerPosition] =
    useState<LatLngLiteral | null>(null);

  const newMarkerEventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      drag: (e: LeafletMouseEvent) => setNewMarkerPosition(e.latlng),
    }),
    []
  );

  const onClick: LeafletEventHandlerFn = useCallback(
    () => setNewMarkerPosition(null),
    []
  );

  const onDblClick: LeafletEventHandlerFn = useCallback(
    (e: LeafletMouseEvent) => setNewMarkerPosition(e.latlng),
    []
  );

  const newMarkerMapEventHandlers: LeafletEventHandlerFnMap = {
    click: onClick,
    dblclick: onDblClick,
  };

  return {
    newMarkerEventHandlers,
    newMarkerMapEventHandlers,
    newMarkerName,
    newMarkerPosition,
    setNewMarkerName,
    setNewMarkerPosition,
  };
}
