import classNames from 'classnames';
import CheckMarkIcon from '../icons/check_mark_icon';

const alertStyle = {
  backgroundColor: '#151515',
  color: 'white',
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  boxSizing: 'border-box'
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF'
};

const BaseIcon = ({ color, pushRight = true, children }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{ marginRight: pushRight ? '20px' : '0', minWidth: 24 }}
  >
    {children}
  </svg>
);

export const CloseIcon = () => (
  <BaseIcon color='#FFFFFF' pushRight={false}>
    <line x1='18' y1='6' x2='6' y2='18' />
    <line x1='6' y1='6' x2='18' y2='18' />
  </BaseIcon>
);

export const InfoIcon = () => (
  <BaseIcon color='#FFC539'>
    <circle cx='12' cy='12' r='10' />
    <line x1='12' y1='16' x2='12' y2='12' />
    <line x1='12' y1='8' x2='12' y2='8' />
  </BaseIcon>
);

export const SuccessIcon = () => (
  <BaseIcon color='#29ACFF'>
    <circle cx='12' cy='12' r='10' />
    <line x1='12' y1='16' x2='12' y2='12' />
    <line x1='12' y1='8' x2='12' y2='8' />
  </BaseIcon>
);

export const ErrorIcon = () => (
  <BaseIcon color='#FF0040'>
    <circle cx='12' cy='12' r='10' />
    <line x1='12' y1='8' x2='12' y2='12' />
    <line x1='12' y1='16' x2='12' y2='16' />
  </BaseIcon>
);

const AlertTemplate = ({ style, options, message, close }) => {
  const classes = classNames('wup-alert', 'alert-large', {
    'alert-xl': options.xlarge,
  });
  return (
    <div className={classes} style={{...alertStyle, ...style}}>
      {options.type === 'info' && <InfoIcon />}
      {options.type === 'success' && <CheckMarkIcon style={{ marginRight: '20px' }} />}
      {options.type === 'error' && <ErrorIcon />}
      <span style={{ flex: 2 }}>{message}</span>
      <button style={buttonStyle} onClick={close}>
        <CloseIcon />
      </button>
    </div>
  );
}

export default AlertTemplate;
