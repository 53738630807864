import { ComponentProps, ReactElement } from 'react';

type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps extends ComponentProps<HeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

export default function Heading({
  level,
  children,
  ...props
}: HeadingProps): ReactElement {
  const DynamicHeading = `h${level}` as HeadingElement;

  return <DynamicHeading {...props}>{children}</DynamicHeading>;
}
