import { LeafletMouseEventHandlerFn } from 'leaflet';
import { ReactElement, useCallback } from 'react';
import {
  LayerGroup,
  LayerGroupProps,
  MapContainer,
  MapContainerProps,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import GeosearchLayer from './geosearch_layer';

export default function Cartography({
  zoom = 13,
  children,
  center,
  ...props
}: MapContainerProps): ReactElement {
  return (
    <MapContainer
      style={{ height: '100%', margin: '0', width: '100%' }}
      zoom={zoom}
      center={center}
      {...props}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <BaseLayer>{children}</BaseLayer>
      <GeosearchLayer />
    </MapContainer>
  );
}

function BaseLayer({ children }: LayerGroupProps) {
  const map = useMap();

  const onDblClick: LeafletMouseEventHandlerFn = useCallback(
    (e) => map.flyTo(e.latlng),
    [map]
  );
  useMapEvents({
    dblclick: onDblClick,
  });

  return <LayerGroup>{children}</LayerGroup>;
}
