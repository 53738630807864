import qs from 'qs';
import { ReactElement, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { clearOrder } from '../../actions/cart_actions';
import { RetrieveStripeOrder } from '../../types';
import { retrieveStripeOrder } from '../../util/api/order_api_util';

function ThankYouPage(props: any): ReactElement {
  const [returnOrder, setReturnOrder] = useState<RetrieveStripeOrder>(Object);
  const location = useLocation();
  const alert = useAlert();

  useEffect(() => {
    if (location.search) {
      const queryParams = qs.parse(location.search.substring(1));
      const stripeSessionId = queryParams.sessionId;
      retrieveStripeOrder(stripeSessionId).
        then((res) => {
          if (res.found) {
            setReturnOrder(res);

            if (props.cart.stripeSessionId == stripeSessionId)
              props.clearOrder();
          }
        });
    }
  }, [location.search]);

  const renderOrder = (): ReactElement => {
    const { found, order } = returnOrder;

    if (found) {
      return (
        <div className="container thank-you-page">
          <div className="row no-gutters">
            <div className="col">
              <div className="title">Confirmation | Download Images</div>
              <div className="thanks">Thank you for your purchase!</div>
              {/* <div className="print-receipt">Print receipt</div> */}
            </div>
          </div>
          <div className="receipt">
            <div className="row no-gutters">
              <div className="col">
                <div className="image-count">{order.lineItems.length} Images</div>
              </div>
            </div>
            <div>
              {order.lineItems.map((image, index) =>
                <div key={index} className="row no-gutters image-row">
                  <div className="col-2 picture-col">
                    <img src={image.thumbnailUrl} alt={image.name} className="image-pic"/>
                  </div>
                  <div className="col-8 description-col">
                    <div className="row no-gutters">
                      <div className="col-2 meta-data-categories">
                        <div>File Name:</div>
                        <div>Photographer:</div>
                        <div>Location:</div>
                        <div>Date & Time:</div>
                        <div>Size:</div>
                      </div>
                      <div className="col-10 meta-data">
                        <div>{image.name}</div>
                        <div>{image.photographer}</div>
                        <div>{image.locationName}</div>
                        <div>{image.photoDate} | {image.photoTime}</div>
                        <div>{image.width}x{image.height} | {image.size}</div>
                        <div className="license">Personal Use License</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 price-col">
                    <div className="row no-gutters align-items-start justify-content-end">
                      <div className="price cost">Price:</div>
                      <div className="value cost">${image.price}</div>
                    </div>
                    <div className="row no-gutters download-image">
                      <a
                        className='download-link'
                        href={`/api/photos/download/${image.productId}`}
                        download={image.name}
                        onClick={() => alert.success(`Downloading photo: ${image.name}. Please wait a few seconds and then check your downloads folder.`) }
                      >
                        <button type="button" className="button-download">Download</button>
                      </a>
                    </div>
                    <div className="row no-gutters download"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="row no-gutters justify-content-center">
              <Link to='/dashboard/past-orders'>
                <button type="button" className="button-dashboard">Go to Dashboard</button>
              </Link>
            </div>
            <hr className="split1" />
            {/* <div className="row no-gutters">
              <div className="order-number">Order#: {order.wunderpicsOrderId}</div>
            </div> */}
            <div className="row no-gutters justify-content-between purchase-summary">
              <div className="col-5 order-section">
                <div className="row no-gutters order-header">Order#:</div>
                <div className="row no-gutters order-info">{order.wunderpicsOrderId}</div>
                {/* <div className="row no-gutters payment-header">Payment - {orderSummary.payment.type}</div>
                <div className="row no-gutters payment-info break">{orderSummary.payment.summary}</div>
                <div className="row no-gutters payment-header">Billing Address</div>
                <div className="row no-gutters payment-info">
                  {orderSummary.billingAddress.name}
                  <br/>
                  {orderSummary.billingAddress.street}
                  <br/>
                  {orderSummary.billingAddress.city}, {orderSummary.billingAddress.state} {orderSummary.billingAddress.zip}
                  <br/>
                  {orderSummary.billingAddress.country}
                </div> */}
              </div>
              <div className="col-2 date">
                <div className="row no-gutters date-header">Date:</div>
                <div className="row no-gutters date-info">{order.checkoutCompleted}</div>
              </div>
              <div className="col-4 costs">
                <div className="row no-gutters justify-content-between">
                  <div className="fee value-pair">Subtotal:</div>
                  <div className="fee-cost value-pair">${order.subtotal}</div>
                </div>
                <div className="row no-gutters justify-content-between">
                  <div className="fee value-pair">Community Fee:</div>
                  <div className="fee-cost value-pair">${order.communityFee}</div>
                </div>
                <hr className="sum" />
                <div className="row no-gutters justify-content-between">
                  <div className="fee value-pair">Total:</div>
                  <div className="fee-cost value-pair">${order.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='text-center'>
          <h2>We&apos;re sorry. Something went wrong.</h2>
          <p>Return <Link to='/'>home</Link>.</p>
        </div>);
    }
  }

  return (
    <div className='thank-you-page page-container pad-top'>
      <div className='thank-you-inner'>
        {renderOrder()}
      </div>
    </div>
  )
}

// FIXME: Redux types
const mapStateToProps = (state: any) => ({
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  clearOrder: () => dispatch(clearOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
