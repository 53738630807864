import React from 'react';

const materialsData = {
    rows: [
        {
            title: "Materials Provided to WunderPics.com or Posted on Any WunderPics, Inc. Web Page",
            content: "WunderPics, Inc. does not claim ownership of the materials you provide to WunderPics.com (including feedback and suggestions) or post, upload, input or submit to any WunderPics, Inc. Site or our associated services (collectively \"Submissions\"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting WunderPics, Inc., our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission."
            +
            "<br/>"
            +
            "<br/>"
            +
            "No compensation will be paid with respect to the use of your Submission, as provided herein. WunderPics, Inc. is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in WunderPics, Inc.'s sole discretion."
            +
            "<br/>"
            +
            "<br/>"
            +
            "By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions."
        }
    ]
}

export default materialsData;