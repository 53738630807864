import React from 'react';

const TrackingUseBehaviorData = {
  rows: [
    {
      title: "Tracking Use Behavior",
      content: <p>WunderPics Inc. may keep track of the websites and pages our users visit within WunderPics Inc., in order to determine what WunderPics Inc. services are the most popular. This data is used to deliver customized content and advertising within WunderPics Inc. to customers whose behavior indicates that they are interested in a particular subject area.</p>
    }]
}

export default TrackingUseBehaviorData;
