import { ReactElement } from 'react';

export default function SelectAllIcon(props: any): ReactElement {
  return (
    <svg {...props} width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.92578" y="3.97461" width="17.5745" height="12.0598" rx="3.5" fill="white" stroke="#AEAEAE"/>
      <rect x="0.5" y="0.814453" width="17.5745" height="12.0598" rx="3.5" fill="white" stroke="#AEAEAE"/>
    </svg>
  );
}
