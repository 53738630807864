import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatBytes } from '../../util/function_util';
import { RootState } from '../../reducers/root_reducer';
import StorageIcon from '../icons/storage_icon';

interface UserStorageProps {
  displayUserName?: boolean;
  displayPlanName?: boolean;
  displayUpgradeButton?: boolean;
  storageUsedProp?: number;
}

export default function UserStorage({
  displayUserName = false,
  displayPlanName = false,
  displayUpgradeButton = false,
  storageUsedProp = 0,
}: UserStorageProps): ReactElement {
  const {
    username,
    storageUsed,
    storageLimit,
    storageLimitHuman,
    accountType,
  } = useSelector(
    (state: RootState) => state.session
  );

  const storage =  storageUsedProp > 0 ? storageUsedProp : storageUsed;
  const storagePercent = (storage / storageLimit) * 100
  const storageHuman = formatBytes(storage, 1);

  return (
    <div className='user-storage dashboard-home-storage'>
      {displayUserName && <div className='storage-username'>{username}</div>}
      <div className='storage-title'><StorageIcon /> Storage</div>
      <div className='storage-bar'>
        <div style={{ width: `${storagePercent}%`}} className='storage-bar-filled'>
        </div>
      </div>
      <div className='storage-text'>{storageHuman} of {storageLimitHuman} used</div>
      {displayPlanName && <div className='storage-plan-name'>{accountType}</div>}
      {displayUpgradeButton &&
        <div className='storage-upgrade-button'>
          <Link className='blue-button' to='/settings/plans'>Upgrade Plan</Link>
        </div>}
    </div>
  )
}
