import { connect } from 'react-redux';
import SearchBar from './search_bar';
import { selectOptionFormat } from '../../reducers/selectors';
import { receiveBannerHeight } from '../../actions/ui_actions';
import {
  searchPhotos,
  setSearchLocation,
  receiveSearchActivity,
  receiveSearchDate,
  receiveSearchTime,
  receiveSearchTimeEnd,
  receiveSearchString,
  receiveClearSearchActivity,
  receiveClearSearchLocation,
  receiveClearSearchDate,
  receiveClearSearchTime,
  receiveClearSearchTimeEnd,
} from '../../actions/search_actions';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  uploading: state.ui.uploading,
  tags: selectOptionFormat(state.entities.tags),
  locations: selectOptionFormat(state.entities.locations.collection),
  activities: selectOptionFormat(state.entities.activities),
  photographers: selectOptionFormat(state.entities.users, 'username'),
  searchLocation: state.ui.searchParams.location,
  searchDate: state.ui.searchParams.date,
  searchTime: state.ui.searchParams.time,
  searchTimeEnd: state.ui.searchParams.timeEnd,
  searchActivity: state.ui.searchParams.activity,
  newSearchParams: state.ui.searchParams.newSearchParams,
  searchString: state.ui.searchParams.searchString,
});

const mapDispatchToProps = (dispatch) => ({
  receiveBannerHeight: (height) => dispatch(receiveBannerHeight(height)),
  searchPhotos: () => dispatch(searchPhotos()),
  setSearchLocation: locationId => dispatch(setSearchLocation(locationId)),
  receiveSearchDate: date => dispatch(receiveSearchDate(date)),
  receiveSearchActivity: activity => dispatch(receiveSearchActivity(activity)),
  receiveSearchTime: time => dispatch(receiveSearchTime(time)),
  receiveSearchTimeEnd: timeEnd => dispatch(receiveSearchTimeEnd(timeEnd)),
  receiveSearchString: () => dispatch(receiveSearchString()),
  receiveClearSearchLocation: () => dispatch(receiveClearSearchLocation()),
  receiveClearSearchActivity: () => dispatch(receiveClearSearchActivity()),
  receiveClearSearchDateAndTime: () => {
    dispatch(receiveClearSearchDate());
    dispatch(receiveClearSearchTime());
    dispatch(receiveClearSearchTimeEnd());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBar);
