import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { withAlert, positions } from 'react-alert';

import { createSearchHashKey } from '../../util/function_util';
import ArrowDown from './../icons/arrow_down';
import BookmarkIcon from '../icons/bookmark_icon';
import ShareIcon from '../icons/share_icon';
import WupDropdown from '../generic/wup_dropdown';
import XIcon from './../icons/x_icon';

class SearchToolBar extends React.Component {

  constructor(props){
    super(props);

    this.renderDropdownPhotographer = this.renderDropdownPhotographer.bind(this);
    this.renderTagsDropdown = this.renderTagsDropdown.bind(this);
    this.handleSelectTags = this.handleSelectTags.bind(this);
    this.handleSelectPhotographer = this.handleSelectPhotographer.bind(this);
    this.clearSelectPhotographer = this.clearSelectPhotographer.bind(this);
    this.clearSelectTags = this.clearSelectTags.bind(this);
    this.renderSearchBreadCrumbs = this.renderSearchBreadCrumbs.bind(this);
    this.shareLink = this.shareLink.bind(this);
    this.handleFollowedSearchClick = this.handleFollowedSearchClick.bind(this);
    this.followedSearchExists = this.followedSearchExists.bind(this);
  }

  handleSelectPhotographer(user) {
    this.props.receiveSearchPhotographer(user);
  }

  handleSelectTags(value) {
    this.props.receiveSearchTags(value);
  }

  clearSelectPhotographer() {
    this.props.clearSearchPhotographer();
  }

  clearSelectTags() {
    this.props.clearSearchTags();
  }

  handleFollowedSearchClick() {
    const {
      searchDate,
      searchLocation,
      searchActivity,
      searchPhotographer,
      searchTime,
      searchTimeEnd,
      searchTags,
      currentUser,
    } = this.props;

    if (!currentUser.id) {
      this.props.alert.error(`Please login to follow a search!`);
      return;
    }

    let followedSearchParams = {
      date: searchDate.label,
      time: searchTime.label,
      time_end: searchTimeEnd.label,
      location_id: searchLocation.value,
      activity_id: searchActivity.value,
    };

    if (searchPhotographer && searchPhotographer.value) {
      followedSearchParams.photographer_id = searchPhotographer.value
    }

    if (searchTags) {
      followedSearchParams.tags = searchTags.map(tag => tag.value);
      followedSearchParams.tag_names = searchTags.map(tag => tag.label);
    }

    this.props.createFollowedSearch(followedSearchParams)
      .then(() => {
        this.props.alert.success(
          `You will now receive updates whenever photos get uploaded to this search query!`);
      });
  }

  /**
   * TODO: Add start time and end time
   */
  followedSearchExists() {
    const {
      searchDate,
      searchLocation,
      searchActivity,
      searchPhotographer,
      searchTags,
      searchTime,
      searchTimeEnd,
      currentUser: { followedSearches }
    } = this.props;

    if (followedSearches) {
      return followedSearches.some((followedSearch) => {
        return createSearchHashKey(
          searchDate.label,
          searchLocation.value,
          searchActivity.value,
          searchTime.label,
          searchTimeEnd.label,
          searchPhotographer,
          searchTags) === followedSearch.searchHash
      });
    }

    return false;
  }

  renderDropdownPhotographer() {
    return (
      <div>
        <p>Select a Photographer</p>
        <Select
          value={this.props.searchPhotographer}
          className='wup-select-dropdown'
          classNamePrefix='wup-select-dropdown'
          onChange={this.handleSelectPhotographer}
          options={this.props.photographers}
        />
        <div className='text-right'>
          <button className='btn btn-primary' onClick={this.props.searchPhotos}>Apply</button>
        </div>
      </div>
    );
  }

  renderTagsDropdown() {
    return (
      <div>
        <p>Select Tags</p>
        <Select
          isMulti
          value={this.props.searchTags}
          className='wup-select-dropdown'
          classNamePrefix='wup-select-dropdown'
          onChange={this.handleSelectTags}
          options={this.props.tags}
        />
        <div className='text-right'>
          <button className='btn btn-primary' onClick={this.props.searchPhotos}>Apply</button>
        </div>
      </div>
    );
  }

  renderSearchBreadCrumbs() {
    const {
      searchTags,
      searchPhotographer,
      searchLocation,
      searchDate,
      searchTime,
      searchTimeEnd,
      searchActivity,
    } = this.props;

    if (searchLocation.value) {
      return `${searchLocation.value && searchLocation.label}
        ${searchDate.value ? ' / '+searchDate.label: ''}
        ${searchActivity.value ? ' / '+searchActivity.label : ''}
        ${(searchDate.value && searchTime.value) ? ' / '+searchTime.label : ''}
        ${(searchDate.value && searchTimeEnd.value) ? ' - ' + searchTimeEnd.label : ''}
        ${searchPhotographer ? ' / '+searchPhotographer.label : ''}
        ${searchTags ? ' / '+searchTags.map(tag => `#${tag.label}`).join(',') : ''}`
    } else {
      return null;
    }
  }

  shareLink() {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    this.props.alert.show(`Search query copied to clipboard!`);
  }

  render() {
    const {
      numSearchPhotos,
      searchTags,
      searchPhotographer,
      searchLocation,
      searchActivity,
      searchDate,
    } = this.props;

    const photographerButtonClasses = classNames('btn outline-button', {
      'outline-button-active': searchPhotographer,
    });
    const tagsButtonClasses = classNames('btn outline-button', {
      'outline-button-active': searchTags,
    });
    const followedSearchClasses = classNames('fs-button btn outline-button outline-button-left', {
      'disabled': !(searchLocation.value && searchActivity.value && searchDate.value),
      'following': this.followedSearchExists(),
    });

    return (
      <div className='search-tool-bar'>
        <WupDropdown
          label={
            <div className={photographerButtonClasses}>
              Photographer
              {searchPhotographer ?
                <XIcon className='outline-button-icon-right x-icon icon-white' onClick={this.clearSelectPhotographer} /> :
                <ArrowDown className='outline-button-icon-right' color='#000' />}
            </div>
          }
          dropdown={this.renderDropdownPhotographer()}
        />
        <WupDropdown
          label={
            <div className={tagsButtonClasses}>
              Tags { (searchTags && searchTags.length > 0) ? `(${searchTags.length})` : null}
              {searchTags ?
                <XIcon className='outline-button-icon-right x-icon icon-white' onClick={this.clearSelectTags} /> :
                <ArrowDown className='outline-button-icon-right' color='#000' />}
            </div>
          }
          dropdown={this.renderTagsDropdown()}
        />
        <div className='tool-bar-left'>
          <div className='count-and-query'>
            <strong>Search Results: {numSearchPhotos}</strong>
            <br />
            <em>{this.renderSearchBreadCrumbs()}</em>
          </div>
          <div
            onClick={this.handleFollowedSearchClick}
            className={followedSearchClasses}
          >
            <BookmarkIcon className='outline-button-icon-left' />
            Follow Search
          </div>
          <div onClick={this.shareLink} className='btn outline-button outline-button-left'>
            <ShareIcon className='outline-button-icon-left' />
            Share Search
          </div>
        </div>
      </div>
    );
  }
}

export default withAlert()(SearchToolBar);
