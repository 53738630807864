import React from 'react';
import { Link } from 'react-router-dom';

import FacebookIcon from './icons/facebook_icon';
import InstagramIcon from './icons/instagram_icon';
import TwitterIcon from './icons/twitter_icon';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.updateEmail = this.updateEmail.bind(this);
  }

  updateEmail(e) {
    this.setState({ email: e.target.value})
  }

  render() {
    return (
      <footer className='row no-gutters'>
        <div className='col-md-6'>
          <div className='footer-inner'>
            <h3>Subscribe</h3>
            <p>Sign up for exclusive offers, original stories, events, and more.</p>

            <div id="mc_embed_signup">
              <form action="https://wunderpics.us1.list-manage.com/subscribe/post?u=fa57e8df5a5b38d51f1558f7b&amp;id=7e9be20709" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                  <input onChange={this.updateEmail} type='email' value={this.state.email} name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email Address" required />
                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input readOnly type="text" name="b_fa57e8df5a5b38d51f1558f7b_7e9be20709" tabIndex="-1" value="" /></div>
                  <div className="clear">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn white-button" />
                  </div>
                </div>
              </form>
            </div>

            <div className='d-flex'>
              <Link to='/'>Terms</Link>
              <Link to='/'>Privacy</Link>
              <span>{new Date().getFullYear()} WunderPics Inc.</span>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='footer-inner'>
            <h3>More Info</h3>
            <ul>
              <li><Link to='/help'>Help</Link></li>
              <li><Link to='/terms-and-conditions'>Terms &amp; Conditions</Link></li>
              <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
              <li><Link to='/about'>About</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
              <li><Link to='/'>My Account</Link></li>
            </ul>
            <h3>Follow Us</h3>
            <div className='socials'>
              <a href='https://www.instagram.com/wunder.pics/'><InstagramIcon /></a>
              <a href='https://www.facebook.com/WunderPics/'><FacebookIcon /></a>
              <a href='https://twitter.com/wunderpicsapp'><TwitterIcon /></a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
