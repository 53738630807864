import React from 'react';

class WupDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  handleClick(e) {
    if (this.node.contains(e.target)) {
      if (!this.state.isOpen) {
        this.setState({ isOpen: true });
      }
      return;
    }
    this.handleClickOutside();
  }

  handleClickOutside() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => this.props.callBack ? this.props.callBack() : null);
    }
  }

  handleCallback(){
    if (this.props.callBack) {
      this.props.callBack();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  render() {
    const { className } = this.props;
    const { isOpen } = this.state;
    const classes = className ? 'wup-dropdown ' + className : 'wup-dropdown';

    return (
      <div className={classes} ref={node => this.node = node}>
        {this.props.label}
        {isOpen && (
          <div className={`wup-dropdown-content ${this.props.contentWrapperClassName}`}>
            {this.props.dropdown}
          </div>
        )}
      </div>
    )
  }
}

export default WupDropdown;
