import React from 'react';
import SearchMapContainer from './search_map_container';
import StreamContainer from './stream_container';

const StreamSearchMap = () => (
  <div className='stream-search-map d-flex'>
    <SearchMapContainer />
    <StreamContainer />
  </div>
);

export default StreamSearchMap;
