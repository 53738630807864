import { Helmet } from 'react-helmet';

interface SEOProps {
  title: string;
}

export default function SEO({
  title,
}: SEOProps) {

  const fullTitle = `WunderPics - ${title}`

  return (
    <Helmet
      title={title}
    >
      <meta property='og:title' content={fullTitle} />
      <meta property='twitter:title' content={fullTitle} />
      <meta name='title' content={fullTitle} />
    </Helmet>
  );
}
