import BankAccIcon from '../../icons/bank_acc_icon'
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardBody, Button } from 'reactstrap';

const EmptyBankAccSection = () => {
  return (
    <Card className='dashboard-home-card'>
      <CardBody className='dashboard-card-body'>
        <BankAccIcon className='empty-dashboard-icons'/>
        <CardTitle className='dashboard-card-title'>Start Getting Paid</CardTitle>
        <Link to='/settings/payout'>
          <Button className='dashboard-home-btn'>Connect Bank Account</Button>
        </Link>
      </CardBody>
    </Card>
  )
}

export default EmptyBankAccSection;
