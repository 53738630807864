import React from 'react';
import { connect } from 'react-redux';

import StreamPhotoContainer from './stream_photo_container';
import { asArray } from '../../reducers/selectors';
import { createLineItem } from '../../actions/cart_actions';

/**
 * Definitions for how this component works
 *
 * 1. clientHeight: the inner height of an element in pixels, inclduing padding, but NOT border or margin (only visible)
 * 2. offsetHeight: height of an element including borders and padding (visible)
 * 3. scrollHeight: measurement of an element's content including content not visible due to overflow
 * 4. scrollTop: gets the number of pixels that an element's content is scrolled vertically.
 */
class Temporal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayTime: '',
      firstTime: '',
      lastTime: '',
      dots: [],
      photosContainerClientHeight: 0, // Viewable Height of Photos Container
      photosContainerScrollHeight: 0, // Scrollable Height of Photos Container
      scrollbarHeight: 0, // Height of Scrollbar
      scrolledStringPercent: '0%',
    };

    // Methods
    this.setHeights = this.setHeights.bind(this);
    this.calculateScrollPercent = this.calculateScrollPercent.bind(this);
    this.generateDisplayTime = this.generateDisplayTime.bind(this);
    this.generateDots = this.generateDots.bind(this);
    this.renderSearchPhotos = this.renderSearchPhotos.bind(this);

    // eventHandlers
    this.handleTemporalContainerScroll = this.handleTemporalContainerScroll.bind(this);
    this.handleWheel = this.handleWheel.bind(this);

    // Refs
    this.photosContainerRef = React.createRef();
    this.scrollbarRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.generateDots();
    this.setHeights();
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    this.setHeights();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    const { focusedPhoto, numPhotos } = this.props;

    if (focusedPhoto !== -1) {
      if (e.keyCode === 39 && ((focusedPhoto + 1) !== numPhotos)) {
        // right arrow
        this.props.receiveFocusedPhoto(focusedPhoto+1)
      } else if (e.keyCode === 37 && focusedPhoto !== 0) {
        // left arrow
        this.props.receiveFocusedPhoto(focusedPhoto-1)
      }
    }
  }

  /**
   * setHeights() gets called when mounting and when updating. It get's called when updating because
   * we need to recalculate the heights of the containers for our scrollbar percentages.
   *
   * photosContainerRef = The Photos Container, extends past the scrollbar
   * scrollbarRef = The actual scrollbar, blue bar
   */
  setHeights() {
    setTimeout(() => {
      if (this.state.photosContainerScrollHeight !== this.photosContainerRef.current.scrollHeight) {
        this.setState({ photosContainerScrollHeight: this.photosContainerRef.current.scrollHeight });
      }

      if (this.state.photosContainerClientHeight !== this.photosContainerRef.current.clientHeight) {
        this.setState({ photosContainerClientHeight: this.photosContainerRef.current.clientHeight });
      }

      if (this.state.scrollbarHeight !== this.scrollbarRef.current.clientHeight) {
        this.setState({ scrollbarHeight: this.scrollbarRef.current.clientHeight });
      }
    }, 1)
  }

  renderSearchPhotos() {
    // FIXME: Render every photo in every size to fix performance?
    let photoElements = [];
    const mql = window.matchMedia('(max-device-width: 481px)');
    const isMobile = mql.matches;

    const gridNumber = isMobile ? 3 : 5;

    const {
      photos,
      focusedPhoto,
      createLineItem,
    } = this.props;

    for (let index = 0; index < photos.length; index++) {
      photoElements.push(
        <StreamPhotoContainer
          index={index}
          createLineItem={createLineItem}
          key={index}
          photo={photos[index]}
        />
      );
    }

    if (focusedPhoto !== null && focusedPhoto !== -1) {
      //FIXME: This should be a dynamic number based on resolution
      const insertIndex = Math.floor(focusedPhoto / gridNumber ) * gridNumber;
      const largePhoto = <StreamPhotoContainer
        index={focusedPhoto}
        key={focusedPhoto-100}
        createLineItem={createLineItem}
        photo={photos[focusedPhoto]}
        large={true}
        isMobile={isMobile}
      />
      photoElements.splice(insertIndex, 0, largePhoto); // inserts at index, deletes elements
    }

    return photoElements;
  }

  generateDots(numDots=30) {
    let dots = [];
    let i = 0;
    while (i < 100) {
      dots.push(<span key={i} style={{ top: `${i}%` }} className='dot'></span>)

      i += 100 / numDots;
    }

    dots.shift(); // remove the first dot
    dots.pop(); // remove the last dot (causes screen to scroll because pushes past viewport)

    this.setState({ dots })
  }

  calculateScrollPercent(scrollTop) {
    const {
      photosContainerScrollHeight,
      photosContainerClientHeight,
    } = this.state;


    const heightToScroll = photosContainerScrollHeight - photosContainerClientHeight;
    const scrolledPercent = (scrollTop / heightToScroll) * 100;
    const scrolledStringPercent = `${scrolledPercent}%`;

    this.setState({ scrolledStringPercent });
  }

  generateDisplayTime(scrollTop){
    const {
      photosContainerClientHeight,
      photosContainerScrollHeight
    } = this.state;

    const { times } = this.props;

    const heightToScroll = photosContainerScrollHeight - photosContainerClientHeight;
    const numElements = times.length - 1;
    const increment = heightToScroll / numElements;
    const index = Math.floor(scrollTop / increment);

    this.setState({ displayTime: times[index] });
  }

  /**
   * 1. Calculate percentage we have scrolled through the PhotosContainer
   * 2. Generate the display time
   *
   * @param {SyntheticEvent} _event Unused SyntheticEvent
   */
  handleTemporalContainerScroll(_event) {
    const scrollTop = this.photosContainerRef.current.scrollTop;
    // const photosContainerScrollHeight = this.photosContainerRef.current.scrollHeight;

    this.calculateScrollPercent(scrollTop);
    this.generateDisplayTime(scrollTop)
  }

  /**
   * It's crazy that this event triggers `onScroll` event for the container, but I'm glad it does
   *
   * @param {SyntheticEvent} event  SyntheticEvent used to simulate a scroll up or down
   */
  handleWheel(event) {
    this.photosContainerRef.current.scrollTop += event.deltaY;
  }

  // setTimes() {
  //   const { times } = this.props;
  //   if (times != this.state.times) {
  //     if (!_.isEmpty(times)) {
  //       this.setState({
  //         firstTime: times[0],
  //         lastTime: times[times.length - 1]
  //       });
  //     }
  //   }
  // }

  render() {
    const {
      bannerHeight,
      times,
      photos,
    } = this.props;

    const firstTime = times[0];
    const lastTime = times[times.length - 1]

    const {
      scrolledStringPercent,
      scrollbarHeight,
      photosContainerClientHeight,
      displayTime,
    } = this.state;

    return (
      <div className='search-temporal-container'>
        <div
          onScroll={this.handleTemporalContainerScroll}
          ref={this.photosContainerRef}
          className='photos-container'
          style={{
            height: `calc(100vh - ${bannerHeight}px)`
          }}
        >
          {this.renderSearchPhotos()}
        </div>
        <div
          onWheel={this.handleWheel}
          className='scrollbar-container-outer'
          style={{
            height: `calc(100vh - ${bannerHeight}px)`,
            position: 'relative',
          }}
        >
          <span className='first-time'>{firstTime}</span>
          <span className='last-time'>{lastTime}</span>
          {this.state.dots}
          <div
            className='scrollbar-container'
            style={{
              height: `calc(${photosContainerClientHeight}px - ${scrollbarHeight}px)`,
              marginTop: `${scrollbarHeight}px`
            }}
          >
            <div
              ref={this.scrollbarRef}
              className='scrollbar'
              style={{
                top: `calc(${scrolledStringPercent} - ${scrollbarHeight}px)`
              }}
            >
              <span className='scrollbar-display'>{displayTime || times[0] || ''}</span>
              <div className='cursor'></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bannerHeight: state.ui.dimensions.bannerHeight,
});

const mapDispatchToProps = (dispatch) => ({
  createLineItem: (lineItem) => dispatch(createLineItem(lineItem)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Temporal);
