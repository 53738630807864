import React from 'react';

const PersonalInfoCollectionData = {
  rows: [
    {
      title: "Personal Information Collection",
      content: <div><p>Protecting your private information is our priority. This Statement of Privacy applies to wunderpics.com, and WunderPics Inc. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to WunderPics Inc. include wunderpics.com. The WunderPics Inc. website is a photo marketplace site. By using the WunderPics Inc. website, you consent to the data practices described in this statement.</p>
      <p>In order to better provide you with products and services offered, WunderPics Inc. may collect personally identifiable information, such as your:
      <br />
      <br />
      -	E-mail Address
      <br />
      <br />
      If you purchase WunderPics Inc.'s products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.
      <br />
      <br />
      Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through WunderPics Inc.'s public message boards, this information may be collected and used by others.
      <br />
      <br />
      We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. </p></div>
    }]
}

export default PersonalInfoCollectionData;
