import { ReactElement } from "react";

export default function Select(): ReactElement {
  return (
    <div className="selectDiv">
      <select>
        <option>Latest</option>
      </select>
    </div>
  );
}
