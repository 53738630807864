import React from 'react';

const AutoCollectedInfoData = {
  rows: [
    {
      title: "Automatically Collected Information",
      content: <p>Information about your computer hardware and software may be automatically collected by WunderPics Inc.. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the WunderPics Inc. website.</p>
    }]
}

export default AutoCollectedInfoData;
