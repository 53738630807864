import { connect } from 'react-redux';
import { receiveActivity } from '../../actions/activity_actions';
import { getCurrentUser } from '../../actions/session_actions';
import { selectOptionFormat } from '../../reducers/selectors';
import UploadPhotoManager from './upload_photo_manager';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  locations: state.entities.locations.collection,
  activities: selectOptionFormat(state.entities.activities),
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  receiveActivity: (activity) => dispatch(receiveActivity(activity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPhotoManager);
