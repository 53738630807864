import React from 'react';

const yourAccountData = {
    rows: [
        {
            title: "Your Account",
            content: "If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that WunderPics, Inc. is not responsible for third party access to your account that results from theft or misappropriation of your account. WunderPics, Inc. and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content at our sole discretion."
            +
            "<br />"
            +
            "<br />"
            +
            "By registering for an account on the Site, you agree and warrant that you have submitted an accurate and truthful account, registration, and identity information about yourself, and that you are thirteen (13) years old or older. If you do not meet these requirements you are not allowed access on the Site."
            +
            "<br />"
            +
            "<br />"
            +
            "If you register on the Site as a business and use the business name as your username, register using a business-owned domain email, and use registered trademarks and logos of the business in your profile information, you agree and personally guarantee you have the authority to register for said business and are legally bound by the provision of these terms and conditions."
            +
            "<br />"
            +
            "<br />"
            +
            "We reserve the right to change the username for your account under certain circumstances (for example, if your username appears unrelated to the name you use in everyday life and someone else claims the username.)"
            +
            "<br />"
            +
            "<br />"
            +
            "Users are only allowed to connect a single account to one bank account. Connecting multiple accounts to a single bank account is prohibited and will result in the deletion of an account."
        }
    ]
}

export default yourAccountData;