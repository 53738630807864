import React from 'react';

const indemnificationData = {
    rows: [
        {
            title: "Indemnification",
            content: "You agree to indemnify, defend and hold harmless WunderPics, Inc., its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. WunderPics, Inc. reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with WunderPics, Inc. in asserting any available defenses."
        }
    ]
}

export default indemnificationData;