import { RECEIVE_PHOTOGRAPHERS } from '../actions/user_actions';

const initialState = {};

const usersReducer = (state = initialState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PHOTOGRAPHERS:
      return Object.assign({}, state, action.photographers);
    default:
      return state;
  }
}

export default usersReducer;
