import ReactDOM from 'react-dom';
import Root from './wunderpics/components/root';
import configureStore from './wunderpics/store/store';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  // Setup jQuery and Axios to pass in CSRF token on every request
  const token = $('meta[name="csrf-token"]').attr('content');
  $.ajaxSetup({ headers: { 'X-CSRF-Token': token } });
  axios.defaults.headers.common['X-CSRF-Token'] = token;

  let store;
  if (window.currentUser) {
    const preloadedState = {
      entities: {
        users: { [window.currentUser.id]: window.currentUser }
      },
      session: window.currentUser,
    };
    store = configureStore(preloadedState);
    delete window.currentUser;
  } else {
    store = configureStore();
  }
  const rootElement = document.getElementById('root');

  if (rootElement)
    ReactDOM.render(<Root store={store} />, rootElement);
});
