import AbsCenterContainer from '../library/abs_center_container';
import LoadingDots from './loading_dots';

export default function GenericLoading() {
  return (
    <>
      <AbsCenterContainer>
        <LoadingDots />
      </AbsCenterContainer>
    </>
  );
}
