import DateAndTime1 from 'images/uploader_guide/date-and-time-1.png';
import DateAndTime2 from 'images/uploader_guide/date-and-time-2.png';
import DateAndTime3 from 'images/uploader_guide/date-and-time-3.png';

const DateAndTimeData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>WunderPics scans the EXIF data of every photo you import in order to determine the time at which they were taken</li>
        <li>You will see the date and time of the first photo of your batch (left) and the last photo of your batch (right)</li>
        <li>If these times are accurate click “Yes, Date & Time is correct”</li>
        <li>If the date and time displayed is incorrect, select "No, Edit Date & Time"</li>
        <img src={DateAndTime1} alt='Date and Time Img1' />
        <p className='article-content-subtitle'>Edit the Date & Time</p>
        <li>The “Edit Date & Time” panel will display the date and time of the first photo of your batch</li>
        <li>Correcting the capture time of the first photo of your batch will shift the capture times accordingly to all of the other photos in your batch</li>
        <li>Click on the date and time under "Corrected time" to edit and correct the time</li>
        <li>When the date and time are correct, click "Apply"</li>
        <img src={DateAndTime2} alt='Date and Time Img2' />
        <li>After clicking “Apply” the new capture times will appear on the ride side. This will indicate how much you have adjusted the time</li>
        <li>When all is correct, select “Date & Time is correct”</li>
        <img src={DateAndTime3} alt='Date and Time Img3' />
      </ul>
    </div>
  )
}

export default DateAndTimeData