import { ReactElement, SVGProps } from 'react';

export default function PayPalIcon({
  className = 'paypal-icon',
  height = '26',
  width = '23',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.1638 2.02386C17.9663 0.656521 15.8017 0.0703125 13.0326 0.0703125H4.99566C4.42911 0.0703125 3.94768 0.483095 3.85898 1.04279L0.512593 22.3047C0.446066 22.7239 0.770118 23.1037 1.19432 23.1037H6.15595L7.40208 15.1856L7.36345 15.4336C7.45216 14.8739 7.93001 14.4611 8.49584 14.4611H10.8536C15.4855 14.4611 19.1123 12.5763 20.1717 7.12417C20.2032 6.96293 20.2304 6.80598 20.254 6.65262C20.1202 6.58168 20.1202 6.58168 20.254 6.65262C20.5694 4.63744 20.2518 3.2658 19.1638 2.02386Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M9.30061 5.92667C9.43294 5.86361 9.58031 5.82849 9.73482 5.82849H16.0356C16.7817 5.82849 17.4777 5.87722 18.1137 5.9797C18.2961 6.00908 18.4728 6.04276 18.6444 6.08146C18.8161 6.11944 18.9828 6.16244 19.1445 6.20974C19.2253 6.23339 19.3047 6.25776 19.3827 6.28355C19.6953 6.38818 19.9864 6.51001 20.254 6.65262C20.5694 4.63672 20.2518 3.2658 19.1638 2.02386C17.9656 0.656521 15.8017 0.0703125 13.0326 0.0703125H4.99495C4.42911 0.0703125 3.94768 0.483095 3.85898 1.04279L0.512594 22.304C0.446067 22.7239 0.770118 23.103 1.1936 23.103H6.15595L8.74121 6.67914C8.79414 6.34232 9.01018 6.06641 9.30061 5.92667Z"
          fill="white"
        />
        <path
          d="M20.1706 7.12426C19.1112 12.5757 15.4844 14.4612 10.8525 14.4612H8.49406C7.92822 14.4612 7.45037 14.874 7.36238 15.4337L5.81223 25.2788C5.75429 25.6457 6.03756 25.9783 6.40811 25.9783H10.5907C11.0858 25.9783 11.5071 25.6171 11.5843 25.1276L11.6251 24.914L12.4134 19.9091L12.4642 19.6324C12.5415 19.143 12.9628 18.7818 13.4578 18.7818H14.0838C18.1355 18.7818 21.308 17.1328 22.2351 12.3636C22.6221 10.3706 22.4218 8.70659 21.3982 7.53776C21.0877 7.18374 20.7021 6.89135 20.2529 6.65271C20.2286 6.80679 20.2021 6.96301 20.1706 7.12426Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M19.1443 6.20986C18.9826 6.16257 18.816 6.11957 18.6443 6.08159C18.4726 6.0436 18.2952 6.00992 18.1135 5.98054C17.4768 5.87734 16.7815 5.82861 16.0347 5.82861H9.73465C9.57942 5.82861 9.43206 5.86373 9.30044 5.92751C9.00929 6.06725 8.79397 6.34244 8.74104 6.67998L7.40191 15.1857L7.36328 15.4337C7.45127 14.874 7.92912 14.4612 8.49496 14.4612H10.8535C15.4853 14.4612 19.1121 12.5765 20.1715 7.12429C20.203 6.96305 20.2295 6.80682 20.2538 6.65275C19.9855 6.51085 19.6951 6.38831 19.3825 6.28439C19.3045 6.2586 19.2251 6.23351 19.1443 6.20986Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="22"
            height="26"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
