import faqBanner from 'images/faq-banner.png';

const HelpBanner = ({ text }) => {
  return (
    <div id="bannerContainer">
      <h1 id="bannerText">{text}</h1>
      <img id="faqBanner" src={faqBanner} alt="FAQ Banner" />
    </div>
  );
}

export default HelpBanner;
