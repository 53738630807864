import { ReactElement, useState } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TrashIcon from '../icons/trash_icon';
import { clearAllSearchParams } from '../../actions/search_actions';
import {
  destroyFollowedSearch,
  updateFollowedSearchNotifications,
} from '../../actions/session_actions';

interface FollowedSearchCardProps {
  id: number;
  followDate: string;
  followDateValue: string;
  followBeginTime: string;
  followEndTime: string;
  locationId: number;
  locationName: string;
  activityId: number;
  activityName: string;
  photographerId: number;
  photographerName: string;
  tags: number[];
  tagNames: string[];
  thumbnailUrl: string;
  newPhotos: number;
}

interface FollowedSearchSlug {
  locationId: number;
  activityId: number;
  date: string;
  time?: string;
  timeEnd?: string;
  photographerId?: number;
  tags?: number[];
}

export default function FollowedSearchCard({
  id,
  followDate,
  followDateValue,
  followBeginTime,
  followEndTime,
  locationId,
  locationName,
  activityId,
  activityName,
  photographerId,
  photographerName,
  tags,
  tagNames,
  thumbnailUrl,
  newPhotos,
}: FollowedSearchCardProps): ReactElement {
  const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const goToSearch = async () => {
    const followedSearchSlug: FollowedSearchSlug = {
      locationId: locationId,
      date: followDateValue,
      time: followBeginTime,
      timeEnd: followEndTime,
      activityId: activityId,
      tags: tags,
      photographerId: photographerId,
    };

    if (!photographerId)
      delete followedSearchSlug.photographerId;

    if (!tags)
      delete followedSearchSlug.tags;

    await dispatch(clearAllSearchParams());
    if (newPhotos > 0) {
      dispatch(updateFollowedSearchNotifications(id));
    }
    history.push('/search?' + qs.stringify(followedSearchSlug));
  }

  const handleDestroyFollowedSearch = () => {
    dispatch(destroyFollowedSearch(id));
  }

  const renderOverlay = () => {
    return (
      <div className='thumbnail-overlay'>
        <div className="goToSearch" onClick={() => goToSearch()}>Go to Search</div>
        <hr className='middleLine'></hr>
        <button onClick={handleDestroyFollowedSearch}><TrashIcon /></button>
      </div>
    )
  }

  return (
    <div className='followed-search-card'>
      <div className='followed-search-card-inner'>
        {newPhotos > 0 &&
            <div className='new-photos-num'>
              {newPhotos}
            </div>}
        <div className='thumbnailWrapper'>
          <div onMouseLeave={() => setDisplayOverlay(false)}
              onMouseEnter={() => setDisplayOverlay(true)} className="followedSearchesThumbnail">
            <div className="followedSearchesOverlay">{displayOverlay && renderOverlay()}</div>
            {thumbnailUrl ?
              <img src={thumbnailUrl} /> :
              <div className="noPhotosFoundText">
                <div>No Photos Found</div>
              </div>}
            {!thumbnailUrl && <div className="greyBox"></div>}
          </div>
        </div>
        <div className='searchText'>
          <div>{followDate}</div>
          <div>{locationName}</div>
          <div>{activityName}</div>
          <div>{followBeginTime} - {followEndTime}</div>
          {photographerName && <div>{photographerName}</div>}
          {tagNames && <div>{tagNames.map((tag) => `#${tag}`).join(', ')}</div>}
        </div>
      </div>
    </div>
  )
}
