import React from 'react';

const PictureTimeIcon = () => (
  <svg className='picture-time-icon' width="60" height="51" viewBox="0 0 60 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.21 0.940491C37.0547 0.940491 30.4199 7.57531 30.4199 15.7305C30.4199 23.8858 37.0547 30.5206 45.21 30.5206C53.3652 30.5206 60 23.8858 60 15.7305C60 7.57531 53.3652 0.940491 45.21 0.940491ZM50.0803 22.6922L43.731 16.3429V6.85651H46.689V15.1182L52.1717 20.6009L50.0803 22.6922Z" />
    <path d="M37.6804 17.0751H4.33559C2.03688 17.0751 0.16748 18.9595 0.16748 21.2768V46.4871C0.16748 48.8044 2.03688 50.6888 4.33559 50.6888H37.6804C39.9792 50.6888 41.8485 48.8044 41.8485 46.4871V21.2768C41.8485 18.9595 39.9792 17.0751 37.6804 17.0751ZM9.54572 23.3777C10.3748 23.3777 11.1699 23.7097 11.7562 24.3006C12.3424 24.8916 12.6718 25.6932 12.6718 26.5289C12.6718 27.3647 12.3424 28.1663 11.7562 28.7572C11.1699 29.3482 10.3748 29.6802 9.54572 29.6802C8.71663 29.6802 7.9215 29.3482 7.33525 28.7572C6.74899 28.1663 6.41964 27.3647 6.41964 26.5289C6.41964 25.6932 6.74899 24.8916 7.33525 24.3006C7.9215 23.7097 8.71663 23.3777 9.54572 23.3777ZM21.008 44.3863H6.41964L14.7559 33.882L17.8819 38.0837L24.1341 29.6802L35.5964 44.3863H21.008Z" />
  </svg>
);

export default PictureTimeIcon;
