import AddTags1 from 'images/uploader_guide/add-tags-1.png';

const AddTagsData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>Adding Tags is a great way to get more specific with the information attached to your photos</li>
        <li>Adding Tags is optional, if you would like to skip this step select “Skip Tags”</li>
        <li>If you would like to add tags, you can type multiple tags separated by commas and select “Apply”</li>
        <li>These tags will be applied to all photos. You can change the tags of individual photos later in the Upload Editor</li>
        <img src={AddTags1} alt='Add Tags Img1' />
      </ul>
    </div>
  )
}

export default AddTagsData