import classNames from 'classnames';
import ExifReader from 'exifreader';
import React from 'react';
import { withAlert } from 'react-alert';
import DatePicker from 'react-datepicker';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { selectOptionFormat } from '../../reducers/selectors';
import { createActivity } from '../../util/api/activity_api_util';
import { runFollowedSearchJob } from '../../util/api/jobs_api_util';
import { uploadToAWS } from '../../util/api/photo/photo_api_util';
import { postUploadBatches } from '../../util/api/upload_batch_api_util';
import DateTools from '../../util/date_tools';
import PhotoUploadDataFactory from '../../util/photo_upload_data_factory';
import PhotoGridPreview from '../generic/photo_grid_preview';
import SEO from '../generic/seo';
import ArrowBackIcon from '../icons/arrow_back_icon';
import GroupImagesIcon from '../icons/group_images_icon';
import LargeSuccessIcon from '../icons/large_success_icon';
import UploadingIcon from '../icons/uploading_icon';
import GenericMap from './../generic/generic_map';
import UserStorage from './../generic/user_storage';
import CalendarIcon from './../icons/calendar_icon';
import DollarSignIcon from './../icons/dollar_sign_icon';
import EditorIcon from './../icons/editor_icon';
import HelpIcon from './../icons/help_icon';
import LocationIcon from './../icons/location_icon';
import MorePhotosLeft from './../icons/more_photos_left';
import SurfingIcon from './../icons/surfing_icon';
import TagsIcon from './../icons/tags_icon';
import WpMapIconSelected from './../icons/wp_map_icon_selected';
import InitialUploadSidebar from './initial_upload_sidebar';
import UploadDropzone from './upload_dropzone';
import UploadDropzoneWithPreview from './upload_dropzone_with_preview';
import UploadPhotoCard from './upload_photo_card';
import UploadPhotoManagerContainer from './upload_photo_manager_container';
import SetLocation from './set_location';

const LOCATION_STAGE = 1;
const ACTIVITY_STAGE = 2;
const DATE_AND_TIME_STAGE = 3;
const PRICE_STAGE = 4;
const TAGS_STAGE = 5;
const UPLOAD_EDITOR_STAGE = 6;
const UPLOAD_MANAGER_STAGE = 7;
const PHOTO_MINIMUM_WIDTH = 1200;
const MAX_IMAGE_SIZE = 31457280;

const DATE_TIME_DIGITIZED_FIELD = 'DateTimeDigitized';
const IMAGE_HEIGHT_FIELD = 'Image Height';
const IMAGE_WIDTH_FIELD = 'Image Width';
const PIXEL_X_FIELD = 'PixelXDimension';
const PIXEL_Y_FIELD = 'PixelYDimension';


class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [],
      locationId: '',
      rawLocation: '',
      activityId: '',
      rawActivity: '',
      lastPhoto: '',
      explodedTime: '',
      delta: 0,
      displayDelta: false,
      timezone: {},
      displayTimestampEditor: false,
      stage: 0,
      completedStage: 0,
      bulkPrice: '0.00',
      bulkTag: '',
      chosenLocation: '',
      applyTags: [],
      firstPhoto: {},
      stagedFirstPhoto: {},
      newFirstPhoto: {},
      newLastPhoto: {},
      storageUsed: props.currentUser.storageUsed,
      storageLimit: props.currentUser.storageLimit,
      showUploadingModal: false,
      uploadComplete: false,
      shareLink: '',
      uploadBatchId: 0,
      uploadProgress: 0,
      validPrice: true,
    };

    this.photoUploadDataFactory = new PhotoUploadDataFactory();
    this.tagRegex = /^[a-zA-Z0-9_,]*$/;
    this.handleDrop = this.handleDrop.bind(this);
    this.readExifData = this.readExifData.bind(this);
    this.renderStage = this.renderStage.bind(this);
    this.renderDropzone = this.renderDropzone.bind(this);
    this.renderDropzoneWithPreview = this.renderDropzoneWithPreview.bind(this);
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.handleSelectActivity = this.handleSelectActivity.bind(this);
    this.getfirstAndLastPhoto = this.getfirstAndLastPhoto.bind(this);
    this.handleBadImage = this.handleBadImage.bind(this);
    this.renderUploadPhotoManager = this.renderUploadPhotoManager.bind(this);
    this.filterLowQuality = this.filterLowQuality.bind(this);
    this.filterTooLarge = this.filterTooLarge.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
    this.updateTagField = this.updateTagField.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.renderLocationStage = this.renderLocationStage.bind(this);
    this.renderActivityStage = this.renderActivityStage.bind(this);
    this.renderPriceStage = this.renderPriceStage.bind(this);
    this.renderTagsStage = this.renderTagsStage.bind(this);
    this.renderUploadEditorStage = this.renderUploadEditorStage.bind(this);
    this.cleanBulkPrice = this.cleanBulkPrice.bind(this);
    this.renderTimestampCheckStage = this.renderTimestampCheckStage.bind(this);
    this.renderTimestampEditStage = this.renderTimestampEditStage.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
    this.updateDeltaAndTimes = this.updateDeltaAndTimes.bind(this);
    this.revertPhotoTimes = this.revertPhotoTimes.bind(this);
    this.applyTags= this.applyTags.bind(this);
    this.handleTagKeyPress = this.handleTagKeyPress.bind(this);
    this.renderDeltaDisplay = this.renderDeltaDisplay.bind(this);
    this.handleSelectStagedFirstPhoto = this.handleSelectStagedFirstPhoto.bind(this);
    this.filterUserHasStorage = this.filterUserHasStorage.bind(this);
    // Upload
    this.uploadAll = this.uploadAll.bind(this);
    this.buildUploadBatchParams = this.buildUploadBatchParams.bind(this);
    this.renderUploadingModal = this.renderUploadingModal.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
  }

  async readExifData(image) {
    const exifData = await ExifReader.load(image)

    image.exifData = exifData;
    return image;
  }

  createImageBlob(image) {
    return new Promise((resolve, reject) => {
      try {
        // We could also add thumbnail here from exifData, but doesn't seem necessary
        image.browserPath = window.URL.createObjectURL(image);
        resolve(image);
      } catch (_error) {
        reject(image);
      }
    });
  }

  updateField(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    }
  }

  updateTagField(e) {
    if (this.tagRegex.test(e.target.value))
      this.setState({ bulkTag: e.target.value });
  }

  resolveExifData = async (image) => {
    let retrievedDate = image.exifData[DATE_TIME_DIGITIZED_FIELD]?.value[0];

    if (!retrievedDate) {
      retrievedDate = image.exifData['DateTimeOriginal']?.value[0]
    }

    // All images must have date and time
    if (!retrievedDate) {
      throw new Error(`Invalid metadata for image: ${image.name}`)
    }

    const exifDate = DateTools.parseExifDate(retrievedDate);
    image.parsedDate = exifDate.date;
    image.parsedTime = exifDate.time;
    image.parsedTimestamp = exifDate.timestamp;
    image.dateObject = new Date(exifDate.timestamp);

    const imageWidth = image.exifData[PIXEL_X_FIELD]?.value || image.exifData[IMAGE_WIDTH_FIELD]?.value;
    const imageHeight = image.exifData[PIXEL_Y_FIELD]?.value || image.exifData[IMAGE_HEIGHT_FIELD]?.value;
    image.width = imageWidth;
    image.height = imageHeight;

    return image;
  }

  handleDrop(files) {
    if (files) {
      files.forEach(file => {
        this.filterUserHasStorage(file).
          then(this.filterTooLarge).
          then(this.readExifData).
          then(this.resolveExifData).
          then(this.filterLowQuality).
          then(this.createImageBlob).
          then((image) => {
            image.uploaded = false;
            image.price = '0.00';
            image.tags = new Set();
            image.id = Math.random().toString(16).slice(2);
            this.setState({ photos: this.state.photos.concat(image) }, this.getfirstAndLastPhoto);
            return image;
          }).
          catch(this.handleBadImage);
      });
    }
  }

  filterUserHasStorage(photo) {
    const { storageUsed, storageLimit } = this.state;
    return new Promise((resolve, reject) => {
      if ((storageUsed + photo.size) > storageLimit) {
        reject(photo)
      } else {
        this.setState({ storageUsed: storageUsed + photo.size });
        resolve(photo);
      }
    });
  }

  filterLowQuality(photo) {
    return new Promise((resolve, reject) => {
      let width = photo?.width || 0;
      let height = photo?.height || 0;

      if ((width < PHOTO_MINIMUM_WIDTH) && (height < PHOTO_MINIMUM_WIDTH)) {
        reject(photo);
      } else {
        resolve(photo);
      }
    });
  }

  filterTooLarge(photo) {
    return new Promise((resolve, reject) => {
      if (Number(photo.size) > MAX_IMAGE_SIZE) {
        reject(photo);
      } else {
        resolve(photo);
      }
    });
  }

  handleBadImage(image) {
    const { storageUsed, storageLimit } = this.state;
    const { alert } = this.props;

    if ((storageUsed + image.size) > storageLimit) {
      alert.show(`Photo '${image.name}' not uploaded. (Reason: User does not have sufficient storage space)`);
      return;
    }

    if (image instanceof Error) {
      // debugger; // FIXME: log to service/database
      alert.show(`Something went wrong. (${image.toString()})`);
    } else if (image.type !== "image/jpeg") {
      alert.show(`Image '${image.name}' not uploaded. (Reason: Wrong image type)`);
    } else if ((image.width || 0) < PHOTO_MINIMUM_WIDTH) {
      alert.show(
        `Image '${image.name}' not uploaded. (Reason: Picture must be ${PHOTO_MINIMUM_WIDTH} pixels or above)`);
    } else if (Number(image.size) > MAX_IMAGE_SIZE) {
      alert.show(
        `Image '${image.name}' not uploaded. (Reason: Picture must be less than 30mb)`);
    }
  }

  renderDropzone() {
    return <UploadDropzone handleDrop={this.handleDrop} />;
  }

  renderDropzoneWithPreview() {
    return <UploadDropzoneWithPreview
      handleDrop={this.handleDrop}
      photos={this.state.photos}
      onClickButton={() => this.setState({ stage: 1, completedStage: 1})}
    />
  }

  /**
   * Props passed into the PhotoManager are just used to `prime` the manager
   */
  renderUploadPhotoManager() {
    const {
      photos,
      firstPhoto,
      lastPhoto,
      rawLocation,
      rawActivity,
      newFirstPhoto,
      newLastPhoto,
      delta,
      timezone,
    } = this.state;

    const updatedPhotos = photos.map(photo => {
      const newDate = DateTools.addDeltaMillis(photo.parsedTimestamp, delta);
      photo.parsedDate = newDate.parsedDate;
      photo.parsedTime = newDate.parsedTime;
      photo.parsedTimestamp = newDate.parsedTimestamp;
      photo.dateObject = newDate.dateObject;

      return photo;
    });

    return (
      <UploadPhotoManagerContainer
        rawLocation={rawLocation}
        rawActivity={rawActivity}
        firstPhoto={firstPhoto}
        lastPhoto={lastPhoto}
        newFirstPhoto={newFirstPhoto}
        newLastPhoto={newLastPhoto}
        photos={updatedPhotos}
        delta={delta}
        timezone={timezone}
      />
    );
  }

  /**
   *
   * @param {Object} location {label: 'HB', value: 1}
   */
  handleSelectLocation(location) {
    this.setState({
      rawLocation: location,
      locationId: location.value,
    });
  }

  handleSelectActivity(activity) {
    if (activity.__isNew__) {
      createActivity({ name: activity.label })
        .then((newActivity) => {
          this.setState({
            rawActivity: {
              label: newActivity.name,
              value: newActivity.id,
            },
            activityId: newActivity.id,
          })
          this.props.receiveActivity(newActivity);
        })
    } else {
      this.setState({
        rawActivity: activity,
        activityId: activity.value,
      });
    }
  }

  getfirstAndLastPhoto() {
    const { photos } = this.state;
    const sortedPhotos = photos.sort((a, b) => a.dateObject - b.dateObject);
    const firstPhoto = sortedPhotos[0];
    const lastPhoto = sortedPhotos[sortedPhotos.length - 1];

    this.setState({
      firstPhoto: {
        browserPath: firstPhoto.browserPath,
        dateObject: firstPhoto.dateObject,
        parsedTime: firstPhoto.parsedTime,
        parsedDate: firstPhoto.parsedDate,
        parsedTimestamp: firstPhoto.parsedTimestamp,
      },
      stagedFirstPhoto: {
        browserPath: firstPhoto.browserPath,
        dateObject: firstPhoto.dateObject,
        parsedTime: firstPhoto.parsedTime,
        parsedDate: firstPhoto.parsedDate,
        parsedTimestamp: firstPhoto.parsedTimestamp,
      },
      newFirstPhoto: {
        browserPath: firstPhoto.browserPath,
        dateObject: firstPhoto.dateObject,
        parsedTime: firstPhoto.parsedTime,
        parsedDate: firstPhoto.parsedDate,
        parsedTimestamp: firstPhoto.parsedTimestamp,
      },
    });

    if (firstPhoto !== lastPhoto) {
      this.setState({
        lastPhoto: {
          browserPath: lastPhoto.browserPath,
          dateObject: lastPhoto.dateObject,
          parsedTime: lastPhoto.parsedTime,
          parsedDate: lastPhoto.parsedDate,
          parsedTimestamp: lastPhoto.parsedTimestamp,
        },
        newLastPhoto: {
          browserPath: lastPhoto.browserPath,
          dateObject: lastPhoto.dateObject,
          parsedTime: lastPhoto.parsedTime,
          parsedDate: lastPhoto.parsedDate,
          parsedTimestamp: lastPhoto.parsedTimestamp,
        }
      });
    }
  }

  handleBack() {
    const { stage } = this.state;
    const newStage = stage - 1;
    this.setState({ stage: newStage});
  }

  updateStages(newStage, newCompletedStage) {
    return () => {
      const { completedStage } = this.state;
      const updateCompletedStage = newCompletedStage >= completedStage ? newCompletedStage : completedStage;
      this.setState({ stage: newStage, completedStage: updateCompletedStage });
    }
  }

  renderStage() {
    const { stage, displayTimestampEditor, photos } = this.state;

    if (stage === 0) {
      if (_.isEmpty(photos))
        return this.renderDropzone();
      else
        return this.renderDropzoneWithPreview();
    }

    if (stage === LOCATION_STAGE) {
      return this.renderLocationStage();
    }

    if (stage === ACTIVITY_STAGE) {
      return this.renderActivityStage();
    }

    if (stage === DATE_AND_TIME_STAGE) {
      if (displayTimestampEditor)
        return this.renderTimestampEditStage();
      else
        return this.renderTimestampCheckStage();
    }

    if (stage === PRICE_STAGE) {
      return this.renderPriceStage();
    }

    if (stage === TAGS_STAGE) {
      return this.renderTagsStage();
    }

    if (stage === UPLOAD_EDITOR_STAGE) {
      return this.renderUploadEditorStage();
    }
  }

  renderHelp() {
    this.props.alert.show('Please refer to our Help page if you have any questions');
  }

  renderLocationStage() {
    const { rawLocation } = this.state;
    const { locations } = this.props;

    let centerLocation;
    if (rawLocation.value) {
      const location = locations.filter(loc => loc.id === rawLocation.value)[0];
      centerLocation = Object.assign({}, rawLocation, location);
    }

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Location</h3>
        <SetLocation
          value={rawLocation}
          locations={locations}
          handleSelectLocation={this.handleSelectLocation}
          handleSubmit={this.handleSelectLocation}
          displayModalButtons={false}
        />
        <div className='pre-upload-bottom'>
          <button
            onClick={this.updateStages(ACTIVITY_STAGE, ACTIVITY_STAGE)}
            className='upload-button-large'
            disabled={!rawLocation}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  renderActivityStage() {
    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Activity</h3>
        <div className='row pre-upload-activity'>
          <div className='col-8'>
            <h4>What is the Activity for these photos?</h4>
            <Select
              name='upload-activity'
              placeholder='Search Activity...'
              value={this.state.rawActivity}
              className='upload-activity-select upload-select'
              classNamePrefix='upload-select'
              onChange={this.handleSelectActivity}
              options={this.props.activities}
            />
          </div>
        </div>
        <div className='pre-upload-bottom'>
          <a className='upload-back-button' onClick={this.handleBack}><ArrowBackIcon className='arrow-back-icon' />Back</a>
          <button
            onClick={this.updateStages(DATE_AND_TIME_STAGE, DATE_AND_TIME_STAGE)}
            className='upload-button-large'
            disabled={!this.state.rawActivity}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  cleanBulkPrice() {
    const { bulkPrice } = this.state;
    if (bulkPrice) {
      const price = parseFloat(bulkPrice);
      const cleanPrice = price.toFixed(2);

      if ((cleanPrice < 1.99) && (cleanPrice != 0.00)) {
        this.props.alert.show('Price per photo must be at least $2.00! (or free)');
        this.setState({ validPrice: false })
      } else {
        this.setState({ validPrice: true })
        this.setState({ bulkPrice: cleanPrice });
      }
    }
  }

  renderPriceStage() {
    const { photos, bulkPrice } = this.state;

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Price</h3>
        <div className='row pre-upload-price'>
          <div className='col-6'>
            <h4>How much do you want to sell each photo for?</h4>
            <input
              onChange={this.updateField('bulkPrice')}
              type='number'
              placeholder='0.00'
              min='0.00'
              step='1.00'
              className='form-control price-input'
              value={this.state.bulkPrice}
              onBlur={this.cleanBulkPrice}
            />
            <p className='price-preview'>You are selling each photo for <span>${this.state.bulkPrice}</span></p>
            <p>You can change the price of individual photos later in the <strong>Upload Editor</strong></p>
            <p>The minimum amount you may sell a photo for is $2.00</p>
          </div>
        </div>
        <div className='pre-upload-bottom'>
          <a className='upload-back-button' onClick={this.handleBack}><ArrowBackIcon className='arrow-back-icon' />Back</a>
          <button
            onClick={
              () => {
                const updatedPhotos = photos.map(photo => {
                  photo.price = bulkPrice;
                  return photo;
                });
                this.setState({ stage: TAGS_STAGE, completedStage: TAGS_STAGE, photos: updatedPhotos });
              }
            }
            disabled={!this.state.validPrice}
            className='upload-button-large'
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  applyTags() {
    const { bulkTag, applyTags } = this.state;
    const newTagList = bulkTag.split(',').filter(tag => tag)
    this.setState({
      bulkTag: '',
      applyTags: [...new Set(applyTags.concat(newTagList))],
    });
  }

  handleTagKeyPress(e) {
    if (e.key === 'Enter') {
      this.applyTags();
    }
  }

  removeTag(removalTag) {
    return (e) => {
      e.preventDefault();
      const { applyTags } = this.state;

      this.setState({ applyTags: applyTags.filter(tag => tag !== removalTag) });
    }
  }

  renderTagsStage() {
    const { photos, applyTags } = this.state;

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Tags</h3>
        <div className='row pre-upload-tags'>
          <div className='col-6'>
            <h4>Get more specific by adding Tags to your Photos (Optional)</h4>
            <p>These tags will be applied to all photos. You can change the tags of individual photos in the <strong>Upload Editor</strong></p>
            <p>Separate Tags by a comma</p>
            <div className='tags-input-button'>
              <input
                type='text'
                placeholder='Ex: surf,red,wave'
                className='form-control tag-input'
                value={this.state.bulkTag}
                onChange={this.updateTagField}
                onKeyDown={this.handleTagKeyPress}
              />
              <button
                className='upload-button-large'
                onClick={this.applyTags}
              >
                Apply
              </button>
            </div>
            <div className='display-apply-tags'>
              {applyTags.map((tag, i) => <span key={i}>{`#${tag}`} <a className='tag-delete' onClick={this.removeTag(tag)}>&#xd7;</a></span>)}
            </div>
          </div>
        </div>
        <div className='pre-upload-bottom'>
          <a className='upload-back-button' onClick={this.handleBack}>
            <ArrowBackIcon className='arrow-back-icon' />Back
          </a>
          <button
            onClick={() => {
              const updatedPhotos = photos.map(photo => {
                photo.tags = new Set(applyTags);
                return photo;
              });
              this.setState({ stage: UPLOAD_EDITOR_STAGE, completedStage: UPLOAD_EDITOR_STAGE, photos: updatedPhotos });
            }}
            className='upload-button-large'
          >
            Next
          </button>
        </div>
      </div>
    )
  }

  async uploadAll() {
    this.setState({ showUploadingModal: true });
    const photos = this.state.photos.filter((image) => !image.uploaded);
    const uploadBatch = await postUploadBatches(this.buildUploadBatchParams())
    const photosPromises = photos.map((image) => this.uploadPhoto(image, uploadBatch.batchId));

    Promise.all(photosPromises).
      then(() => this.props.getCurrentUser()).
      then(() => this.setState({ uploadComplete: true, shareLink: uploadBatch.shareLink, uploadBatchId: uploadBatch.batchId })).
      then(() => runFollowedSearchJob(uploadBatch.batchId));
  }

  buildUploadBatchParams() {
    const { photos, rawLocation, rawActivity } = this.state;
    const sortedPhotos = photos.sort((a, b)=> a.dateObject - b.dateObject)
    const firstPhoto = sortedPhotos[0];
    const lastPhoto = sortedPhotos[sortedPhotos.length - 1];

    return {
      capture_begin: firstPhoto.parsedTimestamp,
      capture_end: lastPhoto.parsedTimestamp,
      location_id: rawLocation.value,
      activity_id: rawActivity.value,
      num_photos: photos.length,
      batch_size: photos.reduce((sum, { size }) => sum + size, 0),
    };
  }

  uploadPhoto(photo, batchId) {
    const { rawLocation, rawActivity } = this.state;
    const formData = this.photoUploadDataFactory.buildFormData(
      photo, rawLocation.value, rawActivity.value, batchId);
    return uploadToAWS(photo, formData).
      then(() => {
        const newProgress = this.state.uploadProgress + 1;
        this.setState({ uploadProgress: newProgress })
      });
  }

  renderUploadingModal() {
    const {
      showUploadingModal,
      uploadProgress,
      uploadComplete,
      shareLink,
      uploadBatchId,
      photos,
    } = this.state;

    const numPhotos = photos.length;
    const uploadPercent = (uploadProgress / (numPhotos || 1)) * 100;

    return showUploadingModal ? (
      <div className='modal-dialog uploading-modal-dialog'>
        <div className='uploading-modal'>
          {uploadComplete ?
            <div className='upload-complete'>
              <h3>Success!</h3>
              <LargeSuccessIcon />
              <p>Your photos were successfully uploaded and posted to <strong>WunderPics!</strong></p>
              <label>Share</label>
              <div className='d-flex'>
                <div className='upload-share-link'>
                  {shareLink}
                </div>
                <button
                  className='btn btn-dark'
                  onClick={() => {
                    navigator.clipboard.writeText(shareLink);
                    this.props.alert.success('Share link successfully copied to clipboard!')
                  }}
                >
                  Copy
                </button>
              </div>
              <a href={shareLink}>
                <button className='btn blue-button'>
                  View photos on website
                </button>
              </a>
              <Link to={`/upload-manager?batchId=${uploadBatchId}`}>
                <button className='btn yellow-button'>
                  Go to Upload Manager
                </button>
              </Link>
            </div>
            :
            <div className='uploading-progress'>
              <h3>
                Please wait...<br />Your photos are being uploaded
              </h3>
              <UploadingIcon className='uploading-modal-icon' />
              <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={uploadPercent} aria-valuemin="0" aria-valuemax={numPhotos} style={{width: `${uploadPercent}%`}}></div>
              </div>
              <p>{uploadPercent.toFixed(0)}%</p>
            </div>
          }
        </div>
      </div>
    ) : null;
  }

  renderUploadEditorStage() {
    const {
      photos,
      rawLocation,
      newFirstPhoto,
      newLastPhoto,
      rawActivity,
      bulkPrice,
      applyTags,
    } = this.state;

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Publish</h3>
          <div className='row'>
            <div className='col-7 pre-upload-editor'>
              <h4>Are you ready to Publish your photos for sale?</h4>
              <div className='upload-left-field'>
                <label>Location</label>
                <p className='upload-editor-location'>{rawLocation.label}</p>
              </div>
              <div className='upload-left-field'>
                <label>Time Range</label>
                <p className='upload-editor-location'>
                  {newFirstPhoto.parsedDate} {newFirstPhoto.parsedTime} - {newLastPhoto.parsedDate} {newLastPhoto.parsedTime}
                </p>
              </div>
              <div className='upload-left-field'>
                <label>Activity</label>
                <p className='upload-editor-location'>{rawActivity.label}</p>
              </div>
              <div className='upload-left-field'>
                <label>Price per photo</label>
                <p className='upload-editor-location'>${bulkPrice === '' ? '0.00' : bulkPrice}</p>
              </div>
              <div className='upload-left-field'>
                {applyTags.length ?
                  <>
                    <label>Tags</label>
                    <p className='upload-editor-location'>
                      {applyTags.map(tag => `#${tag}`).join(', ')}
                    </p>
                  </>
                  :
                  null
                }
              </div>
              <hr />
              <div className='row choose-publish'>
                <div className='col'>
                  <p><strong>I&apos;m ready to publish my photos</strong></p>
                  <button
                    className='btn blue-button'
                    onClick={this.uploadAll}
                  >
                    Publish Photos
                  </button>
                </div>
                <div className='col'>
                <p>
                  I would like to customize the price and tags of
                  individual photos in the <strong>Upload Manager</strong>
                </p>
                <button
                  className='btn yellow-button'
                  onClick={this.updateStages(UPLOAD_MANAGER_STAGE, UPLOAD_MANAGER_STAGE)}
                >Continue Editing</button>
                </div>
              </div>
              {/* <div className='upload-left-field'>
                <button className='btn'>Publish For Sale</button>
              </div>
              <hr />
              <p>
                If you would to customize the price and Tags of
                individual photos, continue to the <strong>Upload Manager</strong>
              </p>
              <button
                onClick={this.updateStages(UPLOAD_MANAGER_STAGE, UPLOAD_MANAGER_STAGE)}
              >Continue to Upload Manager</button> */}
            </div>
            <div className='col-5 dropzone-image-col'>
              <PhotoGridPreview className='dropzone-photos-preview grid-4' photos={photos} />
            </div>
        </div>
        <div className='pre-upload-bottom'>
          <a className='upload-back-button' onClick={this.handleBack}><ArrowBackIcon className='arrow-back-icon' />Back</a>
        </div>
      </div>
    );
  }

  renderTimestampCheckStage() {
    const {
      firstPhoto,
      lastPhoto,
      timezone,
      photos
    } = this.state;

    const moreThanOnePhoto = (photos.length > 1);
    const question = moreThanOnePhoto ? 'these photos were' :
      'this photo was'

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Date &amp; Time</h3>
        <h4>We have determined that {question} taken at this time. Is this correct?</h4>
        <div className='row pre-upload-time-check no-gutters'>
          {moreThanOnePhoto ?
          <>
            <div className='col-4'>
              <UploadPhotoCard
                photo={firstPhoto}
                timezone={timezone}
                photoCardLabel='First photo taken:'
              />
            </div>
            <div className='photo-card-connect col-4'>
              <GroupImagesIcon />
              <p>
                <strong>
                  +{photos.length-2}<br/>
                  More Photos
                </strong>
              </p>
            </div>
            <div className='col-4'>
              <UploadPhotoCard
                photo={lastPhoto}
                timezone={timezone}
                photoCardLabel='Last photo taken:'
              />
            </div>
          </> :
          <UploadPhotoCard
            photo={firstPhoto}
            timezone={timezone}
            photoCardLabel='Photo taken at:'
          />}
        </div>
        <div className='pre-upload-bottom'>
          <a className='upload-back-button' onClick={this.handleBack}><ArrowBackIcon className='arrow-back-icon' />Back</a>
          <div className='pre-upload-multi-button'>
            <button
              onClick={() => this.setState({ displayTimestampEditor: true })}
              className='upload-button-large upload-gray'
            >
              No, Edit Date &amp; Time
            </button>
            <button
              onClick={this.updateStages(PRICE_STAGE, PRICE_STAGE)}
              className='upload-button-large'
            >
              Yes, Date &amp; Time are correct
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleSelectStagedFirstPhoto(jsDate) {
    this.setState({
      stagedFirstPhoto: DateTools.createParsedTimesAndObject(jsDate.toISOString()),
    });
  }

  updateDeltaAndTimes() {
    const {
      firstPhoto,
      newFirstPhoto,
      lastPhoto,
      stagedFirstPhoto,
    } = this.state;

    const delta = DateTools.calculateDeltaInMillis(
      firstPhoto.parsedTimestamp,
      stagedFirstPhoto.parsedTimestamp,
    );

    this.setState({
      displayDelta: true,
      newFirstPhoto: DateTools.addDeltaMillis(firstPhoto.parsedTimestamp, delta),
      delta,
    });

    if (lastPhoto) {
      this.setState({
        newLastPhoto: DateTools.addDeltaMillis(lastPhoto.parsedTimestamp, delta),
      });
    }
  }

  revertPhotoTimes() {
    const { firstPhoto, lastPhoto } = this.state;
    this.setState({
      newFirstPhoto: firstPhoto,
      stagedFirstPhoto: firstPhoto,
      newLastPhoto: lastPhoto,
      displayDelta: false,
    });
  }

  renderDeltaDisplay() {
    const { firstPhoto, newFirstPhoto } = this.state;
    const { shifts, plusOrMinus } = DateTools.getShifts(firstPhoto.parsedTimestamp, newFirstPhoto.parsedTimestamp);
    const plusOrMinusClass = classNames('plus-or-minus', {
      'red': (plusOrMinus === '- minus'),
      'green': (plusOrMinus === '+ plus'),
    });

    const nonZeroShifts = Object.entries(shifts.values).
      filter(([_, value]) => value > 0).
      map(([timeUnit, value]) => value > 0 ? `${value.toFixed(0)} ${timeUnit}` : null).join(', ');

    return (
      <div>
        <span className={plusOrMinusClass}>{plusOrMinus}</span>
        <span>
           {nonZeroShifts}
        </span>
      </div>
    )
  }

  /**
   * https://reactdatepicker.com/#example-custom-input
   */
  renderTimestampEditStage() {
    const {
      firstPhoto,
      lastPhoto,
      timezone,
      newFirstPhoto,
      stagedFirstPhoto,
      newLastPhoto,
      photos,
      displayDelta,
    } = this.state;

    const moreThanOnePhoto = (photos.length > 1);
    const prompt = moreThanOnePhoto ? 'Correct the capture time of your first photo to shift the capture time of all photos' : 'Correct the capture time of your photo';

    return (
      <div className='pre-upload-manager-inner'>
        <div className='pre-upload-top'>
          <a onClick={this.renderHelp} className='pre-upload-help'><HelpIcon />Help</a>
        </div>
        <h3>Correct Date &amp; Time</h3>
        <h4>{prompt}</h4>
        <div className='row time-edit'>
          <div className='col-6'>
            <div className='time-edit-first-photo'>
              <div>
                <label>First Photo</label>
                <div>
                  <img src={firstPhoto.browserPath} />
                  <MorePhotosLeft />
                </div>
              </div>
              <p>+{photos.length - 1}<br/>More Photos</p>
            </div>
            <div className='time-edit-table'>
              <table>
                <tbody>
                  <tr>
                    <td>Original Time:</td>
                    <td>
                      <DatePicker
                        readOnly
                        formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
                        showTimeInput
                        className='original-time-input'
                        calendarClassName='wup-datepicker'
                        dateFormat='MMMM d, yyyy h:mm aa'
                        selected={firstPhoto.dateObject}
                        timeCaption='Start Time'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Corrected Time:</td>
                    <td>
                      <DatePicker
                        showPopperArrow={false}
                        formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
                        showTimeInput
                        className='corrected-time-input'
                        calendarClassName='wup-datepicker'
                        dateFormat='MMMM d, yyyy h:mm aa'
                        selected={stagedFirstPhoto.dateObject}
                        onChange={this.handleSelectStagedFirstPhoto}
                        timeCaption='Start Time'
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='text-right'>
              <button
                onClick={this.revertPhotoTimes}
                className='upload-button-large upload-gray'
              >
                Revert to original time
              </button>
              <button
                onClick={this.updateDeltaAndTimes}
                className='upload-button-large'
                disabled={firstPhoto.dateObject === stagedFirstPhoto.dateObject}
              >
                Apply Correction
              </button>
            </div>
          </div>
          <div className='col-6'>
            {displayDelta &&
              <div className='pre-upload-time-preview'>
                <h5>New Capture Times</h5>
                <div className='new-range'>
                  <div className='range-date'>
                    {newFirstPhoto.parsedDate}<br />
                    {newFirstPhoto.parsedTime}<br />
                    {timezone.abbreviation}  {timezone.name}
                  </div>
                  {newLastPhoto.parsedDate &&
                    <>
                      <div className='new-range-divider'></div>
                      <div className='range-date'>
                        {newLastPhoto.parsedDate}<br />
                        {newLastPhoto.parsedTime}<br />
                        {timezone.abbreviation}  {timezone.name}
                      </div>
                    </>
                  }
                </div>
                <div className='delta-display'>
                  <p>Capture time of all photos shifted</p>
                  {this.renderDeltaDisplay()}
                </div>
              </div>}
          </div>
        </div>
        <div className='pre-upload-bottom'>
          <div className='pre-upload-multi-button'>
            <button
              onClick={() => this.setState({ displayTimestampEditor: false })}
              className='upload-button-large upload-gray'
            >
              Cancel
            </button>
            <button
              onClick={this.updateStages(PRICE_STAGE, PRICE_STAGE)}
              disabled={firstPhoto.dateObject === stagedFirstPhoto.dateObject}
              className='upload-button-large'
            >
              Confirm, set new time range
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderSidebar() {
    const { stage, completedStage } = this.state;
    const stage1Classes = classNames('upload-stage', { 'active-stage': stage === 1, 'stage-done': completedStage > 1 });
    const stage2Classes = classNames('upload-stage', { 'active-stage': stage === 2, 'stage-done': completedStage > 2 });
    const stage3Classes = classNames('upload-stage', { 'active-stage': stage === 3, 'stage-done': completedStage > 3 });
    const stage4Classes = classNames('upload-stage', { 'active-stage': stage === 4, 'stage-done': completedStage > 4 });
    const stage5Classes = classNames('upload-stage', { 'active-stage': stage === 5, 'stage-done': completedStage > 5 });
    const stage6Classes = classNames('upload-stage', { 'active-stage': stage === 6, 'stage-done': completedStage > 6});

    if (stage === 0) {
      return <InitialUploadSidebar />;
    } else {
      return (
        <>
          <div className='upload-sidebar-header'>
            Uploader
          </div>
          <div onClick={() => this.state.completedStage > 0 ? this.setState({ stage: 1 }) : null} className={stage1Classes}>
            <span className='outlined-number'>1</span>
            <LocationIcon className='location-icon' />
            Location
          </div>
          <div onClick={() => this.state.completedStage > 1 ? this.setState({ stage: 2 }) : null} className={stage2Classes}>
            <span className='outlined-number'>2</span>
            <SurfingIcon className='icon-gray-fill' />
            Activity
          </div>
          <div onClick={() => this.state.completedStage > 2 ? this.setState({ stage: 3 }) : null} className={stage3Classes}>
            <span className='outlined-number'>3</span>
            <CalendarIcon className='icon-gray-fill' />
            Date &amp; Time
          </div>
          <div onClick={() => this.state.completedStage > 3 ? this.setState({ stage: 4 }) : null} className={stage4Classes}>
            <span className='outlined-number'>4</span>
            <DollarSignIcon className='icon-gray-fill' />
            Price
          </div>
          <div onClick={() => this.state.completedStage > 4 ? this.setState({ stage: 5 }) : null} className={stage5Classes}>
            <span className='outlined-number'>5</span>
            <TagsIcon className='icon-gray-fill' />
            Tags
          </div>
          <div onClick={() => this.state.completedStage > 5 ? this.setState({ stage: 6 }) : null}  className={stage6Classes}>
            <span className='outlined-number'>6</span>
            <EditorIcon className='icon-gray-fill' />
            Upload Editor
          </div>
        </>
      );
    }
  }

  render() {
    const { showUploadingModal, stage } = this.state;
    const modalClasses = classNames('upload-modal', {
      'wup-modal': (showUploadingModal),
      'fade': (showUploadingModal),
      'show': (showUploadingModal),
    });

    return (
      <>
        <SEO title='Upload' />
        <div className='upload-page-container'>
          {
            (stage !== UPLOAD_MANAGER_STAGE) ?
              <div id='pre-upload'>
                <div className='upload-sidebar'>
                  {this.renderSidebar()}
                  <UserStorage storageUsedProp={this.state.storageUsed} />
                </div>
                <div className='pre-upload-main'>
                  <div id='pre-manager-upload'>{this.renderStage()}</div>
                </div>
              </div> :
              this.renderUploadPhotoManager()
          }
        </div>
        <div className={modalClasses}>
          {this.renderUploadingModal()}
        </div>
      </>
    );
  }
}

export default withRouter(withAlert()(Upload));
