const EmailConfirmationIcon = () => {
  return (
    <svg width="75" height="75" viewBox="0 0 72 72" className='email-confirmation-svg' fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.8961 44.8715V68.3941C27.8961 69.9973 31.7207 71.2969 36.4385 71.2969C41.1563 71.2969 44.9809 69.9973 44.9809 68.3941V44.8715H27.8961Z" fill="#D19B61"/>
      <path d="M70.3465 25.5736C70.3465 16.9343 63.3429 9.93079 54.7036 9.93079H17.4425L33.0853 54.0637H69.334C69.6025 54.0637 69.86 53.957 70.0499 53.7671C70.2398 53.5773 70.3465 53.3197 70.3465 53.0512V26.5861C70.3465 26.5162 70.3394 26.448 70.3259 26.3821C70.3394 26.1143 70.3465 25.8448 70.3465 25.5736Z" fill="#81929B"/>
      <path d="M17.4426 9.93079C8.80335 9.93079 1.7998 16.9343 1.7998 25.5736V53.0512C1.7998 53.3197 1.90648 53.5773 2.09636 53.7671C2.28624 53.957 2.54377 54.0637 2.8123 54.0637H32.073C32.3415 54.0637 32.5991 53.957 32.7889 53.7671C32.9788 53.5773 33.0855 53.3197 33.0855 53.0512V25.5736C33.0855 16.9343 26.0819 9.93079 17.4426 9.93079Z" fill="#2B3B47"/>
      <path d="M54.9821 0.776917H46.8127C44.179 0.776917 41.9666 2.58099 41.3437 5.0207C41.1383 5.45897 41.032 5.93715 41.0325 6.42118V29.321C41.0325 31.1469 42.5126 32.6269 44.3384 32.6269C46.1643 32.6269 47.6444 31.1469 47.6444 29.321V12.0656H54.9821C58.0993 12.0656 60.6265 9.53856 60.6265 6.42132C60.6265 3.30409 58.0993 0.776917 54.9821 0.776917Z" fill="#FF473E"/>
      <path d="M33.0853 25.5736C33.0853 24.0141 32.8559 22.5083 32.4311 21.087L10.7308 21.0877C9.84233 21.182 9.03852 21.554 8.4119 22.1242L8.66727 22.3234C8.11813 22.6888 7.63677 23.1454 7.31165 23.7261L26.045 38.4401C28.4093 40.185 29.653 40.2124 31.9912 38.4401L33.0853 37.5807V25.5736Z" fill="#EDECE6"/>
      <path d="M33.0856 25.5736C33.0856 23.9978 32.8516 22.4769 32.4182 21.0424H11.17C8.89766 21.0424 7.05603 22.8842 7.05603 25.1564V48.4692L28.9975 32.0131L33.0856 35.0792V25.5736Z" fill="#B9C5C6"/>
      <path d="M33.0853 34.5039C32.3958 33.9816 31.9904 33.6753 31.9904 33.6753C29.5889 31.9032 28.3258 31.8753 25.9511 33.6753C25.9511 33.6753 6.79713 48.1475 6.95828 48.5927L6.95435 48.5959C6.95631 48.5999 6.95955 48.6024 6.96208 48.606C7.1245 49.0488 7.36077 49.4608 7.66085 49.8246C7.78755 49.9783 7.94041 50.1071 8.08736 50.2409L8.08019 50.2464C8.09186 50.2568 8.10747 50.2618 8.11914 50.2722C8.74197 50.8276 9.52722 51.1875 10.3997 51.2902C10.4133 51.2918 10.4244 51.2989 10.438 51.3006H10.4906C10.6233 51.3132 10.7494 51.3451 10.8852 51.3451H33.085V34.5039H33.0853Z" fill="#96A9B2"/>
      <path d="M33.0853 25.5736C33.0863 24.0464 32.8636 22.5273 32.4245 21.0645H11.1523C9.39657 21.0645 7.90904 22.1686 7.31827 23.7163C7.31124 23.7278 7.30041 23.7361 7.29395 23.7479L7.30548 23.7569C7.14095 24.2014 26.0273 38.4621 26.0273 38.4621C28.3916 40.2069 29.6353 40.2344 31.9735 38.4621C31.9735 38.4621 32.3862 38.1503 33.0853 37.6203V25.5736Z" fill="#DCE2E2"/>
    </svg>
  )
};

export default EmailConfirmationIcon;