import { useEffect } from 'react';

type KeyboardEventType = 'keydown' | 'keyup';
type KeyboardEventHandlerFn = (event: KeyboardEvent) => void;
type KeyboardEventHandlerFnMap = Record<
  KeyboardEventType,
  KeyboardEventHandlerFn
>;

export default function useKeyboardEvents(
  handlerMap: Partial<KeyboardEventHandlerFnMap>
): void {
  useEffect(() => {
    let handler: KeyboardEventType;

    for (handler in handlerMap) {
      window.addEventListener(handler, (e) => handlerMap[handler]?.(e));
    }

    return () => {
      for (handler in handlerMap) {
        window.removeEventListener(handler, (e) => handlerMap[handler]?.(e));
      }
    };
  }, [handlerMap]);
}
