import { AnyAction } from 'redux';
import {
  LOGOUT_CURRENT_USER,
  RECEIVE_CURRENT_USER,
  RECEIVE_FOLLOWED_SEARCH,
  REMOVE_FOLLOWED_SEARCH,
  UPDATE_FOLLOWED_SEARCH,
} from '../actions/session_actions';
import { CurrentUser } from '../types';

const nullUser: CurrentUser = {
  id: null,
  firstName: '',
  lastName: '',
  username: '',
  storageUsed: 0,
  storageUsedHuman: '',
  storageLimit: 0,
  storageLimitHuman: '',
  accountType: '',
  stripeDetailsSubmitted: false,
  followedSearches: [],
};

export default function sessionReducer(
  state = nullUser,
  action: AnyAction
): CurrentUser {
  Object.freeze(state);

  switch (action.type) {
    case LOGOUT_CURRENT_USER: {
      return nullUser;
    }
    case RECEIVE_CURRENT_USER: {
      return action.currentUser;
    }
    case RECEIVE_FOLLOWED_SEARCH: {
      return Object.assign({}, state, {
        followedSearches: [...state.followedSearches, action.followedSearch]
      });
    }
    case REMOVE_FOLLOWED_SEARCH: {
      return Object.assign({}, state, {
        followedSearches: state.followedSearches.filter((fs) => fs.id !== action.followedSearchId)
      });
    }
    case UPDATE_FOLLOWED_SEARCH: {
      const filtered = state.followedSearches.filter((fs) => fs.id !== action.followedSearch.id)
      return Object.assign({}, state, {
        followedSearches: [...filtered, action.followedSearch]
      });
    }
    default: {
      return state;
    }
  }
}
