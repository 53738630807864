import { DateTime } from 'luxon';
import qs from 'qs';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DateTools from '../../util/date_tools';
import GenericLoading from '../generic/generic_loading';
import SEO from '../generic/seo';
import NoResults from './no_results';
import Temporal from './temporal';

class Stream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  /**
   * Doubles the history for FollowedSearch links
   */
  componentDidUpdate(prevProps) {
    if (this.props.searchString &&
       (this.props.searchString !== prevProps.searchString)) {
      this.props.history.push('/search?' + this.props.searchString);
    }
  }

  componentDidMount() {
    const {
      setSearchLocation,
      receiveSearchDate,
      setSearchActivity,
      receiveSearchTime,
      receiveSearchTimeEnd,
      setSearchTags,
      setSearchPhotographer,
      searchLocation,
    } = this.props;

    if (searchLocation.value) {
      this.props.searchPhotos()
        .then(() => this.setState({ loading: false }));
    } else if (this.props.location.search) {
      const queryParams = qs.parse(this.props.location.search.substring(1));

      if (queryParams.date) {
        const luxonDate = DateTime.fromISO(queryParams.date);
        const displayDate = luxonDate.toFormat('MMMM d, yyyy');
        const date = luxonDate.toJSDate();
        receiveSearchDate({ label: queryParams.date, display: displayDate,  value: date });
      }

      if (queryParams.activityId) {
        setSearchActivity(queryParams.activityId);
      }

      if (queryParams.tags) {
        setSearchTags(queryParams.tags);
      }

      if (queryParams.time) {
        const luxonTime = DateTime.fromFormat(`${queryParams.date} ${queryParams.time}`, 'yyyy-MM-dd hh:mma');

        if (!luxonTime.invalid) {
          const label = DateTools.formatLabelTime(luxonTime.toISO());
          receiveSearchTime({
            label: label,
            value: luxonTime.toJSDate(),
          })
        }
      }

      if (queryParams.timeEnd) {
        const luxonTimeEnd = DateTime.fromFormat(`${queryParams.date} ${queryParams.timeEnd}`, 'yyyy-MM-dd hh:mma');

        if (!luxonTimeEnd.invalid) {
          const label = DateTools.formatLabelTime(luxonTimeEnd.toISO());
          receiveSearchTimeEnd({
            label: label,
            value: luxonTimeEnd.toJSDate(),
          })
        }
      }

      if (queryParams.photographerId) {
        setSearchPhotographer(queryParams.photographerId);
      }

      if (queryParams.locationId) {
        setSearchLocation(queryParams.locationId).
          then(() =>
          this.props.searchPhotos()
            .then(() => this.setState({ loading: false }))
        );
      }
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      searched,
      photos,
      times,
      cart,
      focusedPhoto,
      searchLocation,
      receiveFocusedPhoto,
    } = this.props;

    const { loading } = this.state;
    let comp;

    if (loading) {
      comp = <div className='stream'><GenericLoading /></div>;
    } else {
      if (_.isEmpty(photos)) {
        comp =  <NoResults />;
      } else {
        comp = <div className='stream'>
            <Temporal
              photos={photos}
              times={times}
              searched={searched}
              cart={cart}
              focusedPhoto={focusedPhoto}
              receiveFocusedPhoto={receiveFocusedPhoto}
              numPhotos={photos.length}
            />
          </div>;
      }
    }

    const title = searchLocation.value ? `${searchLocation.label}` : 'Search';

    return (
      <>
        <SEO title={`${title} Photos`} />
        {comp}
      </>
    )
  }
}

export default withRouter(Stream);
