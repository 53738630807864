import React from 'react';

const contentData = {
    rows: [
        {
            title: "Content",
            content: <div>You must be the owner of any content you upload to the Site. We do not claim ownership or copyright of your content. You hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content including the creation of thumbnails and watermarked content. You can end this license anytime by deleting your content or account. However, content will continue to appear if you have shared it with others and they have not deleted it.<br /><br />
            WunderPics Inc. is not liable for the legal repercussions of the content that users upload. As a user of the Site, you are responsible for any content that you upload and agree to not upload/publish any of the following:<br /><br />
            <ol>
                <li>Content that infringes on any copyrights or intellectual property rights including any content that does not belong to you.</li>
                <li>Content that is is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy or publicity, hateful racially or ethnically, or otherwise objectionable as determined by Wunderpics Inc. in its sole discretion;</li>
                <li>Content that contains any inaccurate information or data which restricts or inhibits any anyone from using or enjoying the Services as determined by Wunderpics Inc. in its sole discretion.</li>
                <li>Content which may expose Wunderpics Inc. or any other party to any harm or liability of any type.</li>
                <li>Content that impersonates any person or entity.</li>
                <li>Files containing viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software, website, or hardware.</li>
                <li>Content that is of low quality and does not reflect the standard of professional quality that Wunderpic Inc. has set.</li>
            </ol><br />
            Violation of these terms can result in the removal of content and termination of accounts at the sole discretion of Wunderpics Inc.
            </div>
        }
    ]
}

export default contentData;