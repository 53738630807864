import { RECEIVE_SEARCH_RESULT_PHOTOS } from '../actions/search_actions';
import { RECEIVE_FOCUSED_PHOTO } from '../actions/ui_actions';

const initialState = null;

const focusedPhotoReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_FOCUSED_PHOTO: {
      // The same photo was clicked, so unset it
      if (state === action.payload) {
        return -1;
      }
      return action.payload;
    }
    case RECEIVE_SEARCH_RESULT_PHOTOS: {
      return -1;
    }
    default:
      return state;
  }
}

export default focusedPhotoReducer;
