import { ReactElement, SVGProps } from 'react';

export default function UploadIcon({
  className = "upload-icon",
  height = '31',
  width = '31',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2843 20.3081V26.3713C29.2843 27.1753 28.9649 27.9464 28.3964 28.5149C27.8278 29.0835 27.0567 29.4029 26.2527 29.4029H5.03159C4.22756 29.4029 3.45646 29.0835 2.88793 28.5149C2.3194 27.9464 2 27.1753 2 26.3713V20.3081"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2211 9.69762L15.6421 2.11865L8.06314 9.69762"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6421 2.11865V20.3082"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
