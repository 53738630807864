import WunderPicsLogo from '../icons/wunder_pics_logo';
import AbsCenterContainer from '../library/abs_center_container';
import LoadingDots from './loading_dots';

export default function WpLoading() {

  return (
    <AbsCenterContainer>
      <WunderPicsLogo />
      <LoadingDots />
    </AbsCenterContainer>
  );
}
