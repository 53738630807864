export const asArray = <T>(obj: Record<string, T>): T[] => {
  if (!(obj instanceof Object)) {
    throw new Error('obj is not an Object!');
  }
  return Object.keys(obj).map((key) => obj[key]);
};

export const asArrayNameSort = <T, K extends keyof T>(
  obj: Record<K, T>,
  nameKey: K = 'name' as K
): T[] => {
  return asArray(obj).sort(propCompare<T, K>(nameKey));
};

export const selectOptionFormat = <
  T extends { id: string | number },
  K extends keyof T
>(
  obj: Record<K, T>,
  nameKey: K = 'name' as K
): { value: string | number; label: T[K] }[] => {
  return asArrayNameSort<T, K>(obj, nameKey).map((o) => ({
    value: o.id,
    label: o[nameKey],
  }));
};

const propCompare = <T, K extends keyof T>(prop: K) => {
  return (a: T, b: T) => {
    const aName = JSON.stringify(a[prop]).toUpperCase();
    const bName = JSON.stringify(b[prop]).toUpperCase();

    if (aName < bName) return -1;
    else if (aName > bName) return 1;
    else return 0;
  };
};
