import React from 'react';

const unlawfulData = {
    rows: [
        {
            title: "No Unlawful or Prohibited Use/Intellectual Property",
            content: "You are granted a non-exclusive, non-transferable, revocable license to access and use WunderPics.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to WunderPics, Inc. that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site."
            +
            "<br/>"
            +
            "<br/>"
            +
            "All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of WunderPics, Inc. or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto."
            +
            "<br/>"
            +
            "<br/>"
            +
            "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. WunderPics, Inc. content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of WunderPics, Inc. and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of WunderPics, Inc. or our licensors except as expressly authorized by these Terms."
        }
    ]
}

export default unlawfulData;