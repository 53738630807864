import React from 'react';
import BlankUserImage from 'images/blank-user.png';
import UserProfilePhotoItem from './user_profile_photo_item';

class UserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.username = this.props.match.params.username
    this.state = {
      photos: [],
      userFound: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchUser();
    }
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    $.ajax({
      method: 'GET',
      url: `/api/users/${this.username}`
    }).
    then((userProfile) => this.setState({
      photos: userProfile.photos,
      userFound: true,
      description: userProfile.user.description,
      website: userProfile.user.website,
      instagram: userProfile.user.instagram,
      facebook: userProfile.user.facebook,
      profilePhotoUrl: userProfile.user.profilePhotoUrl,
    }));
  }

  render() {
    const {
      description,
      website,
      instagram,
      facebook,
      profilePhotoUrl,
      userFound,
    } = this.state

    return (
      userFound ?
        <div className='page-container'>
          <div className='user-banner'>
            <h2>{this.username}</h2>
            <div className='user-description d-flex'>
              <div className='user-avatar'>
                <img src={profilePhotoUrl ? profilePhotoUrl : BlankUserImage} />
              </div>
              <div className='user-description-info'>
                <p className='user-description-text'>
                  <strong>Bio:</strong>
                  <br/>
                  {description}
                </p>
                <p>
                  <a href='#'>Personal: {website}</a>
                  <br />
                  <a href="#">Facebook: {instagram}</a>
                  <br />
                  <a href="#">Instagram: {facebook}</a>
                </p>
              </div>
            </div>
          </div>
          <div className='user-profile-stream-container'>
            <h3>Recent Photos</h3>
            <div className='user-profile-stream'>
              {this.state.photos.map((photo, i) =>
                <UserProfilePhotoItem key={i} photo={photo} />
              )}
            </div>
          </div>
        </div> :
        <div className='user-profile'>
          <h2>User not found.</h2>
        </div>
    )
  }
}

export default UserContainer;
