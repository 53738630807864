import {
  ComponentProps,
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { ConditionalContainer, SemanticContainer } from '.';

const PanelContext = createContext<PanelProps & { semantic: boolean }>({
  className: '',
  semantic: false,
});

type PanelProps = {
  className?: string;
};
type NonSemanticPanelProps = PanelProps & { semantic?: false };
type SemanticPanelProps = PanelProps & {
  semantic: true;
  element?: SemanticContainer;
};

export default function Panel(
  props: PropsWithChildren<SemanticPanelProps>
): ReactElement;
export default function Panel(
  props: PropsWithChildren<NonSemanticPanelProps>
): ReactElement;
export default function Panel(
  props: PropsWithChildren<PanelProps> & {
    semantic?: boolean;
    element?: SemanticContainer;
  }
): ReactElement {
  const {
    semantic = false,
    element = 'section',
    className = 'panel',
    children,
    ...rest
  } = props;
  return (
    <PanelContext.Provider value={{ className, semantic }}>
      <ConditionalContainer
        condition={semantic}
        element={element}
        className={className}
        {...rest}
      >
        {children}
      </ConditionalContainer>
    </PanelContext.Provider>
  );
}

interface PanelElementProps {
  className?: string;
}

function PanelBody({
  className,
  children,
  ...props
}: PropsWithChildren<ComponentProps<'div'> & PanelElementProps>): ReactElement {
  const { className: contextClassName } = usePanelContext();

  return (
    <div
      className={className ? className : `${contextClassName}__body`}
      {...props}
    >
      {children}
    </div>
  );
}

function PanelFooter({
  className,
  children,
  ...props
}: PropsWithChildren<PanelElementProps>): ReactElement {
  const { className: contextClassName, semantic } = usePanelContext();

  return (
    <ConditionalContainer
      condition={semantic}
      element="footer"
      className={className ? className : `${contextClassName}__footer`}
      {...props}
    >
      {children}
    </ConditionalContainer>
  );
}

function PanelHeader({
  className,
  children,
  ...props
}: PropsWithChildren<PanelElementProps>): ReactElement {
  const { className: contextClassName, semantic } = usePanelContext();

  return (
    <ConditionalContainer
      condition={semantic}
      element="header"
      className={className ? className : `${contextClassName}__header`}
      {...props}
    >
      {children}
    </ConditionalContainer>
  );
}

function usePanelContext() {
  const cardContext = useContext(PanelContext);

  if (cardContext === undefined) {
    throw new Error('Consume PanelContext within a PanelContext.Provider!');
  }

  return cardContext;
}

Panel.Body = PanelBody;
Panel.Footer = PanelFooter;
Panel.Header = PanelHeader;
