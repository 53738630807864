import React from 'react';
import classNames from 'classnames';

class SidebarAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      overflowVis: false,
    };

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.overflowStyle = this.overflowStyle.bind(this);
  }

  handleHeaderClick(e) {
    this.setState({ isOpen: !this.state.isOpen }, this.overflowStyle)
  }

  overflowStyle() {
    if (this.state.isOpen) {
      setTimeout(() => {
        this.setState({ overflowVis: true });
      }, 400); // this should to match up with max-height CSS transition in upload.scss
    } else {
      this.setState({ overflowVis: false });
    }
  }

  render() {
    const { isOpen, overflowVis } = this.state;
    const { title, iconSrc, content } = this.props;

    const accClasses = classNames('sidebar-accordion', { 'sidebar-open': isOpen });
    const accContentClasses = classNames('sidebar-accordion-content', { 'overflow-vis': overflowVis });

    return (
      <div className={accClasses}>
        <div onClick={this.handleHeaderClick} className='sidebar-accordion-header'>
          <span className='icon-outer'><img src={iconSrc} /></span>
          {title}
        </div>
        <div className={accContentClasses}>
          <div className='sidebar-accordion-inner-content'>
            {isOpen && content}
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarAccordion;
