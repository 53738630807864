import { combineReducers } from 'redux';
import cartReducer from './cart_reducer';
import entitiesReducer from './entities_reducer';
import errorsReducer from './errors_reducer';
import sessionReducer from './session_reducer';
import uiReducer from './ui_reducer';

const rootReducer = combineReducers({
  entities: entitiesReducer,
  session: sessionReducer,
  errors: errorsReducer,
  ui: uiReducer,
  cart: cartReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
