import {
  ComponentProps,
  ComponentPropsWithRef,
  forwardRef,
  ReactElement
} from 'react';

export default forwardRef<HTMLButtonElement, ComponentProps<'button'>>(
  function Button(
    { type, children, ...props }: ComponentPropsWithRef<'button'>,
    ref
  ): ReactElement {
    return (
      <button ref={ref} type={type ? type : 'button'} {...props}>
        {children}
      </button>
    );
  }
);
