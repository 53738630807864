import { connect } from 'react-redux';
import { receiveSearchString, searchPhotos, setSearchLocation } from '../../actions/search_actions';
import { receiveBannerHeight } from '../../actions/ui_actions';
import { selectOptionFormat } from '../../reducers/selectors';
import SearchBarLocation from './search_bar_location';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  locations: selectOptionFormat(state.entities.locations.collectionWithPhotos),
  searchLocation: state.ui.searchParams.location,
  newSearchParams: state.ui.searchParams.newSearchParams,
  searchString: state.ui.searchParams.searchString,
});

const mapDispatchToProps = (dispatch) => ({
  receiveSearchString: () => dispatch(receiveSearchString()),
  receiveBannerHeight: (height) => dispatch(receiveBannerHeight(height)),
  searchPhotos: () => dispatch(searchPhotos()),
  setSearchLocation: locationId => dispatch(setSearchLocation(locationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBarLocation);
