import { useCallback, useEffect, useRef } from 'react';

export default function usePortal(portalName = 'portal'): HTMLDivElement {
  const portalRef = useRef<HTMLDivElement | null>(null);

  const getRoot = useCallback(() => {
    if (!portalRef.current) {
      portalRef.current = document.createElement('div');
      portalRef.current.setAttribute('id', `${portalName}__root`);
    }

    return portalRef.current;
  }, [portalName]);

  useEffect(() => {
    const portalRoot = getRoot();

    document.body.insertBefore(portalRoot, document.body.lastElementChild);

    return () => {
      if (!portalRoot.childElementCount) {
        portalRoot.remove();
      }
    };
  }, [getRoot]);

  return getRoot();
}
