import React from 'react';

const SellerGuideData = {
  title: "Seller Guide",
  rows: [
    {
      title: "Connect a bank account",
      content: "To start getting paid for your photos. Link your bank account to securely receive payments."
    },
    {
      title: "Photo requirements",
      content:
        <div>
          In order to maintain the quality of images on WunderPics, photos must meet the following Minimum Photo Requirements:<br /><br />• File type: JPEG<br />• Minimum resolution: 1200 horizontal pixels<br />• Maximum file size: 50mb<br />• Compliant with Terms & Conditions"
        </div>
    },
    {
      title: "Upload photos for sale",
      content: <div>In order to upload photos for sale, click the upload button in the top right corner. You can then click and drag photos directly into the browser or access your files through your Finder/Windows Explorer.<br /><br />Each batch of photos that you upload needs to have been taken at the same location and be of the same activity<br /><br />After importing your images, you will be asked to do the following before reaching the Upload Manager:<br />• Select the Location at which the photos were taken.<br />• Confirm the date and time in which the photos were taken is correct. If not, select the correct date and time<br />• Categorize the activity that is occuring in the photos.<br /><br />After tagging the photos with the necessary information you will then be taken to the Uploade Manager where you will have the option to further adjust the price and keywords of individual photos.<br /><br />Once you are ready to publish your photos for sale, click the Publish button and your images will then become publicly searchable and available for sale. You can always go back and edit your uploads, by accessing the Upload Manager tab in your dashboard.
      </div>
    },
    {
      title: "Select the location of photos.",
      content: <div>Tagging photos with the accurate location is essential to making them searchable. Search the name of the location in which your photos were taken and select it.<br /><br />If you don’t see the location, you can select  “+ add location” to add a location to the map. You will be able to select a location on the map and name it. Please be as accurate as possible and double check for typos before submitting.
      </div>
    },
    {
      title: "Set the time and date of photos",
      content: <div>WunderPics reads the EXIF data of each photo you upload to find out the time at which it was taken. However, sometimes this EXIF data is incorrect or does not exist. In this case you will need to manually set the date and time at which your photos were taken.<br /><br />
        Upon upload, the date and time of the first photo in the batch will be displayed
        (insert screen shot)<br /><br />
        Select “Yes” if the date and time displayed is accurate.<br /><br />
        Select “No” if the date and time displayed is incorrect.<br /><br />
        If you select “No,” you will be given the option to select the correct date on a calendar, change the time, and set the time zone of the first photo.<br /><br />
        By selecting a new date and time for the first photo, all photos will automatically shift based on this new date and time.
      </div>
    },
    {
      title: "Set the activity of photos",
      content: "Choose the activity that is occurring in all of the photos that you are uploading. If you do not see the activity that you shot you can select  “+ activity” to add a new activity to the WunderPics directory."
    },
    {
      title: "Upload manager",
      content: "After setting the Location, Date & Time, and Activity, you will be taken to the Upload Manager where you can set the price and tag keywords for individual photos. You can also continue to edit the Location, Date & Time, and Activity."
    },
    {
      title: "Set the price of photos",
      content: "While uploading your images you can select the price at which you want to sell each of your photos. If you would like all of your photos to be the same price click “Select All” and apply your desired price. If you would like to change the price of individual photos select that particular photo, type in the price, and select “Apply.”"
    },
    {
      title: "Tag photos with keywords",
      content: "While uploading your images you can tag each of your photos with specific keywords. If you would like all of your photos to have the same keyword applied, click “Select All” and apply your desired keywords. If you would like to apply keywords to individual photos select that particular photo, type in the keyword, and select “Apply.”"
    }
  ]
}
const SellerGuideStyles = {
  titleTextColor: "black",
  titleTextSize: '25px',
  rowTitleColor: 'black',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '15px',
  rowContentPaddingBottom: '15px',
  rowContentColor: 'grey',
}

export { SellerGuideData, SellerGuideStyles };