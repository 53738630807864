import Faq from 'react-faq-component';
import Footer from '../footer';
import SEO from '../generic/seo';
import ScrollToTop from '../scroll_to_top';
import acceptanceData from './acceptance_data';
import arbitrationData from './arbitration_data';
import changesData from './changes_data';
import classActionData from './class_action_data';
import communicationUseData from './communication_use_data';
import contactUsData from './contact_us_data';
import contentData from './content_data';
import dataStorageData from './data_storage_data';
import eCommunicationsData from './electronic_communications_data';
import employmentData from './employment_data';
import generalData from './general_data';
import indemnificationData from './indemnification_data';
import internationalData from './international_data';
import liabilityData from './liability_data';
import materialsData from './materials_data';
import personalUseData from './personal_use_data';
import purchaseData from './purchase_data';
import refundData from './refund_data';
import saleData from './sale_data';
import subscriptionsData from './subscriptions_data';
import terminationData from './termination_data';
import TermsBanner from './terms_banner';
import thirdPartyAccountsData from './third_party_accounts_data';
import thirdPartyServicesData from './third_party_services_data';
import underThirteenData from './under_thirteen_data';
import unlawfulData from './unlawful_data';
import yourAccountData from './your_account_data';

const termsStyles = {
  titleTextColor: "black",
  titleTextSize: '25px',
  rowTitleColor: 'black',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '15px',
  rowContentPaddingBottom: '15px',
  rowContentColor: 'grey',
}

const Terms = (props) => {
  return (
    <>
      <ScrollToTop />
      <SEO title='Terms and Conditions' />
      <div id='content'>
        <TermsBanner text='Terms &amp; Conditions' />
        <div className='page-container'>
          <div className='row no-gutters'>
            <div className='col-12'>
              <dl>
                <div>
                  <br />
                  <div id='acceptance'>
                    <Faq data={acceptanceData} styles={termsStyles} />
                  </div>
                  <div id='general'>
                    <Faq data={generalData} styles={termsStyles} />
                  </div>
                  <div id='yourAccount'>
                    <Faq data={yourAccountData} styles={termsStyles} />
                  </div>
                  <div id='underThirteen'>
                    <Faq data={underThirteenData} styles={termsStyles} />
                  </div>
                  <div id='contentTerms'>
                    <Faq data={contentData} styles={termsStyles} />
                  </div>
                  <div id='personalUse'>
                    <Faq data={personalUseData} styles={termsStyles} />
                  </div>
                  <div id='saleTerms'>
                    <Faq data={saleData} styles={termsStyles} />
                  </div>
                  <div id='purchaseTerms'>
                    <Faq data={purchaseData} styles={termsStyles} />
                  </div>
                  <div id='refund'>
                    <Faq data={refundData} styles={termsStyles} />
                  </div>
                  <div id='subscriptions'>
                    <Faq data={subscriptionsData} styles={termsStyles} />
                  </div>
                  <div id='employment'>
                    <Faq data={employmentData} styles={termsStyles} />
                  </div>
                  <div id='dataStorage'>
                    <Faq data={dataStorageData} styles={termsStyles} />
                  </div>
                  <div id='eCommunications'>
                    <Faq data={eCommunicationsData} styles={termsStyles} />
                  </div>
                  <div id='thirdPartyServices'>
                    <Faq data={thirdPartyServicesData} styles={termsStyles} />
                  </div>
                  <div id='unlawful'>
                    <Faq data={unlawfulData} styles={termsStyles} />
                  </div>
                  <div id='communicationUse'>
                    <Faq data={communicationUseData} styles={termsStyles} />
                  </div>
                  <div id='materials'>
                    <Faq data={materialsData} styles={termsStyles} />
                  </div>
                  <div id='thirdPartyAccounts'>
                    <Faq data={thirdPartyAccountsData} styles={termsStyles} />
                  </div>
                  <div id='internationalData'>
                    <Faq data={internationalData} styles={termsStyles} />
                  </div>
                  <div id='indemnification'>
                    <Faq data={indemnificationData} styles={termsStyles} />
                  </div>
                  <div id='arbitration'>
                    <Faq data={arbitrationData} styles={termsStyles} />
                  </div>
                  <div id='classAction'>
                    <Faq data={classActionData} styles={termsStyles} />
                  </div>
                  <div id='liability'>
                    <Faq data={liabilityData} styles={termsStyles} />
                  </div>
                  <div id='termination'>
                    <Faq data={terminationData} styles={termsStyles} />
                  </div>
                  <div id='changes'>
                    <Faq data={changesData} styles={termsStyles} />
                  </div>
                  <div id='contactUs'>
                    <Faq data={contactUsData} styles={termsStyles} />
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Terms;
