export const fetchPhotographers = () => (
  $.ajax({
    method: 'GET',
    url: '/api/users'
  })
);

export const updateUser = (user) => {
  return $.ajax({
    method: 'POST',
    url: `/api/users/update`,
    data: user,
    contentType: false,
    processData: false,
  })
}

export const getEditUser = () => {
  return $.ajax({
    method: 'GET',
    url: `/api/users/edit`,
  });
}

export const updateUserPassword = (user) => {
  return $.ajax({
    method: 'PUT',
    url: '/users',
    data: user,
    processData: false,
    contentType: false,
  })
}
