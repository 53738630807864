import qs from 'qs';
import { RetrieveStripeOrder, Order } from "../../types";

export const updateOrder = (orderId: number): JQuery.jqXHR<Order> => (
  $.ajax({
    method: 'PUT',
    url: `/api/orders/${orderId}`,
  })
);

export const getUserOrders = (): JQuery.jqXHR<Order[]> => (
  $.ajax({
    method: 'GET',
    url: `/api/users/orders`,
  })
);

type StripeSessionId = string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined;

export const retrieveStripeOrder = (stripeSessionId: StripeSessionId): JQuery.jqXHR<RetrieveStripeOrder> => (
  $.ajax({
    method: 'GET',
    url: `/api/orders/retrieve_stripe_order`,
    data: {
      order: {
        stripe_session_id: stripeSessionId,
      }
    }
  })
);
