import React from 'react';

const WunderPicsLogo = (props) => (
  <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.9209 14.1749C5.9209 12.6144 7.14723 11.3486 8.66101 11.3486C10.1733 11.3486 11.4001 12.6144 11.4001 14.1749C11.4001 15.0143 8.66101 19.3522 8.66101 19.3522C8.66101 19.3522 5.9209 14.973 5.9209 14.1749Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.8311 14.1739C12.8311 12.6134 14.0574 11.3477 15.5712 11.3477C17.0834 11.3477 18.3103 12.6134 18.3103 14.1739C18.3103 15.0134 15.5712 19.3512 15.5712 19.3512C15.5712 19.3512 12.8311 14.972 12.8311 14.1739Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7666 19.5634C21.7666 21.1239 20.5403 22.3896 19.0265 22.3896C17.5142 22.3896 16.2874 21.1239 16.2874 19.5634C16.2874 18.7239 19.0265 14.3861 19.0265 14.3861C19.0265 14.3861 21.7666 18.7653 21.7666 19.5634Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8564 19.5634C14.8564 21.1239 13.6301 22.3896 12.1163 22.3896C10.6041 22.3896 9.37722 21.1239 9.37722 19.5634C9.37722 18.7239 12.1163 14.3861 12.1163 14.3861C12.1163 14.3861 14.8564 18.7653 14.8564 19.5634Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.7432 14.1739C19.7432 12.6134 20.9695 11.3477 22.4833 11.3477C23.9956 11.3477 25.2224 12.6134 25.2224 14.1739C25.2224 15.0134 22.4833 19.3512 22.4833 19.3512C22.4833 19.3512 19.7432 14.972 19.7432 14.1739Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 29.452C23.4293 29.452 29.452 23.4293 29.452 16C29.452 8.57067 23.4293 2.54801 16 2.54801C8.57067 2.54801 2.54801 8.57067 2.54801 16C2.54801 23.4293 8.57067 29.452 16 29.452ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"/>
  </svg>
);

export default WunderPicsLogo;
