import React from 'react';
import termsBanner from 'images/terms-banner.jpg';

const TermsBanner = ({ text }) => {
    return (
        <div id="bannerContainer">
            <h1 id="bannerText">{text}</h1>
            <img id="termsBanner" src={termsBanner} alt="Terms Banner" />
        </div>
    );
}

export default TermsBanner;
