import React from 'react';
import ReactDropzone from 'react-dropzone';
import UploadDropImage from 'images/upload-drop-image.png';

const UploadDropzone = ({ handleDrop }) => {
  return (
    <ReactDropzone onDrop={handleDrop}>
      {({getRootProps, getInputProps}) => (
        <div className='dropzone-container dropzone-empty'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='dropzone-upload'>
              <img src={UploadDropImage} />
              <p className='dropzone-subtitle'>
                Drag photos to upload
                <br />
                or
                <br />
                <button className='dropzone-button'>Browse</button>
              </p>
              <div className='dropzone-requirements'>
                <strong>Photo Requirements</strong><br/>
                Filetype: JPEG<br />
                Minimum Resolution: 1200px (Long Edge)<br/>
                Maximum File Size: 30MB per photo<br/>
                Compliant with <a target='_blank' href='/help'>Terms & Conditions</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactDropzone>
  );
}

export default UploadDropzone;
