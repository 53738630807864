import { connect } from 'react-redux';
import { receiveBannerHeight } from '../../actions/ui_actions';
import Banner from './banner';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  searchLocation: state.ui.searchParams.location,
  uploading: state.ui.uploading,
});

const mapDispatchToProps = (dispatch) => ({
  receiveBannerHeight: (height) => dispatch(receiveBannerHeight(height)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Banner);
