import { connect } from 'react-redux';
import { receiveActivity } from '../../actions/activity_actions';
import { getCurrentUser } from '../../actions/session_actions';
import { receiveUserLeavingUpload, receiveUserOnUpload } from '../../actions/ui_actions';
import { selectOptionFormat } from '../../reducers/selectors';
import Upload from './upload';

const mapStateToProps = (state) => ({
  currentUser: state.session,
  locations: state.entities.locations.collection,
  activities: selectOptionFormat(state.entities.activities),
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  receiveUserOnUpload: () => dispatch(receiveUserOnUpload()),
  receiveUserLeavingUpload: () => dispatch(receiveUserLeavingUpload()),
  receiveActivity: (activity) => dispatch(receiveActivity(activity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Upload);
