const UploadPhotoModal = ({ modalPhoto, locationName, activityName, unsetPhotoModal }) => {
  return (
    <div className='modal-dialog'>
      <div id='photo-modal'>
        <div className='row'>
          <div className='col-5'>
            <div className=' photo-modal-metadata'>
              <h4>Image Properties</h4>
              <div className='modal-field'>
                <label>Name</label>
                <input type='text' className='modal-value' defaultValue={modalPhoto.name} />
              </div>
              <div className='modal-field'>
                <label>Price</label>
                <input
                  className='price-input'
                  type='number'
                  defaultValue={modalPhoto.price}
                  step='0.01'
                  placeholder='0.00'
                />
              </div>
              <div className='modal-field'>
                <label>Tags</label>
                {modalPhoto.tags.size > 0 ?
                  [...modalPhoto.tags].map((tag, i) => <span key={i} className='tag'>#{tag}{i === (modalPhoto.tags.size-1) ? '' : ', '}</span>)
                  : 'None'
                }
              </div>
              <div className='modal-static-values'>
                <div className='modal-field'>
                  <label>Location:</label>
                  <span className='modal-value'>{locationName}</span>
                </div>
                <div className='modal-field'>
                  <label>Activity:</label>
                  <span className='modal-value'>{activityName}</span>
                </div>
                <div className='modal-field'>
                  <label>Time taken:</label>
                  <span className='modal-value'>{modalPhoto.parsedDate} {modalPhoto.parsedTime}</span>
                </div>
                <div className='modal-field'>
                  <label>Dimensions:</label>
                  <span className='modal-value'>{modalPhoto.width}x{modalPhoto.height}px</span>
                </div>
                <div className='modal-field'>
                  <label>File Type:</label>
                  <span className='modal-value'>JPEG</span>
                </div>
              </div>
              <button onClick={unsetPhotoModal} className='photo-modal-button'>Close</button>
            </div>
          </div>
          <div className='col-7 photo-modal-photo'>
            <img src={modalPhoto.browserPath} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPhotoModal;
