import { RECEIVE_ORDER } from '../actions/cart_actions';

const initialState = [];

const cartIdsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_ORDER: {
      const lineItems = action.order.lineItems || [];
      let newState = {}
      lineItems.forEach(lineItem => newState[lineItem.product.id] = true)
      return newState;
    }
  }
  return state;
}

export default cartIdsReducer;
