import { RECEIVE_ACTIVITIES, RECEIVE_ACTIVITY } from '../actions/activity_actions';

const initialState = {};

const activitiesReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_ACTIVITIES:
      return action.activities
    case RECEIVE_ACTIVITY: {
      let newState = Object.assign({}, state);
      newState[action.activity.id] = action.activity;
      return newState;
    }
    default:
      return state;
  }
}

export default activitiesReducer;
