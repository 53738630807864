export default function PoweredByStripeIcon(props) {
  return (
    <svg {...props} width="150" height="34" viewBox="0 0 150 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3" clipPath="url(#clip0)">
        <path d="M146 0H3.73C2.74074 0 1.792 0.392981 1.09249 1.09249C0.392981 1.792 0 2.74074 0 3.73L0 30.27C0 31.2593 0.392981 32.208 1.09249 32.9075C1.792 33.607 2.74074 34 3.73 34H146C147.061 34 148.078 33.5786 148.828 32.8284C149.579 32.0783 150 31.0609 150 30V4C150 2.93913 149.579 1.92172 148.828 1.17157C148.078 0.421427 147.061 0 146 0V0ZM149 30C149 30.7956 148.684 31.5587 148.121 32.1213C147.559 32.6839 146.796 33 146 33H3.73C3.00677 32.9974 2.31391 32.7089 1.80251 32.1975C1.2911 31.6861 1.00263 30.9932 1 30.27V3.73C1.00263 3.00677 1.2911 2.31391 1.80251 1.80251C2.31391 1.2911 3.00677 1.00263 3.73 1H146C146.796 1 147.559 1.31607 148.121 1.87868C148.684 2.44129 149 3.20435 149 4V30Z" fill="black" />
        <path d="M17.0695 11.2402H12.7695V22.0002H14.6895V17.8402H17.0695C19.4695 17.8402 20.9695 16.6802 20.9695 14.5402C20.9695 12.4002 19.4695 11.2402 17.0695 11.2402ZM16.9695 16.2402H14.6895V12.9402H16.9995C18.3795 12.9402 19.1095 13.5302 19.1095 14.5902C19.1095 15.6502 18.3495 16.1902 16.9995 16.1902L16.9695 16.2402Z" fill="black" />
        <path d="M25.1002 14.0003C24.5744 13.9942 24.0533 14.0981 23.5701 14.3054C23.0869 14.5127 22.6525 14.8188 22.2946 15.2039C21.9368 15.5891 21.6634 16.0449 21.4922 16.5419C21.3209 17.039 21.2555 17.5664 21.3002 18.0903C21.2533 18.617 21.3166 19.1477 21.486 19.6486C21.6554 20.1496 21.9272 20.6098 22.2842 20.9999C22.6412 21.3901 23.0754 21.7016 23.5594 21.9148C24.0433 22.1279 24.5663 22.238 25.0952 22.238C25.624 22.238 26.147 22.1279 26.6309 21.9148C27.1149 21.7016 27.5491 21.3901 27.9061 20.9999C28.2631 20.6098 28.5349 20.1496 28.7043 19.6486C28.8738 19.1477 28.937 18.617 28.8902 18.0903C28.9363 17.5669 28.8723 17.0397 28.7022 16.5427C28.5321 16.0456 28.2597 15.5897 27.9026 15.2044C27.5455 14.819 27.1117 14.5128 26.629 14.3054C26.1463 14.098 25.6255 13.994 25.1002 14.0003ZM25.1002 20.6703C23.8802 20.6703 23.1002 19.6703 23.1002 18.0903C23.1002 16.5103 23.8602 15.5103 25.1002 15.5103C26.3402 15.5103 27.1002 16.5103 27.1002 18.0903C27.1002 19.6703 26.3102 20.6603 25.1002 20.6603V20.6703Z" fill="black" />
        <path d="M36.7807 19.3499L35.3707 14.1299H33.8907L32.4907 19.3499L31.0707 14.1299H29.2207L31.5907 22.0099H33.1507L34.5907 16.8499L36.0307 22.0099H37.5907L39.9607 14.1299H38.1807L36.7807 19.3499Z" fill="black" />
        <path d="M44.0002 14C43.4814 14.0096 42.9699 14.1246 42.4968 14.3379C42.0238 14.5513 41.599 14.8585 41.2483 15.241C40.8976 15.6235 40.6283 16.0733 40.4567 16.563C40.2851 17.0528 40.2149 17.5723 40.2502 18.09C40.2084 18.6157 40.2768 19.1444 40.451 19.6422C40.6252 20.14 40.9014 20.596 41.2619 20.9809C41.6223 21.3659 42.0592 21.6714 42.5445 21.8778C43.0298 22.0843 43.5529 22.1872 44.0802 22.18C44.8052 22.2191 45.5242 22.0297 46.1359 21.6386C46.7477 21.2476 47.2213 20.6744 47.4902 20L46.0002 19.38C45.8833 19.7664 45.6385 20.1016 45.3059 20.3306C44.9734 20.5595 44.5729 20.6686 44.1702 20.64C43.8834 20.6468 43.5981 20.5954 43.3317 20.4888C43.0654 20.3821 42.8234 20.2226 42.6205 20.0197C42.4176 19.8168 42.2581 19.5748 42.1514 19.3085C42.0448 19.0421 41.9934 18.7568 42.0002 18.47H47.5202V17.87C47.5402 15.71 46.3202 14 44.0002 14ZM42.0702 17.13C42.1403 16.6708 42.3745 16.2526 42.7294 15.9529C43.0843 15.6532 43.5358 15.4923 44.0002 15.5C44.2209 15.4813 44.443 15.5099 44.6518 15.5837C44.8606 15.6576 45.0512 15.775 45.2111 15.9283C45.371 16.0815 45.4964 16.2671 45.579 16.4726C45.6616 16.678 45.6995 16.8987 45.6902 17.12L42.0702 17.13Z" fill="black" />
        <path d="M50.6906 15.3005V14.1305H48.8906V22.0005H50.6906V17.8705C50.6746 17.6036 50.7153 17.3364 50.8102 17.0865C50.905 16.8366 51.0518 16.6096 51.2408 16.4206C51.4298 16.2316 51.6568 16.0848 51.9067 15.99C52.1566 15.8952 52.4238 15.8544 52.6906 15.8705C52.9104 15.8549 53.1309 15.8549 53.3506 15.8705V14.0705C53.2106 14.0705 53.0506 14.0705 52.8406 14.0705C52.402 14.0477 51.9662 14.1516 51.585 14.3697C51.2038 14.5878 50.8933 14.9109 50.6906 15.3005Z" fill="black" />
        <path d="M57.4807 14C56.9618 14.0096 56.4504 14.1246 55.9773 14.3379C55.5042 14.5513 55.0795 14.8585 54.7288 15.241C54.3781 15.6235 54.1088 16.0733 53.9372 16.563C53.7656 17.0528 53.6954 17.5723 53.7307 18.09C53.6889 18.6157 53.7573 19.1444 53.9315 19.6422C54.1057 20.14 54.3818 20.596 54.7423 20.9809C55.1028 21.3659 55.5397 21.6714 56.025 21.8778C56.5103 22.0843 57.0333 22.1872 57.5607 22.18C58.279 22.2106 58.9891 22.0172 59.5928 21.6267C60.1965 21.2361 60.664 20.6677 60.9307 20L59.3907 19.41C59.2737 19.7964 59.0289 20.1316 58.6964 20.3606C58.3639 20.5895 57.9634 20.6986 57.5607 20.67C57.2797 20.6674 57.002 20.609 56.7438 20.4982C56.4856 20.3874 56.252 20.2263 56.0566 20.0244C55.8612 19.8225 55.7079 19.5837 55.6056 19.322C55.5033 19.0603 55.454 18.7809 55.4607 18.5H61.0007V17.9C61.0007 15.71 59.7607 14 57.4807 14ZM55.5507 17.13C55.6227 16.6744 55.8564 16.2599 56.209 15.9624C56.5615 15.6649 57.0094 15.5043 57.4707 15.51C57.6913 15.4913 57.9135 15.5199 58.1222 15.5937C58.331 15.6676 58.5217 15.785 58.6816 15.9383C58.8414 16.0915 58.9668 16.2771 59.0495 16.4826C59.1321 16.688 59.17 16.9087 59.1607 17.13H55.5507Z" fill="black" />
        <path d="M67.5601 15.0002C67.2826 14.6746 66.9353 14.4156 66.5441 14.2425C66.1529 14.0694 65.7277 13.9866 65.3001 14.0002C63.0901 14.0002 61.8301 15.8502 61.8301 18.0902C61.8301 20.3302 63.0901 22.1802 65.3001 22.1802C65.728 22.1961 66.1539 22.1143 66.5455 21.941C66.937 21.7677 67.284 21.5076 67.5601 21.1802V22.0002H69.3601V11.2402H67.5601V15.0002ZM67.5601 18.3502C67.6004 18.6354 67.5787 18.9258 67.4964 19.2018C67.4141 19.4778 67.2732 19.7327 67.0833 19.9492C66.8934 20.1657 66.659 20.3386 66.3961 20.4562C66.1332 20.5737 65.848 20.6331 65.5601 20.6302C64.2501 20.6302 63.5601 19.6302 63.5601 18.1102C63.5601 16.5902 64.2601 15.5902 65.5601 15.5902C66.6701 15.5902 67.5601 16.4002 67.5601 17.8802V18.3502Z" fill="black" />
        <path d="M79.3092 14.0002C78.8736 13.9812 78.4393 14.0613 78.0392 14.2345C77.639 14.4077 77.2834 14.6696 76.9992 15.0002V11.2402H75.1992V22.0002H76.9992V21.1702C77.2782 21.4966 77.6272 21.756 78.0202 21.9291C78.4131 22.1023 78.84 22.1847 79.2692 22.1702C81.4692 22.1702 82.7292 20.3102 82.7292 18.0802C82.7292 15.8502 81.5092 14.0002 79.3092 14.0002ZM78.9992 20.6002C78.7113 20.6031 78.4261 20.5437 78.1632 20.4262C77.9003 20.3086 77.6659 20.1357 77.476 19.9192C77.2861 19.7027 77.1452 19.4478 77.0629 19.1718C76.9806 18.8958 76.9589 18.6054 76.9992 18.3202V17.8502C76.9992 16.3702 77.8392 15.5602 78.9992 15.5602C80.2992 15.5602 80.9992 16.5602 80.9992 18.0802C80.9992 19.6002 80.2492 20.6002 78.9992 20.6002Z" fill="black" />
        <path d="M86.9296 19.6599L84.9996 14.1299H83.0996L85.9996 21.7199L85.6996 22.4599C85.6486 22.7134 85.5014 22.9373 85.2888 23.0846C85.0763 23.2319 84.8149 23.2912 84.5596 23.2499C84.3599 23.2645 84.1593 23.2645 83.9596 23.2499V24.7599C84.2012 24.7958 84.4453 24.8125 84.6896 24.8099C85.3139 24.8553 85.9344 24.6799 86.4425 24.3143C86.9506 23.9487 87.3142 23.4163 87.4696 22.8099L90.7096 14.1899H88.8196L86.9296 19.6599Z" fill="black" />
        <path d="M125 12.4297C124.605 12.4275 124.214 12.5031 123.848 12.6524C123.483 12.8017 123.15 13.0217 122.87 13.2997L122.73 12.6097H120.34V25.5297L123.06 24.9397V21.8097C123.601 22.2628 124.284 22.5106 124.99 22.5097C126.93 22.5097 128.71 20.9197 128.71 17.3997C128.71 14.1797 126.91 12.4297 125 12.4297ZM124.35 20.0597C124.111 20.0735 123.873 20.034 123.651 19.944C123.43 19.8541 123.231 19.716 123.07 19.5397V15.4297C123.232 15.2471 123.433 15.1029 123.658 15.0077C123.883 14.9126 124.126 14.8688 124.37 14.8797C125.37 14.8797 126.05 16.0097 126.05 17.4597C126.05 18.9097 125.36 20.0597 124.35 20.0597Z" fill="black" />
        <path d="M133.73 12.4297C131.11 12.4297 129.52 14.6897 129.52 17.5397C129.52 20.9097 131.4 22.6197 134.08 22.6197C135.125 22.6367 136.158 22.3854 137.08 21.8897V19.6397C136.243 20.0618 135.316 20.2745 134.38 20.2597C133.3 20.2597 132.38 19.8697 132.24 18.5597H137.62C137.62 18.4097 137.62 17.8197 137.62 17.5597C137.71 14.6897 136.35 12.4297 133.73 12.4297ZM132.26 16.4997C132.26 15.2397 133.03 14.7097 133.71 14.7097C134.39 14.7097 135.11 15.2397 135.11 16.4997H132.26Z" fill="black" />
        <path d="M113 13.36L112.83 12.54H110.51V22.25H113.19V15.67C113.432 15.3762 113.758 15.1632 114.124 15.0595C114.49 14.9559 114.88 14.9665 115.24 15.09V12.54C114.831 12.3798 114.377 12.3747 113.964 12.5257C113.552 12.6768 113.209 12.9735 113 13.36Z" fill="black" />
        <path d="M99.4609 15.4601C99.4609 15.0201 99.8209 14.8501 100.391 14.8501C101.336 14.8704 102.262 15.1174 103.091 15.5701V12.9401C102.233 12.5934 101.316 12.4201 100.391 12.4301C98.1809 12.4301 96.7109 13.6101 96.7109 15.5901C96.7109 18.6901 100.851 18.1901 100.851 19.5201C100.851 20.0401 100.411 20.2101 99.8509 20.2101C98.7944 20.1484 97.767 19.8401 96.8509 19.3101V22.0001C97.7951 22.4212 98.8171 22.6392 99.8509 22.6401C102.111 22.6401 103.671 21.4901 103.671 19.4801C103.621 16.1201 99.4609 16.7201 99.4609 15.4601Z" fill="black" />
        <path d="M107.281 10.2402L104.631 10.8202V19.7502C104.617 20.1266 104.68 20.5017 104.817 20.8528C104.953 21.2039 105.159 21.5235 105.423 21.7921C105.687 22.0607 106.003 22.2727 106.352 22.4151C106.7 22.5575 107.074 22.6273 107.451 22.6202C108.108 22.6496 108.762 22.5228 109.361 22.2502V20.0002C109.011 20.1502 107.301 20.6602 107.301 19.0002V15.0002H109.361V12.6602H107.301L107.281 10.2402Z" fill="black" />
        <path d="M116.25 11.6997L118.98 11.1297V8.96973L116.25 9.53973V11.6997Z" fill="black" />
        <path d="M118.98 12.6104H116.25V22.2504H118.98V12.6104Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="150" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
