import { Ref, RefObject } from 'react';

type Mutable<T> = {
  -readonly [K in keyof T]: T[K];
};

export function mergeRefs<T>(...refs: Ref<T>[]): Ref<T> {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) return null;

  if (filteredRefs.length === 1) return filteredRefs[0];

  return (instance: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        (ref as Mutable<RefObject<T>>).current = instance;
      }
    }
  };
}
