import React from 'react';

const generalData = {
    rows: [
        {
            title: "General Terms and Conditions",
            content: "Wunderpics Inc. has created a platform using certain technology that enables users to sell digital photos to other users. Wunderpics Inc. facilitates the sale and purchasing of digital photos between users on the Site. By using the services provided by the Site, all users agree to the terms and conditions stated here."
        }
    ]
}

export default generalData;