import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SEO from '../../generic/seo';
import UserStorage from '../../generic/user_storage';
import HomeIcon from '../../icons/home_icon';
import EmptyBankAccSection from './empty_bank_account_section';
import EmptyProfileSection from './empty_profile_section';
import FilledBankAccSection from './filled_bank_acc_section';
import FollowSearchesImage from './follow_searches_image';
import HowToUploadImage from './how_to_upload_image';
import LightroomImage from './lightroom_image';

const DashboardHome = () => {
  const {
    firstName,
    username,
    stripeDetailsSubmitted
  } = useSelector(state => state.session);

  return (
    <>
      <SEO title='Home' />
      <div className='container dashboard-home-container'>
        <div className='row home-date-row'>
          <div className='col'>
            <div id='home-heading'>
              <HomeIcon />
              <p>Home</p>
            </div>
          </div>
          <div className='col'>
            <p id='date'>{DateTime.now().toFormat('MMMM dd, yyyy')}</p>
          </div>
        </div>
        <h1 id='welcome-user-sign'>
          Welcome {firstName ? firstName : username}!
        </h1>
        <div className='row card-row'>
          <div className='col'>
            <EmptyProfileSection />
          </div>
          <div className='col'>
            {stripeDetailsSubmitted ?
              <FilledBankAccSection /> :
              <EmptyBankAccSection />}
          </div>
          <div className='col'>
            <UserStorage displayUserName displayUpgradeButton />
          </div>
        </div>
        {/* <div className='row filled-card-row'>
          <div className='col'>
            <FilledProfileSection />
          </div>
          <div className='col'>
            <FilledBankAccSection />
          </div>
          <div className='col'>
            <UserStorage displayUserName />
          </div>
        </div> */}
        <div className='row homeImageContainer'>
          <Link to='/help#sellerGuide'>
            <HowToUploadImage />
          </Link>
        </div>
        <div className='row homeImageContainer'>
          <FollowSearchesImage />
        </div>
        <div className='row homeImageContainer'>
          <LightroomImage />
        </div>
      </div>
    </>
  )
}

export default DashboardHome;
