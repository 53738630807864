import { connect } from 'react-redux';
import ThankYouPage from './thank_you_page';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouPage);
