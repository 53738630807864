import { ReactElement } from 'react';

export default function ExpandIcon({
  width = '22',
  height = '22',
  className= 'expand-icon',
  ...rest
}): ReactElement {
  return (
    <svg {...rest} className={className} width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4029 20.2679H14.0581M1.36841 7.57823V1.2334V7.57823ZM1.36841 1.2334H7.71324H1.36841ZM1.36841 1.2334L7.71324 7.57823L1.36841 1.2334ZM20.4029 7.57823V1.2334V7.57823ZM20.4029 1.2334H14.0581H20.4029ZM20.4029 1.2334L14.0581 7.57823L20.4029 1.2334ZM1.36841 13.9231V20.2679V13.9231ZM1.36841 20.2679H7.71324H1.36841ZM1.36841 20.2679L7.71324 13.9231L1.36841 20.2679ZM20.4029 20.2679L14.0581 13.9231L20.4029 20.2679ZM20.4029 20.2679V13.9231V20.2679Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
