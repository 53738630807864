import { connect } from 'react-redux';
import { createLocation } from '../../actions/location_actions';
import { asArray } from '../../reducers/selectors';
import LocationsPage from './locations_page';

const mapStateToProps = (state) => ({
  currentUser: state.entities.users[state.session.id],
  locations: state.entities.locations.collection,
});

const mapDispatchToProps = (dispatch) => ({
  createLocation: (location) => dispatch(createLocation(location)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsPage);
