import React from 'react';

const HelpLeftNavBar = ({ text }) => {
  return (
    <ul id='helpNavLinks'>
      <li><a id="faqLink" href="#faq">FAQ</a></li>
      <li><a id="sellerGuideLink" href="#sellerGuide">Seller Guide</a></li>
      <li><a id="buyerGuideLink" href="#buyerGuide">Buyer Guide</a></li>
    </ul>
  );
}

export default HelpLeftNavBar;

