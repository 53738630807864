export const fetchActivities = () => (
  $.ajax({
    method: 'GET',
    url: '/api/activities',
  })
);

export const createActivity = (activity) => (
  $.ajax({
    method: 'POST',
    url: '/api/activities',
    data: { activity },
  })
);
