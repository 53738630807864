import classNames from 'classnames';
import CalendarUploadImage from 'images/icons/calendar-gray.svg';
import ClearAllIcon from 'images/icons/clear-all-x.svg';
import InfoUploadImage from 'images/icons/info-upload.svg';
import LocationUploadImage from 'images/icons/location-upload.svg';
import MoneyUploadImage from 'images/icons/money-upload.svg';
import SelectAllIcon from 'images/icons/select-all-icon.svg';
import ActivityUploadImage from 'images/icons/surfing-gray.svg';
import TagsUploadImage from 'images/icons/tags-upload.svg';
import React from 'react';
import { withAlert } from 'react-alert';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { selectOptionFormat } from '../../reducers/selectors';
import { createActivity } from '../../util/api/activity_api_util';
import { runFollowedSearchJob } from '../../util/api/jobs_api_util';
import { uploadToAWS } from '../../util/api/photo/photo_api_util';
import { postUploadBatches } from '../../util/api/upload_batch_api_util';
import DateTools from '../../util/date_tools';
import PhotoUploadDataFactory from '../../util/photo_upload_data_factory';
import GenericMap from '../generic/generic_map';
import LargeSuccessIcon from '../icons/large_success_icon';
import UploadingIcon from '../icons/uploading_icon';
import SidebarAccordion from './sidebar_accordion';
import UploadPhotoGrid from './upload_photo_grid';
import UploadPhotoModal from './upload_photo_modal';

class UploadPhotoManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: props.photos,
      numPhotos: props.photos.length,
      numPhotosDisplayed: props.photos.length,
      firstPhoto: props.firstPhoto,
      lastPhoto: props.lastPhoto,
      newFirstPhoto: props.newFirstPhoto,
      newLastPhoto: props.newLastPhoto,
      rawLocation: props.rawLocation,
      rawActivity: props.rawActivity,
      selectedPhotos: {},
      numSelectedPhotos: 0,
      uploadProgress: 0,
      modalPhoto: '',
      showDateAndTimeModal: false,
      showModalPhoto: false,
      showUploadingModal: false,
      uploadComplete: false,
      bulkPrice: '',
      bulkTag: '',
      dateFilter: '',
      priceFilter: '',
      tagFilter: '',
      sortBy: '',
      timezone: props.timezone,
      deltaAMPM: props.deltaAMPM,
      deltaHour: props.deltaHour,
      deltaMinutes: props.deltaMinutes,
      deltaYear: props.deltaYear,
      deltaMonth: props.deltaMonth,
      deltaDay: props.deltaDay,
      delta: props.delta,
    };

    this.photoUploadDataFactory = new PhotoUploadDataFactory();
    this.fileReader = new FileReader();
    this.alert = this.props.alert;
    this.tagRegex = /^[a-zA-Z0-9_,]*$/;
    this.priceRegex = /^\d+\.\d{2}$/;
    this.sortOptions = ['Date Asc.', 'Date Desc.', 'Price Asc.', 'Price Desc.'];
    this.dashboardMoreInfo = this.dashboardMoreInfo.bind(this);
    this.renderDateAndTimeContent = this.renderDateAndTimeContent.bind(this);
    this.renderLocationContent = this.renderLocationContent.bind(this);
    this.renderActivityContent = this.renderActivityContent.bind(this);
    this.renderPriceContent = this.renderPriceContent.bind(this);
    this.renderTagsContent = this.renderTagsContent.bind(this);
    this.selectPhoto = this.selectPhoto.bind(this);
    this.renderDateAndTimeModal = this.renderDateAndTimeModal.bind(this);
    this.renderPhotoModal = this.renderPhotoModal.bind(this);
    this.renderUploadingModal = this.renderUploadingModal.bind(this);
    this.setPhotoModal = this.setPhotoModal.bind(this);
    this.unsetPhotoModal = this.unsetPhotoModal.bind(this);
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.handleSelectActivity = this.handleSelectActivity.bind(this);
    this.applyBulkPrice = this.applyBulkPrice.bind(this);
    this.updatePriceField = this.updatePriceField.bind(this);
    this.applyBulkTag = this.applyBulkTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.updateTagField = this.updateTagField.bind(this);
    this.removePhotos = this.removePhotos.bind(this);
    this.sortAndFilterPhotos = this.sortAndFilterPhotos.bind(this);
    this.renderPriceFilters = this.renderPriceFilters.bind(this);
    this.clearSelectedPhotos = this.clearSelectedPhotos.bind(this);
    this.renderTagFilters  = this.renderTagFilters.bind(this);
    this.renderDateFilters = this.renderDateFilters.bind(this);
    this.selectAllPhotos = this.selectAllPhotos.bind(this);
    this.updateDeltaAndTimeRange = this.updateDeltaAndTimeRange.bind(this);
    this.toggleAMPM = this.toggleAMPM.bind(this);
    this.updateMinutesField = this.updateMinutesField.bind(this);
    this.uploadAll = this.uploadAll.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.cleanBulkPrice = this.cleanBulkPrice.bind(this);
    this.buildUploadBatchParams = this.buildUploadBatchParams.bind(this);
  }

  dashboardMoreInfo(e) {
    e.preventDefault();
  }

  selectPhoto(photoId) {
    return () => {
      if (this.state.selectedPhotos[photoId]) {
        let selectedPhotos = Object.assign({}, this.state.selectedPhotos);
        delete selectedPhotos[photoId];
        this.setState({ selectedPhotos, numSelectedPhotos: Object.keys(selectedPhotos).length });
      } else {
        const selectedPhotos = Object.assign(
          {},
          this.state.selectedPhotos,
          { [photoId]: true }
        );
        this.setState({ selectedPhotos, numSelectedPhotos: Object.keys(selectedPhotos).length });
      }
    }
  }

  handleSelectLocation(location) {
    this.setState({
      rawLocation: location,
      locationId: location.value,
    });
    this.alert.success(`Location updated for ${this.state.numPhotos} photos.`);
  }

  handleSelectActivity(activity) {
    if (activity.__isNew__) {
      createActivity({ name: activity.label })
        .then((newActivity) => {
          this.setState({
            rawActivity: {
              label: newActivity.name,
              value: newActivity.id,
            },
            activityId: newActivity.id,
          })
          this.props.receiveActivity(newActivity);
        })
    } else {
      this.setState({
        rawActivity: activity,
        activityId: activity.value,
      });
    }
    this.alert.success(`Activity updated for ${this.state.numPhotos} photos.`);
  }

  renderDateAndTimeContent() {
    const { newFirstPhoto } = this.state;
    return(
      <div className='date-and-time-acc'>
        <span>Your first photo was taken on: </span>
        <p>
          {newFirstPhoto.parsedDate}<br />
          {newFirstPhoto.parsedTime}
        </p>
        <span>If this is not correct:</span>
        <button
          className='date-and-time-button'
          onClick={() => this.setState({ showDateAndTimeModal: true })}
        >
          Edit Date &amp; Time
        </button>
      </div>
    );
  }

  renderLocationContent() {
    const { rawLocation } = this.state;
    let centerLocation;
    if (rawLocation.value) {
      const location = this.props.locations.filter(loc => loc.id === rawLocation.value)[0];
      centerLocation = Object.assign({}, rawLocation, location);
    }

    return(
      <div className='location-acc text-center'>
        <Select
          className='sidebar-location-select'
          value={rawLocation}
          classNamePrefix='upload-select'
          options={selectOptionFormat(this.props.locations)}
          onChange={this.handleSelectLocation}
        />
        <div style={{ height: '200px', zIndex: '1' }}>
          <GenericMap
            locations={this.props.locations}
            centerLocation={rawLocation ? centerLocation : {}}
            handleSelectLocation={this.handleSelectLocation}
          />
        </div>

      </div>
    );
  }

  renderActivityContent() {
    const { rawActivity } = this.state;
    return(
      <div className='activity-acc text-center'>
        <span>All photos will be set to the activity selected below</span>
        <CreatableSelect
          className='sidebar-activity-select'
          value={rawActivity}
          onChange={this.handleSelectActivity}
          options={this.props.activities}
        />
      </div>
    );
  }

  updateField(field) {
    return (e) => {
      e.preventDefault();
      this.setState({ [field]: e.target.value }, this.clearSelectedPhotos);
    }
  }

  updateTagField(e) {
    if (this.tagRegex.test(e.target.value))
      this.setState({ bulkTag: e.target.value });
  }

  clearSelectedPhotos() {
    this.setState({ selectedPhotos: {}, numSelectedPhotos: 0, });
  }

  selectAllPhotos() {
    const { photos } = this.state;
    let selectedPhotos = {};
    photos.forEach((photo) => selectedPhotos[photo.id] = true);
    this.setState({ selectedPhotos, numSelectedPhotos: photos.length });
  }

  updatePriceField(e) {
    this.setState({ bulkPrice: e.target.value });
  }

  cleanBulkPrice() {
    const { bulkPrice } = this.state;
    if (bulkPrice) {
      const price = parseFloat(bulkPrice);
      const cleanPrice = price.toFixed(2);
      this.setState({ bulkPrice: cleanPrice });
    }
  }

  renderPriceContent() {
    const { numSelectedPhotos } = this.state;
    return(
      <div className='price-acc text-center'>
        <span>{numSelectedPhotos} selected photo(s) will be set to the price below</span>
        <input
          onChange={this.updatePriceField}
          onBlur={this.cleanBulkPrice}
          type='number'
          placeholder='0.00'
          step='0.01'
          min='0.00'
          className='form-control price-input'
          value={this.state.bulkPrice}
        />
        <button onClick={this.applyBulkPrice} className='price-button'>Apply</button>
      </div>
    );
  }

  renderPriceFilters() {
    const { photos } = this.state;
    let set = new Set();
    photos.forEach((photo) => set.add(photo.price));
    return [...set].sort().map((price, i) => <option key={i} value={price}>${price}</option>)
  }

  renderTagFilters() {
    const { photos } = this.state;
    let set = new Set();
    photos.forEach((photo) => [...photo.tags].forEach((tag) => set.add(tag)));
    return [...set].sort().map((tag, i) => <option key={i} value={tag}>#{tag}</option>)
  }

  renderDateFilters() {
    const { photos } = this.state;
    let set = new Set();
    photos.forEach((photo) => set.add(photo.parsedDate));
    return [...set].sort().map((date, i) => <option key={i} value={date}>{date}</option>)
  }

  // FIXME: Performance
  applyBulkPrice() {
    const { bulkPrice, selectedPhotos, photos, numSelectedPhotos } = this.state;

    if (!this.priceRegex.test(bulkPrice)) {
      alert('Please enter a valid price, example format: 0.00');
      return null;
    }

    const photosUpdated = photos.map((photo) => {
      if (selectedPhotos[photo.id])
        photo.price = bulkPrice;
      return photo;
    });
    this.setState({ photos: photosUpdated, bulkPrice: '' });
    this.alert.success(`Price applied to ${numSelectedPhotos} photos.`);
  }

  applyBulkTag() {
    const { bulkTag, selectedPhotos, photos, numSelectedPhotos } = this.state;

    if (bulkTag === '')
      return null;

    const photosUpdated = photos.map((photo) => {
      if (selectedPhotos[photo.id]) {
        if (bulkTag.includes(','))
          bulkTag.split(',').forEach((tag) => photo.tags.add(tag));
        else
          photo.tags.add(bulkTag);

        if (photo.tags.has(''))
          photo.tags.delete('');
      }
      return photo;
    });
    this.setState({ photos: photosUpdated, bulkTag: '' });
    this.alert.success(`Tag(s) applied to ${numSelectedPhotos} photos.`);
  }

  removeTag(tag) {
    return (e) => {
      e.preventDefault();
      const { photos, selectedPhotos } = this.state;
      let count = 0;
      const updatedPhotos = photos.map((photo) => {
        if (selectedPhotos[photo.id]) {
          if (photo.tags.delete(tag))
            count += 1;
        }
        return photo;
      });
      this.setState({ photos: updatedPhotos });
      this.alert.error(`Tag '${tag}' removed from ${count} photos.`);
    };
  }

  updateMinutesField(e) {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    if (value < 10)
      value = `0${value}`;

    this.setState({ deltaMinutes: `${value}` }, this.updateDeltaAndTimeRange);
  }

  updateEditTimeField(field) {
    return (e) => {
      let { value, min, max } = e.target;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));

      this.setState({ [field]: value }, this.updateDeltaAndTimeRange);
    }
  }

  updateDeltaAndTimeRange() {
    const {
      deltaAMPM,
      deltaHour,
      deltaMinutes,
      deltaYear,
      deltaMonth,
      deltaDay,
      firstPhoto,
      lastPhoto,
    } = this.state;

    const newFirstDate = DateTools.implodeDate(`${deltaYear}-${deltaMonth}-${deltaDay} ${deltaHour}:${deltaMinutes}${deltaAMPM}`);

    const delta = DateTools.calculateDeltaInMillis(
      firstPhoto.parsedTimestamp,
      newFirstDate.toISO(),
    );

    this.setState({
      newFirstPhoto: DateTools.addDeltaMillis(firstPhoto.parsedTimestamp, delta),
      delta
    });

    if (lastPhoto) {
      this.setState({
        newLastPhoto: DateTools.addDeltaMillis(lastPhoto.parsedTimestamp, delta),
      });
    }
  }

  //FIXME: Performance
  renderTagsContent() {
    const { numSelectedPhotos, photos, selectedPhotos, bulkTag } = this.state;
    let currentTags = {};
    photos.forEach((photo) => {
      if (selectedPhotos[photo.id]) {
        [...photo.tags].forEach((tag) => {
          currentTags[tag] = (currentTags[tag] || 0 ) + 1;
        });
      }
    });

    return(
      <div className='tags-acc text-center'>
        <span>The tag below will be added to {numSelectedPhotos} selected photo(s)</span>
        <input
          type='text'
          placeholder='Separate tags by commas'
          className='form-control tag-input'
          value={bulkTag}
          onChange={this.updateTagField}
        />
        <button onClick={this.applyBulkTag} className='tag-button'>Add Tag(s)</button>
        <span className='current-tags-header'>Current tags:</span>
        <div className='current-tags'>
          {_.isEmpty(Object.keys(currentTags)) ?
            `No tags for the current selection` :
            Object.keys(currentTags).map((tag, i) =>
              <span key={i}
                className={currentTags[tag] === numSelectedPhotos ? 'tag all-tag' : 'tag'}
              >
                {tag}
                <a className='tag-delete' onClick={this.removeTag(tag)}>&#xd7;</a>
              </span>)
          }
        </div>
      </div>
    );
  }

  toggleAMPM() {
    const { deltaAMPM } = this.state;

    if (deltaAMPM === 'AM') {
      this.setState({ deltaAMPM: 'PM' }, this.updateDeltaAndTimeRange);
    } else {
      this.setState({ deltaAMPM: 'AM' }, this.updateDeltaAndTimeRange);
    }
  }

  renderDateAndTimeModal() {
    const {
      showDateAndTimeModal,
      firstPhoto,
      newFirstPhoto,
      newLastPhoto,
      deltaAMPM,
      deltaHour,
      deltaMinutes,
      deltaYear,
      deltaMonth,
      deltaDay,
      delta,
      timezone,
    } = this.state;

    return showDateAndTimeModal ? (
      <div className='modal-dialog'>
        <div id='date-and-time-modal'>
          <div className='timestamp-editor-display'>
            <h2>Shift date and times</h2>
            <div className='first-photo'>
              <img className='first-photo-photo' src={firstPhoto.browserPath} />
              <div className='first-photo-right'>
                This is the first photo in the batch. Choosing a new date and time below will shift all other photos in the batch according to this new date.
              </div>
            </div>
            <div className='upload-left-field'>
              <div className='date-editor'>
                <div>
                  <label>Year</label><br />
                  <input
                    type='number'
                    value={deltaYear}
                    step='1'
                    max={new Date().getFullYear()}
                    min='1950'
                    onChange={this.updateEditTimeField('deltaYear')}
                  />
                </div>
                <div>
                  <label>Month</label><br />
                  <input
                    type='number'
                    value={deltaMonth}
                    step='1'
                    max='12'
                    min='1'
                    onChange={this.updateEditTimeField('deltaMonth')}
                  />
                </div>
                <div>
                  <label>Day</label><br />
                  <input
                    type='number'
                    value={deltaDay}
                    min='1'
                    step='1'
                    max={new Date(deltaYear, deltaMonth, 0).getDate()}
                    onChange={this.updateEditTimeField('deltaDay')}
                  />
                </div>
              </div>
            </div>
            <div className='upload-left-field'>
              <label>Time</label>
              <div className='time-editor'>
                <div>
                  <input
                    type='number'
                    value={deltaHour}
                    min='1'
                    max='12'
                    step='1'
                    onChange={this.updateEditTimeField('deltaHour')}
                  />
                </div>
                <div>
                <input
                    type='number'
                    value={deltaMinutes}
                    min='0'
                    max='59'
                    step='1'
                    onChange={this.updateMinutesField}
                  />
                </div>
                <div>
                  <input type='text' value={deltaAMPM} onClick={this.toggleAMPM} readOnly />
                </div>
              </div>
            </div>
            <div className='upload-left-field'>
              <label className='margin-right'>Timezone: </label>
              <span>{timezone.hours_offset} {timezone.abbreviation} {timezone.name}</span>
            </div>
            <div className='upload-left-field'>
              <label>New Range</label>
              <div className='new-range'>
                <div className='range-date'>
                  {newFirstPhoto.parsedDate}<br />
                  {newFirstPhoto.parsedTime}
                </div>
                {newLastPhoto &&
                  <>
                    <div>-</div>
                    <div className='range-date'>
                      {newLastPhoto.parsedDate}<br />
                      {newLastPhoto.parsedTime}
                    </div>
                  </>
                }
              </div>
            </div>
            <br />
            <button
              className='upload-button-large'
              onClick={() => this.setState({ showDateAndTimeModal: false })}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    ): null;
  }

  setPhotoModal(photo) {
    return () => {
      this.setState({ modalPhoto: photo, showModalPhoto: true })
    };
  }

  unsetPhotoModal() {
    this.setState({
      modalPhoto: '',
      showModalPhoto: false,
    });
  }

  sortAndFilterPhotos() {
    const {
      sortBy,
      tagFilter,
      dateFilter,
      priceFilter,
      photos,
    } = this.state;

    let filteredPhotos = photos;

    if (dateFilter)
      filteredPhotos = filteredPhotos.filter((photo) => photo.parsedDate === dateFilter);
    if (priceFilter)
      filteredPhotos = filteredPhotos.filter((photo) => photo.price === priceFilter);
    if (tagFilter)
      filteredPhotos = filteredPhotos.filter((photo) => photo.tags.has(tagFilter));

    if (sortBy) {
      if (sortBy === 'Date Asc.')
        filteredPhotos = filteredPhotos.sort((a, b)=> a.dateObject - b.dateObject)
      else if (sortBy === 'Date Desc.')
        filteredPhotos = filteredPhotos.sort((a, b)=> b.dateObject - a.dateObject)
      else if (sortBy === 'Price Asc.')
        filteredPhotos = filteredPhotos.sort((a, b)=> a.price - b.price)
      else if (sortBy === 'Price Desc.')
        filteredPhotos = filteredPhotos.sort((a, b)=> b.price - a.price)
    }

    return filteredPhotos;
  }

  removePhotos(e) {
    const {
      photos,
      selectedPhotos,
      numSelectedPhotos,
    } = this.state;

    const filteredPhotos = photos.filter((photo) => !selectedPhotos[photo.id]);
    this.alert.error(`${numSelectedPhotos} photos removed from the batch.`);

    this.setState({
      selectedPhotos: {},
      numSelectedPhotos: 0,
      numPhotos: filteredPhotos.length,
      photos: filteredPhotos,
    });
  }

  renderPhotoModal() {
    const { modalPhoto, rawLocation, rawActivity } = this.state;

    return modalPhoto ?
      <UploadPhotoModal
        modalPhoto={modalPhoto}
        locationName={rawLocation.label}
        activityName={rawActivity.label}
        unsetPhotoModal={this.unsetPhotoModal}
      /> : null;
  }

  renderUploadingModal() {
    const {
      showUploadingModal,
      numPhotos,
      uploadProgress,
      uploadComplete,
      shareLink,
      uploadBatchId,
    } = this.state;

    const uploadPercent = (uploadProgress / (numPhotos || 1)) * 100

    return showUploadingModal ? (
      <div className='modal-dialog uploading-modal-dialog'>
        <div className='uploading-modal'>
          {uploadComplete ?
            <div className='upload-complete'>
              <h3>Success!</h3>
              <LargeSuccessIcon />
              <p>Your photos were successfully uploaded and posted to <strong>WunderPics!</strong></p>
              <label>Share</label>
              <div className='d-flex'>
                <div className='upload-share-link'>
                  {shareLink}
                </div>
                <button
                  className='btn btn-dark'
                  onClick={() => {
                    navigator.clipboard.writeText(shareLink);
                    this.props.alert.success('Share link successfully copied to clipboard!')
                  }}
                >
                  Copy
                </button>
              </div>
              <a href={shareLink}>
                <button className='btn blue-button'>
                  View photos on website
                </button>
              </a>
              <Link to={`/upload-manager?batchId=${uploadBatchId}`}>
                <button className='btn yellow-button'>
                  Go to Upload Manager
                </button>
              </Link>
            </div>
            :
            <div className='uploading-progress'>
              <h3>
                Please wait...<br />Your photos are being uploaded
              </h3>
              <UploadingIcon className='uploading-modal-icon' />
              <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={uploadPercent} aria-valuemin="0" aria-valuemax={numPhotos} style={{width: `${uploadPercent}%`}}></div>
              </div>
              <p>{uploadPercent.toFixed(0)}%</p>
            </div>
          }
        </div>
      </div>
    ) : null;
  }

  async uploadAll() {
    this.setState({ showUploadingModal: true });
    const photos = this.state.photos.filter((image) => !image.uploaded );
    const uploadBatch = await postUploadBatches(this.buildUploadBatchParams())
    const photosPromises = photos.map((image) => this.uploadPhoto(image, uploadBatch.batchId));

    Promise.all(photosPromises).
      then(() => this.props.getCurrentUser()).
      then(() => this.setState({ uploadComplete: true, shareLink: uploadBatch.shareLink, uploadBatchId: uploadBatch.batchId })).
      then(() => runFollowedSearchJob(uploadBatch.batchId));
  }

  buildUploadBatchParams() {
    const { photos, rawLocation, rawActivity } = this.state;
    const sortedPhotos = photos.sort((a, b)=> a.dateObject - b.dateObject)
    const firstPhoto = sortedPhotos[0];
    const lastPhoto = sortedPhotos[sortedPhotos.length - 1];

    return {
      capture_begin: firstPhoto.parsedTimestamp,
      capture_end: lastPhoto.parsedTimestamp,
      location_id: rawLocation.value,
      activity_id: rawActivity.value,
      num_photos: photos.length,
      batch_size: photos.reduce((sum, { size }) => sum + size, 0),
    };
  }

  uploadPhoto(photo, batchId) {
    const { rawLocation, rawActivity } = this.state;
    const formData = this.photoUploadDataFactory.buildFormData(
      photo, rawLocation.value, rawActivity.value, batchId);
    return uploadToAWS(photo, formData).
      then(() => {
        const newProgress = this.state.uploadProgress + 1;
        this.setState({ uploadProgress: newProgress })
      });
  }

  // uploadPhoto(photo, batchId) {
  //   const { rawLocation, rawActivity } = this.state;

  //   return fetch(photo.browserPath).
  //     then((response) => response.blob()).
  //     then((blob) => {
  //       photo.data = blob;
  //       const formData = this.photoUploadDataFactory.buildFormData(
  //         photo, rawLocation.value, rawActivity.value, batchId);
  //       return createPhoto(formData);
  //     }).
  //     then(() => {
  //       const newProgress = this.state.uploadProgress + 1;
  //       this.setState({ uploadProgress: newProgress })
  //     });
  // }

  render() {
    const {
      numPhotos,
      photos,
      selectedPhotos,
      showModalPhoto,
      showDateAndTimeModal,
      showUploadingModal,
      numSelectedPhotos,
    } = this.state;

    const filteredPhotos = this.sortAndFilterPhotos(photos);
    const modalClasses = classNames('upload-modal', {
      'wup-modal': (showModalPhoto || showDateAndTimeModal || showUploadingModal),
      'fade': (showModalPhoto || showDateAndTimeModal || showUploadingModal),
      'show': (showModalPhoto || showDateAndTimeModal || showUploadingModal),
    });

    return (
      <div id='upload-photo-manager'>
        <div className='upload-sidebar'>
          <div className='upload-sidebar-header'>
            Uploader <a onClick={this.dashboardMoreInfo}><img src={InfoUploadImage} /></a>
          </div>
          <SidebarAccordion
            iconSrc={CalendarUploadImage}
            title='Date &amp; Time'
            content={this.renderDateAndTimeContent()}
          />
          <SidebarAccordion
            iconSrc={LocationUploadImage}
            title='Location'
            content={this.renderLocationContent()}
          />
          <SidebarAccordion
            iconSrc={ActivityUploadImage}
            title='Activity'
            content={this.renderActivityContent()}
          />
          <SidebarAccordion
            iconSrc={MoneyUploadImage}
            title='Price'
            content={this.renderPriceContent()}
          />
          <SidebarAccordion
            iconSrc={TagsUploadImage}
            title='Tags'
            content={this.renderTagsContent()}
          />
          <div className='upload-sidebar-actions'>
            <button
              className='upload-button-large upload-red'
              disabled={!Boolean(numSelectedPhotos > 0)}
              onClick={(e) => { if (window.confirm(`Are you sure you wish to remove these ${numSelectedPhotos} photos?`)) this.removePhotos(e) } }
            >
              Remove {numSelectedPhotos} Photos
            </button>
            <br />
            <button
              className='upload-button-large'
              onClick={(e) => { if (window.confirm(`Are you sure you're ready to publish these ${numPhotos} photos?`)) this.uploadAll() } }
            >
              Publish
            </button>
          </div>
        </div>
        <div className='upload-main'>
          <div className='filter-bar'>
            <div className='filter-bar-top'>
              <h2>Filter</h2>
              <span>Showing <strong>{filteredPhotos.length}/{numPhotos}</strong> images</span>
            </div>
            <div className='filter-bar-bottom'>
              <div className='filter-item'>
                <select
                  defaultValue=''
                  placholder='Price'
                  className='form-control'
                  onChange={this.updateField('priceFilter')}
                >
                  <option value=''>Price</option>
                  {this.renderPriceFilters()}
                </select>
              </div>
              <div className='filter-item'>
                <select
                  defaultValue=''
                  placholder='Date'
                  className='form-control'
                  onChange={this.updateField('dateFilter')}
                >
                  <option value=''>Date</option>
                  {this.renderDateFilters()}
                </select>
              </div>
              <div className='filter-item'>
                <select
                  defaultValue=''
                  placholder='Tag'
                  className='form-control'
                  onChange={this.updateField('tagFilter')}
                >
                  <option value=''>Tag</option>
                  {/* {this.renderTagFilters()} */}
                </select>
              </div>
              <div className='filter-item'>
                <span
                  onClick={this.selectAllPhotos}
                  className='filter-upload-button'
                >
                  <span className='space-right'><img src={SelectAllIcon} /></span>
                  Select All Photos
                </span>
              </div>
              <div className='filter-item'>
                <span
                  onClick={this.clearSelectedPhotos}
                  className='filter-upload-button'
                >
                  <span className='space-right'><img src={ClearAllIcon} /></span>
                  Clear Selection
                </span>
              </div>
              <div className='filter-item'>
                <span className='space-right'>Sort By:</span>
                <select
                  defaultValue='Date Asc.'
                  className='form-control'
                  onChange={this.updateField('sortBy')}
                >
                  {this.sortOptions.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className='upload-photos'>
              {filteredPhotos.map((photo, i) =>
                <UploadPhotoGrid
                  selectPhoto={this.selectPhoto(photo.id)}
                  key={i}
                  photo={photo}
                  setPhotoModal={this.setPhotoModal(photo)}
                  selected={selectedPhotos[photo.id] || false}
                />
              )}
          </div>
        </div>
        <div className={modalClasses}>
          {this.renderDateAndTimeModal()}
          {this.renderPhotoModal()}
          {this.renderUploadingModal()}
        </div>
      </div>
    )
  }
}

export default withRouter(withAlert()(UploadPhotoManager));
