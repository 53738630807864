import React from 'react';

const purchaseData = {
    rows: [
        {
            title: "Terms of Purchase",
            content: "As a purchaser of digital photos through the Site, you acknowledge that all purchasable photos are for personal use only. The digital photos you have obtained from the Site may not be used for commercial use, in any manner. The purchasing of a digital photo from the Site does not convey any rights under copyright or other laws to exploit the digital photo obtained. Reselling of any purchased file is prohibited. You agree to pay the appropriate transaction fees on the subtotal to Wunderpics Inc. for any digital photo purchased by you on the Site."
        }
    ]
}

export default purchaseData;