import {
  forwardRef,
  ReactNode,
  ReactPortal,
  RefObject,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useKeyboardEvents, useMouseEvents, usePortal } from '../../hooks';
import { mergeRefs } from '../../util/react_util';
import FocusLock from './focus_lock';

export interface ModalProps {
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEscapeKey?: boolean;
  initialVisibility?: boolean;
  backdrop?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  children?: ReactNode;
}

export type ModalRef = {
  close: () => void;
  open: () => void;
};

export default forwardRef<ModalRef, ModalProps>(function Modal(
  {
    className = 'modal',
    closeOnClickOutside = true,
    closeOnEscapeKey = true,
    initialVisibility = false,
    backdrop = true,
    onClose,
    onOpen,
    children,
  }: ModalProps,
  ref
): ReactPortal | null {
  const portal = usePortal('modal');
  const contentRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(initialVisibility);

  const close = useCallback(() => {
    onClose?.();
    setIsVisible(false);
  }, [onClose]);

  const open = useCallback(() => {
    setIsVisible(true);
    onOpen?.();
  }, [onOpen]);

  useImperativeHandle(ref, () => ({
    close,
    open,
  }));

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      closeOnClickOutside &&
        !contentRef.current?.contains(e.target as HTMLDivElement) &&
        close();
    },
    [closeOnClickOutside, close]
  );
  useMouseEvents({ mousedown: handleClickOutside });

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (closeOnEscapeKey && e.key === 'Escape') {
        setIsVisible(false);
      }
    },
    [closeOnEscapeKey]
  );
  useKeyboardEvents({ keydown: handleEscapeKey });

  const backdropClasses = classNames(`${className}__backdrop`, {
    'modal__backdrop': backdrop,
  });

  return !isVisible
    ? null
    : createPortal(
        <div className={className}>
          <div className={backdropClasses}>
            <FocusLock>
              {(parentRef) => (
                <div
                  aria-modal
                  className={`${className}__content modal__content`}
                  ref={mergeRefs(
                    contentRef,
                    parentRef as RefObject<HTMLDivElement>
                  )}
                  role="dialog"
                >
                  {children}
                </div>
              )}
            </FocusLock>
          </div>
        </div>,
        portal
      );
});
