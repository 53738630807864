import { RECEIVE_ORDER, CLEAR_ORDER } from '../actions/cart_actions';

const initialState = {
  lineItems: [],
};

const cartReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_ORDER: {
      return action.order;
    }
    case CLEAR_ORDER: {
      return initialState;
    }
    default:
      return state;
  }
}

export default cartReducer;
