import { ReactElement } from 'react';
import classNames from 'classnames';

import ExpandIcon from '../icons/expand_icon';

export default function GridPhoto({
  showPhotoModal,
  photo,
  selectPhoto,
  selected,
}): ReactElement {

  const photoClasses = classNames('wp-grid-photo', {
    'selected': selected,
  });

  return (
    <div className='wp-grid-photo-container'>
      <div className='grid-photo-expand'>
        <ExpandIcon onClick={() => showPhotoModal(photo)} />
      </div>
      <img
        loading="lazy"
        onClick={selectPhoto}
        className={photoClasses}
        src={photo.thumbnailUrl}
      />
      <div className='grid-photo-info'>
        <span>{photo.price}</span>
        <span className='photo-info-time'>{photo.time}</span>
      </div>
    </div>
  );
}
