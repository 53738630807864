import { ReactElement } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { Account, Notifications, PaymentMethod, Payout, Plans } from '.';
import { Panel } from '../library';
import SEO from '../generic/seo';

export default function Settings(): ReactElement {
  const match = useRouteMatch();

  return (
    <>
      <SEO title='Settings' />
      <Panel className="settings">
        <Panel className="tabs">
          <div className="tabs__container">
            <Panel.Header>SETTINGS</Panel.Header>
            <Panel.Body className="tab__list">
              <NavLink
                activeClassName="tab__label--active"
                className="tab__label"
                to={match.url + '/account'}
              >
                Account
              </NavLink>
              <NavLink
                activeClassName="tab__label--active"
                className="tab__label"
                to={match.url + '/notifications'}
              >
                Notifications
              </NavLink>
              <NavLink
                activeClassName="tab__label--active"
                className="tab__label"
                to={match.url + '/payment-method'}
              >
                Payment Method
              </NavLink>
              <NavLink
                activeClassName="tab__label--active"
                className="tab__label"
                to={match.url + '/payout'}
              >
                Payout
              </NavLink>
              <NavLink
                activeClassName="tab__label--active"
                className="tab__label"
                to={match.url + '/plans'}
              >
                Plans
              </NavLink>
            </Panel.Body>
          </div>
        </Panel>
        <div className="tab__panel">
          <Switch>
            <Route exact path={match.url + '/account'} component={Account} />
            <Route
              exact
              path={match.url + '/notifications'}
              component={Notifications}
            />
            <Route
              exact
              path={match.url + '/payment-method'}
              component={PaymentMethod}
            />
            <Route exact path={match.url + '/payout'} component={Payout} />
            <Route exact path={match.url + '/plans'} component={Plans} />
            <Redirect to={match.url + '/account'} />
          </Switch>
        </div>
      </Panel>
    </>
  );
}
