import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { clearOrder } from '../../actions/cart_actions';
import { createCheckoutSession } from '../../util/api/stripe_api_util';
import SEO from '../generic/seo';
import Footer from './../footer';
import LineItem from './line_item';

export default function CartPage({
  cart,
  deleteLineItem,
  currentUser,
}) {
  const stripe = useStripe();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    createCheckoutSession().
      then((order) => {
        if (order.stripeOrder) {
          stripe.redirectToCheckout({ sessionId: order.sessionId })
        } else {
          // Wait 3 seconds to let the order process (should be much shorter)
          setTimeout(() => {
            dispatch(clearOrder());
            history.push('/thank-you?sessionId=' + order.sessionId);
          }, 3000)
        }
      })
  }

  const handleDeleteLineItem = (lineItem) => {
    return () => {
      deleteLineItem(lineItem)
    }
  }

  const renderLineItems = () => {
    const { lineItems } = cart;
    return lineItems ?
      lineItems.map((lineItem, i) =>
        <LineItem
          key={i}
          lineItem={lineItem}
          product={lineItem.product}
          deleteLineItem={handleDeleteLineItem(lineItem)}
        />) : null;
  }

  const renderBilling = () => {
    const {
      grandTotal,
      communityFee,
      total,
    } = cart;

    const currencyType = 'USD';

    return (
      <div className='col-lg-3 col-xs-12'>
        <div className='summary-table'>
          <div className='summary-table-header'>
            Summary
          </div>
          <div className='summary-table-inner'>
            <div className='row'>
              <div className='col-7'>Subtotal:</div>
              <div className='col-5 summary-price'>${total}</div>
            </div>
            <div className='row'>
              <div className='col-7'>Community Fee:</div>
              <div className='col-5 summary-price'>${communityFee}</div>
            </div>
            <div className='row'>
              <div className='col-5'>
                Total:
              </div>
              <div className='col-7 summary-price grand'>${grandTotal}</div>
            </div>
            <div className='row'>
              <div className='col-12 align-right'>
                {currencyType}
              </div>
            </div>
            <div className='summary-buttons'>
              {renderCheckoutButton()}
              <p><Link to='/'>Continue Shopping</Link></p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderCheckoutButton = () => {
    if (!currentUser) {
      return (
          <a className='checkout-btn' href='/users/sign_in?redirect_to=cart'>Login to Pay</a>
      );
    }

    return (
      <button
        onClick={handleSubmit}
        disabled={!stripe || isLoading}
        className='checkout-btn'
      >
        {isLoading ? 'Loading...' : 'Proceed to checkout'}
      </button>
    );
  }


  const cartPopulated = cart.lineItems.length > 0;
  const itemCount = cartPopulated ? <h3>{cart.lineItems.length} Items</h3> : null;

  return (
    <>
      <SEO title='Cart' />
      <div className='cart-page page-container main-inner'>
        <div className='row cart-header no-gutters'>
          <div className='col-12'>
            <h2>Shopping Cart</h2>
            {itemCount}
          </div>
        </div>
        <div className='row no-gutters'>
          {cartPopulated ?
            (<>
              <div className='col-lg-9 col-xs-12 cart-items'>
                <div className='row'>
                  <table className="main-table">
                    <thead>
                      <tr className="main-table-header">
                        <th className="header-photo">Photo</th>
                        <th className="header-description">Description</th>
                        <th className="header-price">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderLineItems()}
                    </tbody>
                  </table>
                </div>
              </div>
              {renderBilling()}
            </>) :
            (<h2>Your cart is empty.</h2>)
          }
        </div>
      </div>
      <Footer />
    </>
  )
}
