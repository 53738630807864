import React from 'react';

const MorePhotosLeft = (props) => (
  <svg {...props} width="36" height="154" viewBox="0 0 36 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.9879 130.702H24.9939C28.311 130.702 31.0001 128.257 31.0001 125.241V29.9816C31.0001 26.9661 28.311 24.5215 24.9939 24.5215H18.9879V130.702Z" fill="#373737"/>
    </g>
    <g filter="url(#filter1_d)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1079 138.141H15.4301C18.4457 138.141 20.8902 135.696 20.8902 132.681V18.0226C20.8902 15.0071 18.4457 12.5625 15.4301 12.5625H11.1079V138.141Z" fill="#9C9C9C"/>
    </g>
    <g filter="url(#filter2_d)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.452515 147.915H5.22057C8.2361 147.915 10.6807 145.471 10.6807 142.455V6.35658C10.6807 3.34105 8.2361 0.896484 5.22057 0.896484H0.452515V147.915Z" fill="#C4C4C4"/>
    </g>
    <defs>
      <filter id="filter0_d" x="18.9879" y="24.5215" width="17.0122" height="112.18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dx="3" dy="4"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_d" x="11.1079" y="12.5625" width="14.7823" height="131.579" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dx="3" dy="4"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter2_d" x="0.452515" y="0.896484" width="16.2282" height="153.019" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dx="4" dy="4"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
);

export default MorePhotosLeft;
