import { ReactElement, SVGProps } from 'react';

export default function StarIcon({
  className = 'star-icon',
  height = '67',
  width = '70',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 70 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.4928 23.9653L46.665 20.9384L37.3545 2.06297C37.1002 1.54617 36.6818 1.12781 36.165 0.873517C34.8689 0.233673 33.2939 0.766876 32.6459 2.06297L23.3353 20.9384L2.50761 23.9653C1.93339 24.0473 1.40839 24.318 1.00643 24.7282C0.520495 25.2277 0.252721 25.8996 0.26195 26.5964C0.271179 27.2932 0.556656 27.9578 1.05565 28.4442L16.1248 43.136L12.5646 63.8817C12.4812 64.3643 12.5346 64.8606 12.7188 65.3144C12.903 65.7682 13.2107 66.1613 13.607 66.4491C14.0033 66.7369 14.4722 66.9079 14.9608 66.9428C15.4493 66.9776 15.9378 66.8748 16.3709 66.6462L35.0002 56.8516L53.6295 66.6462C54.1381 66.9169 54.7287 67.0071 55.2947 66.9087C56.7221 66.6626 57.6818 65.3091 57.4357 63.8817L53.8756 43.136L68.9447 28.4442C69.3549 28.0423 69.6256 27.5173 69.7076 26.943C69.9291 25.5075 68.9283 24.1786 67.4928 23.9653ZM47.5346 41.0688L50.4959 58.32L35.0002 50.1825L19.5045 58.3282L22.4658 41.077L9.93143 28.8544L27.2564 26.336L35.0002 10.6434L42.7439 26.336L60.0689 28.8544L47.5346 41.0688Z"
      />
    </svg>
  );
}
