import { connect } from 'react-redux';
import { selectOptionFormat } from '../../reducers/selectors';
import { setSearchLocation } from '../../actions/search_actions';
import Landing from './landing';

const mapStateToProps = (state) => ({
  locations: selectOptionFormat(state.entities.locations.collection),
  searchLocation: state.ui.searchParams.location,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchLocation: locationId => dispatch(setSearchLocation(locationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Landing);
