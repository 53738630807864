import { MouseEventHandler, ReactElement } from 'react';

interface LineItemProps {
  lineItem: any,
  product: {
    thumbnail_url: string,
    name: string,
    user: {
      username: string,
    },
    location: {
      name: string,
    },
    photo_date: string,
    width: string,
    height: string,
    file_size: string,
    display_price: string,
  },
  deleteLineItem: MouseEventHandler<HTMLAnchorElement>,
}

export default function LineItem({ lineItem, product, deleteLineItem}: LineItemProps): ReactElement {
  return (
    <tr className="row-data row no-gutters">
      <td className="photo-column col-3">
        <img src={product.thumbnail_url} className="photos" alt={product.name} />

        <a onClick={deleteLineItem}>Remove</a>
      </td>
      <td className="description-column col-7">
        <table>
          <tbody>
            <tr>
              <td className="description-title" >Photo:</td>
              <td className="description-info">{product.name}</td>
            </tr>
            <tr>
              <td className="description-title">Photographer:</td>
              <td className="description-info">{product.user.username}</td>
            </tr>
            <tr>
              <td className="description-title" >Location:</td>
              <td className="description-info">{product.location.name}</td>
            </tr>
            <tr>
              <td className="description-title" >Date &amp; Time:</td>
              <td className="description-info">{product.photo_date}</td>
            </tr>
            <tr>
              <td className="description-title">Size:</td>
              <td className="description-info">{product.width}x{product.height} | {product.file_size}</td>
            </tr>
            <tr>
              <td></td>
              <td className="personal-license">
                <p>Personal Use License</p>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td className="price-column col-2">
        <div>
          <span className='product-display-price'>{lineItem.price}</span>
          <br />
          USD
        </div>
      </td>
    </tr>
  );
}
