import { CurrentUser } from '../../../types';

export const logout = (): JQuery.jqXHR => {
  return $.ajax({
    method: 'DELETE',
    url: '/users/sign_out',
  })
};

export const currentUser = ():JQuery.jqXHR<CurrentUser> => {
  return $.ajax({
    method: 'GET',
    url: '/api/users/current_user'
  });
}
