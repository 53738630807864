import React from 'react';

import GenericMap from './../generic/generic_map';

class SearchMap extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { searchLocation, locations, setSearchLocation, searchPhotos } = this.props;
    return(
      <div className='search-map'>
        <GenericMap
          centerLocation={searchLocation}
          locations={locations}
          setCenterLocation={setSearchLocation}
          searchPhotos={searchPhotos}
        />
      </div>
    );
  }
}

export default SearchMap;
