import FilledBankAccIcon from "../../icons/filled_bank_acc_icon";
import VerifiedBankAccIcon from "../../icons/verified_bank_acc_icon";
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardBody, CardText, Button } from 'reactstrap';

const FilledBankAccSection = () => {
  return (
    <Card className='dashboard-home-card'>
      <CardBody className='dashboard-card-body'>
        <VerifiedBankAccIcon />
        <FilledBankAccIcon />
        <CardTitle className='dashboard-card-title'>Payouts Connnected!</CardTitle>
        {/* <CardText className='bank-card-text'>JPMORGAN CHASE 7521</CardText> */}
        <Link to='/settings/payout'>
          <Button className='dashboard-home-btn'>Edit Bank Account</Button>
        </Link>
      </CardBody>
    </Card>
  )
}

export default FilledBankAccSection;
