import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/root_reducer';
import SEO from '../generic/seo';
import StarIcon from '../icons/star_icon';
import { Collection, Heading, Panel } from '../library';
import FollowedSearchCard from './followed_search_card';

export default function FollowedSearches(): ReactElement {
  const {
    followedSearches,
  } = useSelector(
    (state: RootState) => state.session
  );

  if (!followedSearches.length) {
    return (
      <Panel className="empty">
        <StarIcon />
        <Heading level={1}>You are not following any searches yet</Heading>
        <p>Click the &quot;<b>Follow Search</b>&quot; button duing a search</p>
        <p>to be notified when new images are uploaded.</p>
      </Panel>
    );
  }
  return (
    <>
      <SEO title='Followed Searches' />
      <Panel className='followed-search-container'>
        <Panel.Header>
          <Heading level={2}>Followed Searches</Heading>
        </Panel.Header>
        <Panel.Body>
          <Collection items={followedSearches.sort((a, b) => (a.followDateValue > b.followDateValue) ? 1 : -1)} keyName='id'>
            {(followedSearch) => (
              <>
                <FollowedSearchCard {...followedSearch} />
              </>
            )}
          </Collection>
        </Panel.Body>
      </Panel>
    </>
  )
}
