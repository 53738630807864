import React from 'react';

const underThirteenData = {
    rows: [
        {
            title: "Children Under Thirteen",
            content: "WunderPics, Inc. does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use WunderPics.com only with permission of a parent or guardian."
        }
    ]
}

export default underThirteenData;