import React from 'react';

const UploadPhotoCard = ({
  photoCardLabel,
  photo,
  timezone = null,
}) => (
  <div className='upload-photo-card'>
    <img src={photo.browserPath || photo.thumbnailUrl} />
    <div className='upload-photo-card-bottom'>
      <div>
        <label>{photoCardLabel}</label>
      </div>
      <p className='upload-photo-card-date'>{photo.parsedDate || photo.date}</p>
      <p className='upload-photo-card-time'>{photo.parsedTime || photo.time}</p>
      <hr />
      <span className='blue-text'>(Scanned from photo EXIF data)</span>
      {/* <p className='upload-photo-timezone'>{timezone.abbreviation}  {timezone.name}</p> */}
    </div>
  </div>
);

export default UploadPhotoCard;
