import classNames from 'classnames';
import MagnifyGray from 'images/icons/magnify-gray.svg';
import React from 'react';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import SearchArrow from './../icons/search_arrow';

class SearchBarLocation extends React.Component {

  locationStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#555555',
    }),
    container: (provided, state) => ({
      ...provided,
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '3rem',
      boxShadow: 'none',
      borderColor: '#AEAEAE',
      color: '#555555',
      '&:focus': {
        borderColor: '#AEAEAE',
      },
      '&:hover': {
        borderColor: '#AEAEAE',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
      fontWeight: '700',
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundImage: `url('${MagnifyGray}')`,
      backgroundRepeat: 'no-repeat',
      textAlign: 'left',
    }),
  };

  constructor(props) {
    super(props);

    this.searchButton = React.createRef();
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  async handleSelectLocation(location) {
    if (location) {
      await this.props.setSearchLocation(location.value);
      this.props.receiveSearchString();
      this.searchButton.current.focus();

      this.handleSearch();
    }
  }

  handleSearch() {
    if (!this.props.searchLocation.value) {
      this.props.alert.show(`Please select a location to search`);
      return;
    }

    if (this.props.location.pathname !== '/search') {
      this.props.history.push('/search?' + this.props.searchString);
    } else {
      this.props.searchPhotos();
    }
  }

  render() {
    const {
      searchLocation,
      locations,
      newSearchParams
    } = this.props;

    const searchBarButtonClasses = classNames('search-bar-go', {
      'new-search-params': searchLocation.value && newSearchParams,
    });

    return (
      <div className='search-bar-location'>
        <Select
          styles={this.locationStyles}
          placeholder='Where were you...'
          value={searchLocation.value ? searchLocation : null}
          className='search-location-select'
          classNamePrefix='search-location-select'
          onChange={this.handleSelectLocation}
          options={locations}
        />
        <button
          ref={this.searchButton}
          className={searchBarButtonClasses}
          onClick={this.handleSearch}
        >
          <SearchArrow className='search-bar-arrow' />
        </button>
        <button
          onClick={this.handleSearch}
          disabled={!this.props.searchLocation.value}
          className='mobile-search-button'
        >
          Search
        </button>
      </div>
    )
  }
};


export default withRouter(withAlert()(SearchBarLocation));
