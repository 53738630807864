import { ReactElement, useCallback, useState } from 'react';
import { Tooltip } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { createLocation } from '../../actions/location_actions';
import Cartography from '../cartography/cartography';
import { useLocations, useNewMarker } from '../cartography/hooks';
import MarkerLayer from '../cartography/marker_layer';
import WPMarker from '../icons/wp_marker';
import { Button, Collection, Heading, Input } from '../library';

interface AddLocationProps {
  handleAfterSubmit: any;
  handleCancelClick: any;
}
export default function AddLocation({
  handleAfterSubmit,
  handleCancelClick,
}): ReactElement {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [nameInputValue, setNameInputValue] = useState<string | null>(null);
  const { bounds, locations } = useLocations();
  const {
    newMarkerEventHandlers,
    newMarkerMapEventHandlers,
    newMarkerName,
    newMarkerPosition,
    setNewMarkerName,
    setNewMarkerPosition,
  } = useNewMarker();

  const handleMarkerNameUpdate = useCallback(() => {
    setNewMarkerName(nameInputValue);
    setNameInputValue(null);
  }, [nameInputValue, setNewMarkerName]);

  const handleMarkerFormSubmit = () => {
    if (newMarkerPosition && newMarkerName) {
      setIsSubmitting(true);
      dispatch(
        createLocation({
          lat: newMarkerPosition.lat,
          lng: newMarkerPosition.lng,
          name: newMarkerName,
        })
      );
      setNameInputValue(null);
      setNewMarkerName(null);
      setNewMarkerPosition(null);
      setIsSubmitting(false);

      if (handleAfterSubmit) {
        handleAfterSubmit();
      }
    }
  };

  return (
    <div className="add-location">
      <div className="add-location__content">
        <div className="add-location__form-container">
          <Heading level={1}>Add Location</Heading>
          <form className="marker-form">
            <Input
              disabled={isSubmitting}
              name="name"
              onChange={(e) => setNameInputValue(e.target.value)}
              placeholder={
                newMarkerName
                  ? newMarkerName
                  : 'Enter the name of this location'
              }
              value={nameInputValue ? nameInputValue : ''}
            >
              Name this location
            </Input>
            <div className="marker-form__actions">
              {newMarkerPosition && (
                <span>{`${newMarkerPosition.lat}, ${newMarkerPosition.lng}`}</span>
              )}
              <Button
                disabled={isSubmitting}
                onClick={() => handleMarkerNameUpdate()}
              >
                Apply
              </Button>
            </div>
          </form>
        </div>
        <div className="add-location__map-container">
          <span>Double click on map to add pin</span>
          <Cartography
            bounds={bounds}
            className="add-location__map"
            doubleClickZoom={false}
          >
            <MarkerLayer
              mapEventHandlers={!isSubmitting ? newMarkerMapEventHandlers : {}}
            >
              {({ activeMarkerId, setActiveMarkerId }) => (
                <>
                  <Collection items={locations} keyName="id">
                    {({ id, lat, lng, name }) => (
                      <WPMarker
                        eventHandlers={{
                          click: () => setActiveMarkerId(id),
                        }}
                        position={{ lat, lng }}
                        selected={!!activeMarkerId && activeMarkerId === id}
                      >
                        <Tooltip direction="top">{name}</Tooltip>
                      </WPMarker>
                    )}
                  </Collection>
                  {newMarkerPosition && (
                    <WPMarker
                      eventHandlers={{
                        ...newMarkerEventHandlers,
                        mousedown: () => setActiveMarkerId(null),
                      }}
                      isEditing={!isSubmitting}
                      position={newMarkerPosition}
                      selected={activeMarkerId === null}
                    >
                      {newMarkerName && (
                        <Tooltip direction="top" permanent>
                          <div>{newMarkerName}</div>
                        </Tooltip>
                      )}
                    </WPMarker>
                  )}
                </>
              )}
            </MarkerLayer>
          </Cartography>
        </div>
      </div>
      <div className="add-location__actions">
        <Button
          disabled={!newMarkerPosition || !newMarkerName || isSubmitting}
          onClick={() => handleMarkerFormSubmit()}
        >
          Add Location
        </Button>
        <Button
          onClick={handleCancelClick}
          className="cancel">Cancel</Button>
      </div>
    </div>
  );
}
