import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import ContactUsIcon from '../icons/contact_us_icon';
import { Link } from 'react-router-dom';

const ContactUsSection = () => {
  return (
    <div className='col text-center'>
      <Card>
        <ContactUsIcon />
        <CardBody>
          <CardTitle>Contact Us</CardTitle>
          <CardText>
            Still have questions?
            <br />
            Send us a message!
          </CardText>
          <Link to='/contact'><Button variant="primary">Send Message</Button></Link>
        </CardBody>
      </Card>
    </div>
  )
}

export default ContactUsSection;
