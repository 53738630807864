import React from 'react';

const PrivacyPolicyLeftNavBar = ({ text }) => {
  return (
    <ul id='privacyPolicyNavLinks'>
      <li><a id="personalInfoCollectionLink" href="#personalInfoCollection">Personal Information Collection</a></li>
      <li><a id="personalInfoUseLink" href="#personalInfoUse">Personal Information Use</a></li>
      <li><a id="trackingUseBehaviorLink" href="#trackingUseBehavior">Tracking Use Behavior</a></li>
      <li><a id="autoCollectedInfoLink" href="#autoCollectedInfo">Automatically Collected Information</a></li>
      <li><a id="useOfCookiesLink" href="#useOfCookies">Use of Cookies</a></li>
      <li><a href="#security">Security of your Information</a></li>
      <li><a href="#deletion">Right to Deletion</a></li>
      <li><a href="#children">Children Under Thirteen</a></li>
      <li><a href="#disconnecting">Disconnecting from WunderPics</a></li>
      <li><a href="#email">E-mail Communications</a></li>
      <li><a href="#external">External Data Storage Sites</a></li>
      <li><a href="#changes">Changes to this Statement</a></li>
      <li><a href="#contact">Contact Information</a></li>
    </ul>
  );
}

export default PrivacyPolicyLeftNavBar;
