import * as UserApiUtil from '../util/api/user_api_util';
// action types
export const RECEIVE_PHOTOGRAPHERS = 'RECIEVE_PHOTOGRAPHERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_USER = 'UPDATE_USER';

// action creators
export const receivePhotographers = (photographers) => ({
  type: RECEIVE_PHOTOGRAPHERS,
  photographers,
});

// thunk action creators
export const fetchPhotographers = () => (dispatch) => (
  UserApiUtil.fetchPhotographers()
    .then((photographers) => dispatch(receivePhotographers(photographers)))
);

export const updateUser = (user) => (dispatch) => (
  UserApiUtil.updateUser(user)
);
