import React from 'react';

const employmentData = {
    rows: [
        {
            title: "Employment Relationship",
            content: "Users of the Site agree that their use of the site does not create a partnership, agency, joint venture or any employee-employer relationship between them and WunderPics. Users are responsible for all state, local, federal or other taxes that you are obligated to pay in connection with your use of the Site."
        }
    ]
}

export default employmentData;