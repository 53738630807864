import { ChangeEventHandler, ReactElement, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { Button, Heading, Input, Panel } from '../library';
import BlankUserImage from 'images/blank-user.png';
import { getEditUser, updateUser, updateUserPassword } from '../../util/api/user_api_util';

export default function Account(): ReactElement {
  const alert = useAlert();
  const socialRegex = /^[a-zA-Z0-9_.]*$/;
  const [updateMessages, setUpdateMessages] = useState({
    socialMediaMessage: '',
    bioMessage: '',
    passwordMessage: '',
    generalInfoMessage: '',
  });
  const [generalInfoInputs, setGeneralInfoInputs] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  });
  const [changePasswordInputs, setChangePasswordInputs] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [biographyTextarea, setBiographyTextarea] = useState('');
  const [socialMediaInputs, setSocialMediaInputs] = useState({
    instagram: '',
    website: '',
    facebook: '',
  });

  const handleGeneralInfoInputChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setGeneralInfoInputs({
      ...generalInfoInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePasswordInputChange: ChangeEventHandler<HTMLInputElement> =
  (e) => {
    setChangePasswordInputs({
      ...changePasswordInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleBiographyTextareaChange: ChangeEventHandler<HTMLTextAreaElement> =
  (e) => {
    setBiographyTextarea(e.target.value);
  };

  const handleGeneralInfoFormSubmit = () => {
    const formData = new FormData();
    formData.append('user[username]', generalInfoInputs.username);
    formData.append('user[given_name]', generalInfoInputs.firstName);
    formData.append('user[family_name]', generalInfoInputs.lastName);
    formData.append('user[email]', generalInfoInputs.email);
    updateUser(formData)
      .then((user) => {
        alert.success('General Information updated successfully!');
      })
      .catch((resp) => {
        resp.responseJSON.errors.map(err => alert.error(err));
      });
  };

  const handleChangePasswordFormSubmit = () => {
    const formData = new FormData();
    formData.append('user[password]', changePasswordInputs.newPassword);
    formData.append('user[password_confirmation]', changePasswordInputs.confirmPassword);
    formData.append('user[current_password]', changePasswordInputs.currentPassword);

    updateUserPassword(formData).
      then(resp => {
        if (!resp.updated) {
          resp.errors.map(err => alert.error(err))
        } else {
          alert.success('Password updated succesfully!');
          setChangePasswordInputs({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          });
        }
      });
  }

  const handleBiographyFormSubmit = () => {
    const formData = new FormData();
    formData.append('user[description]', biographyTextarea);
    updateUser(formData)
      .then(() => {
        alert.success('Bio updated successfully!');
      })
  };

  const handleSocialMediaInputChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (socialRegex.test(e.target.value)) {
      setSocialMediaInputs({
        ...socialMediaInputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSocialMediaFormSubmit = () => {
    const formData = new FormData();
    formData.append('user[instagram]', socialMediaInputs.instagram);
    formData.append('user[facebook]', socialMediaInputs.facebook);

    updateUser(formData)
      .then(() => {
        alert.success('Social media links updated successfully!');
      });
  };

  const handleDeactivateAccount = () => {
    alert.show('Please email tony@wunderpics.com requesting for account deactivation and we will respond within 24-48 hours.', {timeout:30000})
  };

  const handleDeleteAccount = () => {
    alert.show('Please email tony@wunderpics.com requesting for account deletion and we will respond within 24-48 hours.', {timeout:30000})
  };

  useEffect(() => {
    getEditUser()
      .then(user => {
        updateAllInputs(user);
      })
  }, []);

  const updateAllInputs = (user) => {
    setGeneralInfoInputs({
      firstName: user?.givenName,
      lastName: user?.familyName,
      username: user?.username,
      email: user?.email,
    });
    setSocialMediaInputs({
      instagram: user?.instagram,
      facebook: user?.facebook,
      website: user?.website,
    });
    setBiographyTextarea(user?.description);
  }

  return (
    <Panel className="account">
      <Heading className="account-heading" level={1}>
        Account
      </Heading>
      <Panel className="photo" semantic element="section">
        <img alt="User Photo" src={BlankUserImage} />
        <div
          className="photo__actions"
          onClick={() => alert.show('Coming soon!')}
        >
          <Button>Upload New Image</Button>
          <a href="#">Remove Profile Photo</a>
        </div>
      </Panel>
      <Panel className="section-container" semantic element="section">
        <Panel.Header>
          <Heading className="account-heading" level={2}>
            General Information
          </Heading>
        </Panel.Header>
        <Panel.Body>
          <form>
            <div className="adjacent-inputs">
              <Input
                name="firstName"
                onChange={handleGeneralInfoInputChange}
                placeholder="First Name"
                value={generalInfoInputs.firstName}
              >
                First Name
              </Input>
              <Input
                name="lastName"
                onChange={handleGeneralInfoInputChange}
                placeholder="Last Name"
                value={generalInfoInputs.lastName}
              >
                Last Name
              </Input>
            </div>
            <Input
              name="username"
              onChange={handleGeneralInfoInputChange}
              placeholder="Username"
              value={generalInfoInputs.username}
            >
              Username
            </Input>
            <Input
              name="email"
              onChange={handleGeneralInfoInputChange}
              placeholder="Email"
              type="email"
              value={generalInfoInputs.email}
            >
              Email
            </Input>
          </form>
        </Panel.Body>
        <Panel.Footer>
          <Button onClick={handleGeneralInfoFormSubmit}>Save</Button>
        </Panel.Footer>
      </Panel>
      <Panel className="section-container" semantic element="section">
        <Panel.Header>
          <Heading className="account-heading" level={2}>
            Change Password
          </Heading>
        </Panel.Header>
        <Panel.Body>
          <form>
            <Input
              name="currentPassword"
              onChange={handleChangePasswordInputChange}
              placeholder="Current Password"
              type="password"
              value={changePasswordInputs.currentPassword}
            >
              Current Password (we need your current password to confirm your changes)
            </Input>
            <Input
              name="newPassword"
              onChange={handleChangePasswordInputChange}
              placeholder="Password"
              type="password"
              value={changePasswordInputs.newPassword}
            >
              New Password
            </Input>
            <Input
              name="confirmPassword"
              onChange={handleChangePasswordInputChange}
              placeholder="Confirm Password"
              type="password"
              value={changePasswordInputs.confirmPassword}
            >
              Confirm Password
            </Input>
          </form>
        </Panel.Body>
        <Panel.Footer>
          <Button onClick={handleChangePasswordFormSubmit}>Save</Button>
        </Panel.Footer>
      </Panel>
      <Panel className="section-container" semantic element="section">
        <Panel.Header>
          <Heading className="account-heading" level={2}>
            Edit Bio
          </Heading>
        </Panel.Header>
        <Panel.Body>
          <form>
            <div className="input">
              <label className="input__label" htmlFor="biography">
                Bio
              </label>
              <textarea
                className="input__control"
                name="biography"
                onChange={handleBiographyTextareaChange}
                placeholder="Write a bit about yourself!"
                rows={5}
                value={biographyTextarea}
              />
            </div>
          </form>
        </Panel.Body>
        <Panel.Footer>
          <Button onClick={handleBiographyFormSubmit}>Save</Button>
        </Panel.Footer>
      </Panel>
      <Panel className="section-container" semantic element="section">
        <Panel.Header>
          <Heading className="account-heading" level={2}>
            Link Social Media
          </Heading>
        </Panel.Header>
        <Panel.Body>
          <form>
            <Input
              className="input--row"
              name="instagram"
              onChange={handleSocialMediaInputChange}
              placeholder="Instagram username"
              value={socialMediaInputs.instagram}
            >
              Instagram
            </Input>
            <Input
              className="input--row"
              name="facebook"
              onChange={handleSocialMediaInputChange}
              placeholder="Facebook username"
              value={socialMediaInputs.facebook}
            >
              Facebook
            </Input>
          </form>
        </Panel.Body>
        <Panel.Footer>
          <Button onClick={handleSocialMediaFormSubmit}>Save</Button>
        </Panel.Footer>
      </Panel>
      <Panel className="section-container" semantic element="section">
        <Panel className="disengagement">
          <Panel.Header>
            <Heading className="account-heading" level={2}>
              Deactivate Account
            </Heading>
          </Panel.Header>
          <Panel.Body>
            <p>
              Your account will be deactivated from use. User will be unable to
              view or buy your photos.
            </p>
          </Panel.Body>
          <Panel.Footer>
            <a onClick={handleDeactivateAccount}>Deactivate my account</a>
          </Panel.Footer>
        </Panel>
        <Panel className="disengagement">
          <Panel.Header>
            <Heading className="account-heading" level={2}>
              Delete Account
            </Heading>
          </Panel.Header>
          <Panel.Body>
            <p>This will delete your account and remove all of your photos.</p>
          </Panel.Body>
          <Panel.Footer>
            <a onClick={handleDeleteAccount}>Delete my account</a>
          </Panel.Footer>
        </Panel>
      </Panel>
    </Panel>
  );
}
