import React from 'react';

const PersonalInfoUseData = {
  rows: [
    {
      title: "Personal Information Use",
      content: <p>WunderPics Inc. collects and uses your personal information to operate and deliver the services you have requested. 
      <br />
      <br />
      WunderPics Inc. may also use your personally identifiable information to inform you of other products or services available from WunderPics Inc. and its affiliates. 
      <br />
      <br />
      Sharing Information with Third Parties 
      WunderPics Inc. does not sell, rent or lease its customer lists to third parties. 
      <br />
      <br />
      WunderPics Inc. may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to WunderPics Inc., and they are required to maintain the confidentiality of your information. 
      <br />
      <br />
      WunderPics Inc. may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on WunderPics Inc. or the site; (b) protect and defend the rights or property of WunderPics Inc.; and/or (c) act under exigent circumstances to protect the personal safety of users of WunderPics Inc., or the public. 
      </p>
    }]
}

export default PersonalInfoUseData;
