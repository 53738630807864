import React from 'react';

const eCommunicationsData = {
    rows: [
        {
            title: "Electronic Communications",
            content: "Visiting WunderPics.com or sending emails to WunderPics, Inc. constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing."
        }
    ]
}

export default eCommunicationsData;