export const runFollowedSearchJob = (batchId: number): JQuery.jqXHR => (
  $.ajax({
    method: 'GET',
    url: '/api/jobs/followed_search',
    data: {
      batchId,
    }
  })
);

export const runWithdrawalJob = (): JQuery.jqXHR => (
  $.ajax({
    method: 'GET',
    url: '/api/jobs/user_withdrawal'
  })
);
