import { connect } from 'react-redux';
import { receiveFocusedPhoto } from '../../actions/ui_actions';
import StreamPhoto from './stream_photo';

const mapStateToProps = (state) => ({
  focusedPhoto: state.ui.focusedPhoto,
  cartIds: state.ui.cartIds,
});

const mapDispatchToProps = (dispatch) => ({
  receiveFocusedPhoto: (photo) => dispatch(receiveFocusedPhoto(photo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StreamPhoto);
