import { useEffect } from 'react';

type MouseEventType =
  | 'auxclick'
  | 'click'
  | 'contextmenu'
  | 'dblclick'
  | 'mousedown'
  | 'mouseenter'
  | 'mouseleave'
  | 'mousemove'
  | 'mouseout'
  | 'mouseover'
  | 'mouseup';
type MouseEventHandlerFn = (event: MouseEvent) => void;
type MouseEventHandlerFnMap = Record<MouseEventType, MouseEventHandlerFn>;

export default function useMouseEvents(
  handlerMap: Partial<MouseEventHandlerFnMap>
): void {
  useEffect(() => {
    let handler: MouseEventType;

    for (handler in handlerMap) {
      window.addEventListener(handler, (e) => handlerMap[handler]?.(e));
    }

    return () => {
      for (handler in handlerMap) {
        window.removeEventListener(handler, (e) => handlerMap[handler]?.(e));
      }
    };
  }, [handlerMap]);
}
