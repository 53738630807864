import {
  RECEIVE_USER_ON_UPLOADING,
  RECEIVE_USER_LEAVING_UPLOAD
} from '../actions/ui_actions';

const initialState = {
  onUploadPage: false,
}

const uploadingReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_USER_ON_UPLOADING: {
      return Object.assign({}, state, { onUploadPage: true });
    }
    case RECEIVE_USER_LEAVING_UPLOAD: {
      return Object.assign({}, state, { onUploadPage: false });
    }
  }
  return state;
}


export default uploadingReducer;
