import qs from 'qs';
import * as SearchApiUtil from '../util/api/search_api_util';

// action types
export const RECEIVE_SEARCH_RESULT_PHOTOS = 'ui/receiveSearchResultPhotos';
export const RECEIVE_SEARCH_RESULT_TIMES = 'ui/receiveSearchResultTimes';

// export const RECEIVE_SEARCHING = 'ui/searchParams/receiveSearching';
export const RECEIVE_SEARCH_LOCATION = 'ui/searchParams/receiveSearchLocation';
export const RECEIVE_CLEAR_SEARCH_LOCATION = 'ui/searchParams/receiveClearSearchLocation';
export const RECEIVE_SEARCH_DATE = 'ui/searchParams/receiveSearchDate';
export const RECEIVE_CLEAR_SEARCH_DATE = 'ui/searchParams/receiveClearSearchDate';
export const RECEIVE_SEARCH_TIME = 'ui/searchParams/receiveSearchTime';
export const RECEIVE_CLEAR_SEARCH_TIME = 'ui/searchParams/receiveClearSearchTime';
export const RECEIVE_SEARCH_TIME_END = 'ui/searchParams/receiveSearchTimeEnd';
export const RECEIVE_CLEAR_SEARCH_TIME_END = 'ui/searchParams/receiveClearSearchTimeEnd';
export const RECEIVE_SEARCH_ACTIVITY = 'ui/searchParams/receiveSearchActivity';
export const RECEIVE_CLEAR_SEARCH_ACTIVITY = 'ui/searchParams/receiveClearSearchActivity';
export const RECEIVE_SEARCH_TAGS = 'ui/searchParams/receiveSearchTags';
export const RECEIVE_SEARCH_PHOTOGRAPHER = 'ui/searchParams/receiveSearchPhotographer';
export const RECEIVE_CLEAR_SEARCH_TAGS = 'ui/searchParams/receiveClearSearchTags';
export const RECEIVE_CLEAR_SEARCH_PHOTOGRAPHER = 'ui/searchParams/receiveClearSearchPhotographer';
export const RECEIVE_SEARCH_STRING = 'ui/searchParams/receiveSearchString';
export const CLEAR_ALL_SEARCH_PARAMS = 'ui/searchParams/receiveClearAllSearchParams';

// action creators
export const receiveSearchResultPhotos = (photos) => ({
  type: RECEIVE_SEARCH_RESULT_PHOTOS,
  photos,
});

export const receiveSearchResultTimes = (times) => ({
  type: RECEIVE_SEARCH_RESULT_TIMES,
  times,
});

export const receiveSearchLocation = (location) => ({
  type: RECEIVE_SEARCH_LOCATION,
  label: location.name,
  id: location.id,
  lat: location.lat,
  lng: location.lng,
});

export const receiveClearSearchLocation = () => ({
  type: RECEIVE_CLEAR_SEARCH_LOCATION,
});

/**
 * Set the Date activity. Label is the search date.
 *
 * @param {Object} date { value: Date, label: String }
 * @returns
 */
export const receiveSearchDate = (date) => ({
  type: RECEIVE_SEARCH_DATE,
  date,
});

export const receiveClearSearchDate = () => ({
  type: RECEIVE_CLEAR_SEARCH_DATE,
});

export const receiveSearchTime = (time) => ({
  type: RECEIVE_SEARCH_TIME,
  time,
});

export const receiveClearSearchTime = () => ({
  type: RECEIVE_CLEAR_SEARCH_TIME,
});

export const receiveSearchTimeEnd = (timeEnd) => ({
  type: RECEIVE_SEARCH_TIME_END,
  timeEnd,
});

export const receiveClearSearchTimeEnd = () => ({
  type: RECEIVE_CLEAR_SEARCH_TIME_END,
});

/**
 * Set the search Activity
 *
 * @param {Object} activity { label, value }
 * @returns
 */
export const receiveSearchActivity = (activity) => ({
  type: RECEIVE_SEARCH_ACTIVITY,
  activity,
});

export const receiveClearSearchActivity = () => ({
  type: RECEIVE_CLEAR_SEARCH_ACTIVITY,
});

export const receiveSearchTags = (tags) => ({
  type: RECEIVE_SEARCH_TAGS,
  tags,
});

export const receiveClearSearchTags = () => ({
  type: RECEIVE_CLEAR_SEARCH_TAGS,
});

export const receiveSearchPhotographer = (photographer) => ({
  type: RECEIVE_SEARCH_PHOTOGRAPHER,
  photographer,
});

export const receiveClearSearchPhotographer = () => ({
  type: RECEIVE_CLEAR_SEARCH_PHOTOGRAPHER,
});

export const receiveSearchString = () => ({
  type: RECEIVE_SEARCH_STRING,
});

export const clearAllSearchParams = () => ({
  type: CLEAR_ALL_SEARCH_PARAMS,
});

// thunk action creators
export const searchPhotos = () => (dispatch, getState) => {
  const searchParams = buildSearchParams(getState().ui.searchParams);
  if (searchParams.locationId) {
    return SearchApiUtil.searchPhotos(searchParams)
      .then((data) => {
        dispatch(receiveSearchResultPhotos(data.results));
        dispatch(receiveSearchResultTimes(data.times));
      })
      .then(() => dispatch(receiveSearchString()));
  } else {
    return Promise.resolve(false);
  }
};

/**
 * setSearch<Parameter> helpers
 *
 *  When receiving search parameters from query paramters, use these helpers to get
 *  the full entity from the Store
 */
export const setSearchActivity = (activityId) => (dispatch, getState) => {
  // TODO: Performance
  const activity = getState().entities.activities[activityId];
  const activityObj = { label: activity.name, value: activity.id };
  return new Promise((resolve) => {
    dispatch(receiveSearchActivity(activityObj));
    resolve(true);
  });
};


export const setSearchLocation = (locationId) => (dispatch, getState) => {
  // TODO: Performance
  const location = getState().entities.locations.collection.filter(location => location.id === Number(locationId))[0];
  return new Promise((resolve, _) => {
    if (location) {
      dispatch(receiveSearchLocation(location));
      return resolve(true);
    }
  });
};

export const setSearchTags = (tagIds) => (dispatch, getState) => {
  const tagEntities = getState().entities.tags;
  const tags = tagIds.map((id) => {
    return { value: tagEntities[id].id, label: tagEntities[id].name }
  });
  return dispatch(receiveSearchTags(tags));
}

export const setSearchPhotographer = (photographerId) => (dispatch, getState) => {
  const photographer = getState().entities.users[photographerId];
  dispatch(receiveSearchPhotographer({ value: photographer.id, label: photographer.username }));
}

// search actions utilities
const buildSearchParams = (stateSearchParams) => {
  let params = {};

  // ID of Location, ex: 1
  if (stateSearchParams.location && stateSearchParams.location.value) {
    params.locationId = stateSearchParams.location.value;
  }

  // Ex: 2021-4-23
  if (stateSearchParams.date && stateSearchParams.date.value) {
    // We are using the `label` to search, because `value` is Date object
    params.date = stateSearchParams.date.label;
  }

  // Ex: 10:00AM
  if (stateSearchParams.time && stateSearchParams.date.value && stateSearchParams.time.value) {
    params.time = stateSearchParams.time.label;
  }

  // Ex: 10:00AM
  if (stateSearchParams.timeEnd && stateSearchParams.date.value && stateSearchParams.timeEnd.value) {
    params.timeEnd = stateSearchParams.timeEnd.label;
  }

  // ID of Activity, ex: 10
  if (stateSearchParams.activity && stateSearchParams.activity.value) {
    params.activityId = stateSearchParams.activity.value;
  }

  // List of Tag Objects, ex: [{label: 'fun', value: 12}]
  if (stateSearchParams.tags) {
    params.tags = stateSearchParams.tags.map(tag => tag.value);
  }

  // ID of Photographer, ex: 1
  if (stateSearchParams.photographer && stateSearchParams.photographer.value) {
    params.photographerId = stateSearchParams.photographer.value;
  }

  return params;
}

export const buildSearchParamsQueryString = (stateSearchParams) => {
  return qs.stringify(buildSearchParams(stateSearchParams));
}
