import React from 'react';

const thirdPartyAccountsData = {
    rows: [
        {
            title: "Third Party Accounts",
            content: "You will be able to connect your WunderPics, Inc. account to third party accounts. By connecting your WunderPics, Inc. account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature."
        }
    ]
}

export default thirdPartyAccountsData;