import {
  LocationAction,
  RECEIVE_LOCATIONS_WITH_PHOTOS,
} from '../actions/location_actions';
import { PhotoLocation } from '../types';

type LocationsState = {
  collection: PhotoLocation[];
  collectionWithPhotos: PhotoLocation[];
  selection: PhotoLocation | null;
};

const initialLocationsState: LocationsState = {
  collection: [],
  collectionWithPhotos: [],
  selection: null,
};

export default function locationsReducer(
  state = initialLocationsState,
  action: LocationAction
): LocationsState {
  switch (action.type) {
    case 'ADD_LOCATION':
      return { ...state, collection: [...state.collection, action.location] };
    case 'REMOVE_LOCATION':
      return {
        ...state,
        collection: state.collection.filter(
          (location) => location.id !== action.locationId
        ),
      };
    case 'SET_LOCATIONS':
      return { ...state, collection: action.locations };
    case 'SET_LOCATION_SELECTION':
      return { ...state, selection: action.location };
    case 'UNSET_LOCATION_SELECTION':
      return { ...state, selection: initialLocationsState.selection };
    case RECEIVE_LOCATIONS_WITH_PHOTOS: {
      return { ...state, collectionWithPhotos: action.locations}
    }
    default:
      return state;
  }
}
