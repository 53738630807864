import SetPrice1 from 'images/uploader_guide/set-price-1.png';

const SetPriceData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>Enter the price that you want to sell each photo for and select “Apply”</li>
        <li>Select “Set Price” to proceed</li>
        <li>You will have an opportunity to change the price of individual photos later in the Upload Editor</li>
        <img src={SetPrice1} alt='Set Price Img1' />
      </ul>
    </div>
  )
}

export default SetPriceData