import { ComponentPropsWithRef, forwardRef, ReactElement } from 'react';

export type SemanticContainer =
  | 'article'
  | 'aside'
  | 'footer'
  | 'header'
  | 'menu'
  | 'nav'
  | 'section';

type ConditionalContainerProps = ComponentPropsWithRef<SemanticContainer> & {
  condition: boolean;
  element: SemanticContainer;
};

export default forwardRef<
  HTMLElementTagNameMap[SemanticContainer] | HTMLDivElement,
  ConditionalContainerProps
>(function ConditionalContainer(
  { condition = false, element, children, ...props }: ConditionalContainerProps,
  ref
): ReactElement {
  const ConditionalHTMLElement = (
    condition ? element : 'div'
  ) as SemanticContainer;

  return (
    <ConditionalHTMLElement ref={ref} {...props}>
      {children}
    </ConditionalHTMLElement>
  );
});
