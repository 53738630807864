import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { fetchActivities } from '../actions/activity_actions';
import { fetchCart } from '../actions/cart_actions';
import { fetchLocations, fetchLocationsWithPhotos } from '../actions/location_actions';
import { fetchTags } from '../actions/tag_actions';
import { fetchPhotographers } from '../actions/user_actions';
import App from './app';
import AlertTemplate from './generic/alert_template';
import WpLoading from './generic/wp_loading';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    }

    this.initApp = this.initApp.bind(this);
    this.title = 'WunderPics - A revolutionary way to buy and sell photos';
  }

  // Initialize Application Data
  componentDidMount() {
    this.initApp();
  }

  initApp() {
    // We need to call these in app.jsx after we have a user
    // TODO: Performance and single load for linked search
    Promise.all(
      [
        this.props.store.dispatch(fetchLocations()),
        this.props.store.dispatch(fetchLocationsWithPhotos()),
        this.props.store.dispatch(fetchActivities()),
        this.props.store.dispatch(fetchTags()),
        this.props.store.dispatch(fetchPhotographers()),
        this.props.store.dispatch(fetchCart())
      ]
    )
    .then(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <>
        <Helmet
          titleTemplate="WunderPics - %s"
          defaultTitle="WunderPics - A revolutionary way to buy and sell photos"
        >
          <meta property='og:title' content={this.title} />
          <meta property='twitter:title' content={this.title} />
          <meta name='title' content={this.title} />
        </Helmet>
        <Provider store={this.props.store}>
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <AlertProvider
                template={AlertTemplate}
                position={positions.TOP_CENTER}
                timeout={10000}
              >
                {this.state.loading ? <WpLoading /> : <App />}
              </AlertProvider>
            </Elements>
          </BrowserRouter>
        </Provider>
      </>
    )
  }
}

export default Root;
