import Faq from 'react-faq-component';
import Footer from '../footer';
import SEO from '../generic/seo';
import AutoCollectedInfoData from './auto_collected_info_data.jsx';
import PersonalInfoCollectionData from './personal_info_collection_data.jsx';
import PersonalInfoUseData from './personal_info_use_data.jsx';
import PrivacyPolicyBanner from './privacy_policy_banner.jsx';
import PrivacyPolicyData from './privacy_policy_data.jsx';
import PrivacyPolicyLeftNavBar from './privacy_policy_left_nav_bar.jsx';
import TrackingUseBehaviorData from './tracking_use_behavior_data.jsx';
import UseOfCookiesData from './use_of_cookies_data.jsx';
import ScrollToTop from '../scroll_to_top';

const styles = {
  titleTextColor: "black",
  titleTextSize: '25px',
  rowTitleColor: 'black',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '15px',
  rowContentPaddingBottom: '15px',
  rowContentColor: 'grey',
}

const PrivacyPolicy = (props) => {
  return (
    <>
      <ScrollToTop />
      <SEO title='Privacy Policy' />
      <div>
        <PrivacyPolicyBanner text="Privacy Policy" />
        <div className='page-container'>
          <div className='row no-gutters'>
            <div className='col-lg-3' id='privacypolicy'>
              <div className="sticky-top">
                <PrivacyPolicyLeftNavBar />
              </div>
            </div>
            <div className='col-lg-9'>
              <dl>
                <div>
                  <br />
                  <div id="personalInfoCollection" >
                    <Faq data={PersonalInfoCollectionData} styles={styles} />
                  </div>
                  <div id="personalInfoUse">
                    <Faq data={PersonalInfoUseData} styles={styles} />
                  </div>
                  <div id="trackingUseBehavior">
                    <Faq data={TrackingUseBehaviorData} styles={styles} />
                  </div>
                  <div id="autoCollectedInfo">
                    <Faq data={AutoCollectedInfoData} styles={styles} />
                  </div>
                  <div id="useOfCookies">
                    <Faq data={UseOfCookiesData} styles={styles} />
                  </div>
                  <div id="security">
                    <Faq data={{
                      rows: [
                        {
                          title: "Security of your Personal Information",
                          content: <div>
                            <p>WunderPics Inc. secures your personal information from unauthorized access, use, or disclosure. WunderPics Inc. uses the following methods for this purpose: </p>
                            <p><strong> -	SSL Protocol</strong></p>
                            <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                            <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="deletion">
                    <Faq data={{
                      rows: [
                        {
                          title: "Right to Deletion",
                          content: <div>
                            <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
                            <ul>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Delete your personal information from our records; and</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Direct any service providers to delete your personal information from their records.</li>
                            </ul>
                            <br />
                            <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: </p>
                            <ul>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Debug to identify and repair errors that impair existing intended functionality</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Comply with the California Electronic Communications Privacy Act</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Comply with an existing legal obligation; or</li>
                              <li style={{listStyleType: 'disc', marginLeft: '1rem'}}>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. </li>
                            </ul>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="children">
                    <Faq data={{
                      rows: [
                        {
                          title: "Children Under Thirteen",
                          content: <div>
                            <p>WunderPics Inc. does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="disconnecting">
                    <Faq data={{
                      rows: [
                        {
                          title: "Disconnecting your WunderPics Inc. Account from Third Party Websites",
                          content: <div>
                            <p>You will be able to connect your WunderPics Inc. account to third party accounts. BY CONNECTING YOUR WUNDERPICS INC. ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users can create a WunderPics account through their accounts with third-party sites such as Facebook and Google. If they would like to disconnect access to these accounts they will need to do so through these said accounts.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="email">
                    <Faq data={{
                      rows: [
                        {
                          title: "E-mail Communications",
                          content: <div>
                            <p>From time to time, WunderPics Inc. may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from WunderPics Inc. or click on a link therein.</p>
                            <p>If you would like to stop receiving marketing or promotional communications via email from WunderPics Inc., you may opt out of such communications by Customers may unsubscribe from emails by clicking on the UNSUBSCRIBE button.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="external">
                    <Faq data={{
                      rows: [
                        {
                          title: "External Data Storage Sites",
                          content: <div>
                            <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="changes">
                    <Faq data={{
                      rows: [
                        {
                          title: "Changes to this Statement",
                          content: <div>
                            <p>WunderPics Inc. reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                  <div id="contact">
                    <Faq data={{
                      rows: [
                        {
                          title: "Contact Information",
                          content: <div>
                            <p>WunderPics Inc. welcomes your questions or comments regarding this Statement of Privacy. If you believe that WunderPics Inc. has not adhered to this Statement, please contact WunderPics Inc. at:</p>
                            <p>WunderPics Inc.</p>
                            <p>Email Address: tony@wunderpics.com</p>
                            <p>Effective as July 27, 2021</p>
                          </div>
                        }
                      ]
                    }} styles={styles} />
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default PrivacyPolicy;
