import { ComponentProps, ReactElement } from 'react';

interface CollectionProps<ItemType> extends ComponentProps<'ul'> {
  items: ItemType[];
  keyName?: keyof ItemType extends string ? keyof ItemType : never;
  className?: string;
  children: (item: ItemType) => ReactElement;
}

export default function Collection<Item>({
  className,
  items,
  keyName,
  children,
  ...props
}: CollectionProps<Item>): ReactElement {
  return (
    <ul className={className} {...props}>
      {items.map((item, index) => (
        <li
          key={keyName ? JSON.stringify(item, [`${keyName}`]) : index}
          className={className ? `${className}__item` : ''}
        >
          {children(item)}
        </li>
      ))}
    </ul>
  );
}
