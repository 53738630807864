import 'leaflet/dist/leaflet.css';
import { MapConsumer, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import GeosearchLayer from '../cartography/geosearch_layer';
import { wpMarkerIcon, wpMarkerIconSelected } from '../icons/wp_marker';


const GenericMap = ({
  addingLocation = false,
  lat = 33.6554414727159,
  lng = -118.003168702126,
  zoom = 13,
  height = '100%',
  locations = [],
  centerLocation = { lat: 33.6554414727159, lng: -118.003168702126 },
  setCenterLocation = null,
  handleSelectLocation = null,
  searchPhotos = null,
  doubleClickZoom = false,
  children,
}) => {

  const center = (centerLocation.value || centerLocation.id) ? [centerLocation.lat, centerLocation.lng] : [lat, lng];

  const markers = locations.map((loc, i) => {
    const selected = (centerLocation.id === loc.id);
    return (
      <Marker
        key={i}
        eventHandlers={{
          click: () => {
            if (setCenterLocation)
              setCenterLocation(loc.id).then(
                () => {
                  if (searchPhotos)
                    searchPhotos()
                }
              );
            if (handleSelectLocation) {
              handleSelectLocation({label: loc.name, value: loc.id })
            }
          }
        }}
        icon={selected ? wpMarkerIconSelected : wpMarkerIcon}
        position={[loc.lat, loc.lng]}
      >
        {!selected &&
          <Tooltip
            direction='top'
          >
            {loc.name}
          </Tooltip>}
        {selected &&
          <Tooltip
            direction='top'
            permanent
          >
            {loc.name}
          </Tooltip>}
      </Marker>
    );
  });

  return (
    <MapContainer
      doubleClickZoom={doubleClickZoom}
      style={{height, zIndex: '1'}}
      center={center}
      zoom={zoom}
    >
      {!addingLocation &&
        <MapConsumer>
          {(map) => {
            map.setView(center);
            return null;
          }}
        </MapConsumer>}
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
      {markers}
      <GeosearchLayer />
    </MapContainer>
  );
};

export default GenericMap;
