import React from 'react';

const acceptanceData = {
    rows: [
        {
            title: "Acceptance of Terms",
            content: "Welcome to WunderPics.com. The WunderPics.com website (the \"Site\") is comprised of various web pages operated by WunderPics, Inc. WunderPics.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the \"Terms\"). Your use of WunderPics.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference." 
            +
            "<br />"
            +
            "<br />"
            +
            "Wunderpics operates as an online marketplace that allows users to purchase and sell digital photos."
        }
    ]
}

export default acceptanceData;