import React from 'react';

const personalUseData = {
    rows: [
        {
            title: "Personal Use License",
            content: <div>All media uploaded for sale and/or purchased on WunderPics Inc. is licensed for personal use only. The following restrictions apply the media:<br /><br />
                <ul id='personalUseList'>
                    <li>May be used on personal social media, blogging, and websites.</li>
                    <li>May be used for personal printing, art, and gifts.</li>
                    <li>May NOT be used for commercial purposes such as advertisements, promoted social media posts, marketing material, or any means that generate monetary profit from the image.</li>
                    <li>May NOT be used to promote commercial services or advertise commercial products.</li>
                    <li>May NOT be used for manufactured merchandise or retail product packaging.</li>
                    <li>May NOT be used for out-of-home advertising, display advertising, print advertising, broadcast advertising, and multi-channel advertising.</li>
                    <li>May NOT be resold, relicensed, or sub-licensed.</li>
                    <li>All ownership and copyright remain exclusively to the original creator/owner.</li>
                    <li>This license does not expire.</li>
                </ul>
            </div>
        }
    ]
}

export default personalUseData;