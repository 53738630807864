import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';

import SearchBarContainer from './../generic/search_bar_container';
import ControlsContainer from './controls_container';
import SearchToolBarContainer from './search_tool_bar_container';
import WunderPicsLogo from './../icons/wunder_pics_logo';

class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.noSearchPages = [
      '/',
      '/upload',
      '/cart',
    ];

    this.toolBarPages = [
      '/search',
    ];

    this.bannerRef = React.createRef();
    this.noSearchPage = this.noSearchPage.bind(this);
    this.toolBarPage = this.toolBarPage.bind(this);
  }

  componentDidMount() {
    const height = this.bannerRef.current.offsetHeight;
    this.props.receiveBannerHeight(height);
  }

  componentDidUpdate() {
    // Hack so that react calculates height with CSS loaded
    setTimeout(() => {
      const height = this.bannerRef.current.offsetHeight;
      this.props.receiveBannerHeight(height);
    }, 1);
  }

  noSearchPage() {
    const { location: { pathname } } = this.props;

    return this.noSearchPages.includes(pathname) || pathname.includes('/dashboard');
  }

  toolBarPage() {
    return this.toolBarPages.includes(this.props.location.pathname);
  }

  render() {
    const {
      currentUser,
      searchLocation,
      uploading,
      location: { pathname },
    } = this.props;

    const landingOrDashboard = (pathname === '/' || pathname.includes('/dashboard'));

    const bannerClasses = classNames('banner', {
      'no-border': landingOrDashboard,
    });
    const searchClassNames = classNames('search', {
      'searching': pathname.includes('/search'),
    });

    return (
      <div ref={this.bannerRef} className={bannerClasses}>
        <div className={searchClassNames}>
          <div className='banner-inner'>
            <Link className='wp-logo' to='/'>
              <h1><WunderPicsLogo />WunderPics</h1>
            </Link>
            {this.noSearchPage() ? null : <SearchBarContainer />}
            <ControlsContainer />
          </div>
        </div>
        {this.toolBarPage() ? <SearchToolBarContainer /> : null}
      </div>
    );
  }
}

export default withRouter(Banner);
