import React from 'react';
import AddLocation from '../add_location/add_location';
import LocationsTable from './locations_table';

class LocationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      lat: this.props.lat,
      lng: this.props.lng,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  static defaultProps = {
    zoom: 11,
    lat: 33.6554414727159,
    lng: -118.003168702126,
  }

  updateField(field) {
    return (e) => {
      e.preventDefault();
      this.setState({ [field]: e.target.value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const formData = Object.assign({}, this.state);
    this.props.createLocation(formData);
    this.setState({
      name: '',
      lat: this.props.lat,
      lng: this.props.lng,
    });
  }

  handleClick({x, y, lat, lng, event}) {
    this.setState({ lat: lat, lng: lng });
  }

  render() {
    const { locations } = this.props;
    return (
      <div className='page-container'>
        <AddLocation />
        <LocationsTable locations={locations} />
      </div>
    );
  }
}

export default LocationsPage;
