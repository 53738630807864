import { connect } from 'react-redux';
import SearchToolBar from './search_tool_bar';
import { selectOptionFormat } from '../../reducers/selectors';
import {
  receiveSearchTags,
  receiveSearchPhotographer,
  receiveClearSearchPhotographer,
  receiveClearSearchTags,
  searchPhotos,
  clearAllSearchParams,
} from '../../actions/search_actions';
import { createFollowedSearch } from '../../actions/session_actions';

const mapStateToProps = (state) => ({
  currentUser: state.session,
  tags: selectOptionFormat(state.entities.tags),
  photographers: selectOptionFormat(state.entities.users, 'username'),
  numSearchPhotos: state.ui.searchPhotos.photos.length,
  searchTags: state.ui.searchParams.tags,
  searchPhotographer: state.ui.searchParams.photographer,
  searchLocation: state.ui.searchParams.location,
  searchDate: state.ui.searchParams.date,
  searchTime: state.ui.searchParams.time,
  searchTimeEnd: state.ui.searchParams.timeEnd,
  searchActivity: state.ui.searchParams.activity,
  newSearchParams: state.ui.searchParams.newSearcParams,
});

const mapDispatchToProps = (dispatch) => ({
  receiveSearchTags: tags => dispatch(receiveSearchTags(tags)),
  receiveSearchPhotographer: photographer => dispatch(receiveSearchPhotographer(photographer)),
  clearSearchPhotographer: () => dispatch(receiveClearSearchPhotographer()),
  clearSearchTags: () => dispatch(receiveClearSearchTags()),
  searchPhotos: () => dispatch(searchPhotos()),
  createFollowedSearch: (followedSearchParams) => dispatch(createFollowedSearch(followedSearchParams)),
  clearAllSearchParams: () => dispatch(clearAllSearchParams()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchToolBar);
