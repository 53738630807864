const LoadingDots = () => (
  <div className="wp-loading">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadingDots;
