import { ReactElement, SVGProps } from 'react';

export default function InfoIcon({
  className = 'info-icon',
  height = '16',
  width = '17',
}: SVGProps<SVGElement>): ReactElement {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.84058 6.0791L7.6937 6.34014L7.61683 6.68576L8.03871 6.76125C8.31433 6.82491 8.36871 6.92132 8.30871 7.18782L7.61683 10.3421C7.43496 11.1579 7.71527 11.5417 8.37433 11.5417C8.88527 11.5417 9.47871 11.3125 9.74777 10.9978L9.83027 10.6195C9.64277 10.7795 9.36902 10.8432 9.18714 10.8432C8.92933 10.8432 8.83558 10.6677 8.90214 10.3584L9.84058 6.0791Z" fill="white" stroke="white" strokeWidth="0.25"/>
      <path d="M8.96875 5.08956C9.48652 5.08956 9.90625 4.68235 9.90625 4.18003C9.90625 3.67772 9.48652 3.27051 8.96875 3.27051C8.45098 3.27051 8.03125 3.67772 8.03125 4.18003C8.03125 4.68235 8.45098 5.08956 8.96875 5.08956Z" fill="white" stroke="white" strokeWidth="0.25"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 14.1849C10.2405 14.1849 11.9097 13.5141 13.1404 12.3201C14.3711 11.1262 15.0625 9.50676 15.0625 7.81821C15.0625 6.12966 14.3711 4.51026 13.1404 3.31628C11.9097 2.12229 10.2405 1.45152 8.5 1.45152C6.75952 1.45152 5.09032 2.12229 3.85961 3.31628C2.6289 4.51026 1.9375 6.12966 1.9375 7.81821C1.9375 9.50676 2.6289 11.1262 3.85961 12.3201C5.09032 13.5141 6.75952 14.1849 8.5 14.1849ZM8.5 15.0944C10.4891 15.0944 12.3968 14.3278 13.8033 12.9633C15.2098 11.5987 16 9.74798 16 7.81821C16 5.88843 15.2098 4.0377 13.8033 2.67315C12.3968 1.30859 10.4891 0.541992 8.5 0.541992C6.51088 0.541992 4.60322 1.30859 3.1967 2.67315C1.79018 4.0377 1 5.88843 1 7.81821C1 9.74798 1.79018 11.5987 3.1967 12.9633C4.60322 14.3278 6.51088 15.0944 8.5 15.0944Z" fill="white" stroke="white" strokeWidth="0.25"/>
    </svg>
  )
}
