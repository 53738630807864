import { RECEIVE_BANNER_HEIGHT } from '../actions/ui_actions';

const initialState = {
  bannerHeight: 0,
};

const dimensionsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case RECEIVE_BANNER_HEIGHT: {
      let newState = Object.assign({}, state);
      newState.bannerHeight = action.height;
      return newState;
    }
    default:
      return state;
  }
}

export default dimensionsReducer;
