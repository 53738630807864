import React from 'react';

const refundData = {
    rows: [
        {
            title: "Refund Policy",
            content: "All sales are final. Digital photos purchased from the Site are non-returnable after purchase, and you will not receive a refund unless your digital purchase is unviewable, corrupted, or defective. If your digital file meets this criteria, contact support@WunderPics.com and request a refund with the order number in your message. All other requests for refunds that do not meet the stated requirements will not be eligible for a refund."
        }
    ]
}

export default refundData;