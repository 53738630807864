import * as ActivityApiUtil from '../util/api/activity_api_util';

// action types
export const RECEIVE_ACTIVITIES = 'RECEIVE_ACTIVITIES';
export const RECEIVE_ACTIVITY = 'RECEIVE_ACTIVITY';

// action creators
export const receiveActivities = (activities) => ({
  type: RECEIVE_ACTIVITIES,
  activities,
});

export const receiveActivity = (activity) => ({
  type: RECEIVE_ACTIVITY,
  activity,
});

// thunk action creators
export const fetchActivities = () => (dispatch) => (
  ActivityApiUtil.fetchActivities()
    .then((activities) => dispatch(receiveActivities(activities)))
);

export const createActivity = (activity) => (dispatch) => (
  ActivityApiUtil.createActivity(activity)
    .then((activity) => dispatch(receiveActivity(activity)))
);
