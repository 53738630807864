import React from 'react';

const thirdPartyServicesData = {
    rows: [
        {
            title: "Links to Third Party Sites/Third Party Services",
            content: "WunderPics.com may contain links to other websites (\"Linked Sites\"). The Linked Sites are not under the control of WunderPics, Inc. and WunderPics, Inc. is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. WunderPics, Inc. is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by WunderPics, Inc. of the site or any association with its operators."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Certain services made available via WunderPics.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the WunderPics.com domain, you hereby acknowledge and consent that WunderPics, Inc. may share such information and data with any third party with whom WunderPics, Inc. has a contractual relationship to provide the requested product, service or functionality on behalf of WunderPics.com users and customers."
        }
    ]
}

export default thirdPartyServicesData;