import React from 'react';

const LocationIcon = (props) => (
  <svg {...props} width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M7.04004 12.7926C7.13672 12.952 7.30664 13.0582 7.5 13.0582C7.69336 13.0582 7.86328 12.952 7.96289 12.7926L11.1914 7.47383C11.625 6.7582 11.877 5.9707 11.877 5.0582C11.874 2.4207 9.93164 0.392578 7.5 0.392578C5.06836 0.392578 3.12598 2.4207 3.12598 5.0582C3.12598 5.9707 3.37793 6.7582 3.81152 7.47383L7.04004 12.7926ZM7.5 2.39258C8.87988 2.39258 9.99902 3.58633 9.99902 5.0582C9.99902 6.53008 8.87988 7.72383 7.5 7.72383C6.12012 7.72383 5.00098 6.53008 5.00098 5.0582C5.00098 3.58633 6.12012 2.39258 7.5 2.39258ZM10.7051 10.502L7.5 15.0582L4.29492 10.502C3.19922 11.1145 2.49902 12.0301 2.49902 13.0582C2.49902 14.8988 4.7373 16.3926 7.5 16.3926C10.2627 16.3926 12.501 14.8988 12.501 13.0582C12.501 12.0301 11.8008 11.1145 10.7051 10.502Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15" height="16" transform="translate(0 0.392578)"/>
      </clipPath>
    </defs>
  </svg>
);

export default LocationIcon
