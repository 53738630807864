import * as TagApiUtil from '../util/api/tag_api_util';

// action types
export const RECEIVE_TAGS = 'RECEIVE_TAGS';

// action creators
export const receiveTags = (tags) => ({
  type: RECEIVE_TAGS,
  tags
});

// thunk action creators
export const fetchTags = () => (dispatch) => (
  TagApiUtil.fetchTags()
    .then((tags) => dispatch(receiveTags(tags)))
);
