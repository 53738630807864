import { ReactElement, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import PayPalIcon from '../icons/paypal_icon';
import StripeIcon from '../icons/stripe_icon';
import PoweredByStripeIcon from '../icons/powered_by_stripe_icon';
import { Button, Heading, Panel } from '../library';
import { getStripeAccount } from '../../util/api/stripe_api_util';

export default function Payout(): ReactElement {
  const alert = useAlert();
  const [detailsSubmitted, setDetailsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingStripeLink, setIsLoadingStripeLink] = useState<boolean>(false);
  const [hasExternalAccount, setHasExternalAccount] = useState<boolean>(false);
  const [externalAccount, setExternalAccount] = useState<any>({});

  const handlePaypalButtonClick = () => {
    alert.info('Coming soon!');
  }

  useEffect(() => {
    getStripeAccount().
      then((response) => {
        const { stripeAccountDetails } = response;
        if (response.hasStripeAccount && stripeAccountDetails.details_submitted) {
          setDetailsSubmitted(stripeAccountDetails.details_submitted)
          if (stripeAccountDetails.external_accounts.total_count > 0) {
            setHasExternalAccount(true);
            setExternalAccount(stripeAccountDetails.external_accounts.data[0])
          }
        }
      }).
      then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (<Panel className='payout'><p>Loading details...</p></Panel>)
  }

  return (
    <Panel className="payout">
      <Heading level={1}>Payout</Heading>
      <Panel className="connect">
        {detailsSubmitted ?
        <>
          <Heading level={2}>Connected Bank Account</Heading>
          {hasExternalAccount &&
            <p className='external-account'>
              {externalAccount.object === 'card' &&
                <code>{externalAccount.brand} ************{externalAccount.last4}</code>}
              {externalAccount.object === 'bank_account' &&
                <code>{externalAccount.bank_name} {externalAccount.last4}</code>}
              <a className='edit-external-link' href='/api/stripe_express_dashboard'>Edit</a>
            </p>}
          <p className='flex-center'>
            <a href='/api/stripe_express_dashboard'>View Payouts</a>
            <PoweredByStripeIcon className='powered-by-stripe' />
          </p>
        </> :
        <>
          <Heading level={2}>Connect Bank Account</Heading>
          <p>Stripe is easiest and most secure way to get paid instantly.</p>
          <p>
            In order to receive payment for selling your photos, you must connect
            your bank account using Stripe.
          </p>
          {isLoadingStripeLink ?
            <p>Loading...</p> :
            <a
              onClick={() => setIsLoadingStripeLink(true)}
              href='/api/stripe_onboard'
            >
              <Button className="button--stripe">
                Connect with <StripeIcon />
              </Button>
            </a>}
        </>}
      </Panel>
      <Panel className="connect--coming-soon">
        <p className="pseudo-heading">Coming Soon!</p>
        <Heading level={2}>Connect Bank Account</Heading>
        <p>Stripe is easiest and most secure way to get paid instantly.</p>
        <p>
          In order to receive payment for selling your photos, you must connect
          your bank account using Stripe.
        </p>
        <Button className="button--paypal" onClick={handlePaypalButtonClick}>
          Connect with <PayPalIcon /> PayPal
        </Button>
      </Panel>
    </Panel>
  );
}
