class PhotoUploadDataFactory {
  /**
   * Create a list of tags, with hashtag removed and whitespace trimmed
   * [ {name: 'tag1' } ]
   */
  buildPhotoTags(tags) {
    const tagsArray = [...tags]
      // .split(' ')
      // .map((tag) => tag.trim().replace('#',''))
      // .filter((tag, index, tArr) => tArr.indexOf(tag) === index)
      .map(tag => ({ name: tag }));
    return JSON.stringify(tagsArray);
  }

  buildFormData(photo, locationId, activityId, uploadBatchId) {
    const formData = new FormData();
    formData.append('photo[location_id]', locationId);
    formData.append('photo[upload_batch_id]', uploadBatchId);
    formData.append('photo[activity_id]', activityId);
    formData.append('photo[name]', photo.name);
    formData.append('photo[price]', photo.price);
    formData.append('photo[parsed_date]', photo.parsedDate);
    formData.append('photo[parsed_time]', photo.parsedTime);
    formData.append('photo[parsed_timestamp]', photo.parsedTimestamp);
    formData.append('photo[width]', photo.width);
    formData.append('photo[height]', photo.height);
    formData.append('photo[file_type]', photo.type);
    formData.append('photo[size]', photo.size);
    if (photo.tags)
      formData.append('photo[tags_attributes]', this.buildPhotoTags(photo.tags));
    return formData;
  }
}

export default PhotoUploadDataFactory;
