import { connect } from 'react-redux';
import Controls from './controls';
import { logout } from '../../actions/session_actions';


const mapStateToProps = ({ session, entities: { users }, cart }) => ({
  currentUser: users[session.id],
  cart,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Controls);
