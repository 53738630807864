import React from 'react';

const MagnifyingGlassIcon = () => (
  <svg className='magnifying-glass-icon' width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.7383 33.7377C34.0383 33.4373 34.3946 33.199 34.7869 33.0364C35.1791 32.8738 35.5995 32.7901 36.0241 32.7901C36.4486 32.7901 36.8691 32.8738 37.2613 33.0364C37.6535 33.199 38.0098 33.4373 38.3099 33.7377L50.7485 46.1763C51.3547 46.7821 51.6955 47.6039 51.6958 48.4609C51.6961 49.318 51.3559 50.14 50.7501 50.7462C50.1443 51.3525 49.3225 51.6932 48.4654 51.6935C47.6084 51.6938 46.7863 51.3537 46.1801 50.7479L33.7415 38.3092C33.4411 38.0092 33.2028 37.6529 33.0402 37.2607C32.8776 36.8684 32.7939 36.448 32.7939 36.0235C32.7939 35.5989 32.8776 35.1785 33.0402 34.7862C33.2028 34.394 33.4411 34.0377 33.7415 33.7377H33.7383Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.0012 38.7715C23.3349 38.7715 25.6456 38.3119 27.8016 37.4188C29.9576 36.5258 31.9166 35.2169 33.5667 33.5667C35.2169 31.9166 36.5258 29.9576 37.4188 27.8016C38.3119 25.6456 38.7715 23.3349 38.7715 21.0012C38.7715 18.6676 38.3119 16.3568 37.4188 14.2009C36.5258 12.0449 35.2169 10.0859 33.5667 8.43575C31.9166 6.78563 29.9576 5.47668 27.8016 4.58364C25.6456 3.6906 23.3349 3.23096 21.0012 3.23096C16.2883 3.23096 11.7683 5.10318 8.43576 8.43576C5.10318 11.7683 3.23096 16.2883 3.23096 21.0012C3.23096 25.7142 5.10318 30.2342 8.43576 33.5667C11.7683 36.8993 16.2883 38.7715 21.0012 38.7715ZM42.0025 21.0012C42.0025 26.5711 39.7899 31.9129 35.8514 35.8514C31.9129 39.7899 26.5711 42.0025 21.0012 42.0025C15.4314 42.0025 10.0896 39.7899 6.15112 35.8514C2.21262 31.9129 0 26.5711 0 21.0012C0 15.4314 2.21262 10.0896 6.15112 6.15112C10.0896 2.21262 15.4314 0 21.0012 0C26.5711 0 31.9129 2.21262 35.8514 6.15112C39.7899 10.0896 42.0025 15.4314 42.0025 21.0012Z" />
    <path d="M10.335 10.048C9.80463 10.6844 9.89061 11.6303 10.527 12.1606C11.1634 12.691 12.1093 12.605 12.6396 11.9686L10.335 10.048ZM33.0583 16.8003C33.3492 17.576 34.2138 17.969 34.9895 17.6781C35.7651 17.3872 36.1582 16.5226 35.8673 15.7469L33.0583 16.8003ZM12.6396 11.9686C14.6279 9.5827 18.7328 8.11746 22.9614 8.66663C27.0865 9.20237 31.1223 11.6378 33.0583 16.8003L35.8673 15.7469C33.4953 9.4217 28.4367 6.35255 23.3477 5.69162C18.3621 5.04413 13.1333 6.6901 10.335 10.048L12.6396 11.9686Z" />
  </svg>
);

export default MagnifyingGlassIcon;
