import UploadDropImage from 'images/upload-drop-image.png';
import ReactDropzone from 'react-dropzone';
import { formatBytes } from '../../util/function_util';
import PhotoGridPreview from '../generic/photo_grid_preview';

const UploadDropzoneWithPreview = ({photos, handleDrop, onClickButton }) => {
  return(
    <div className='dropzone-container full-width pre-manager-upload-inner'>
      <div className='flex-column'>
        <h3>Upload Photos</h3>
        <div className='row'>
          <div className='col-7 dropzone-image-col'>
            <PhotoGridPreview className='dropzone-photos-preview grid-4' photos={photos} />
          </div>
          <div className='col-5'>
            <ReactDropzone onDrop={handleDrop}>
              {({getRootProps, getInputProps}) => (
                <div className='dropzone-container dropzone-outlined'>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className='dropzone-upload'>
                      <img src={UploadDropImage} />
                      <p className='dropzone-subtitle'>
                        Drag photos to upload
                        <br />
                        or
                        <br />
                        <button className='dropzone-button'>Browse</button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </ReactDropzone>
          </div>
        </div>
        <div className='row dropzone-meta'>
          <div className='col-7'>
            <div className=''>
              Total Images: {`${photos.length}`}<br />
              Total Upload Size: {formatBytes(photos.map(photo => photo.size).reduce((a, b) => a + b, 0))}
            </div>
          </div>
          <div className='col-5'>
            <div className='dropzone-requirements'>
              <strong>Requirements</strong><br/>
              Filetype: JPEG<br />
              Minimum Resolution: 1200px (Long Edge)<br/>
              Maximum File Size: 30MB per photo<br/>
              Compliant with <a target='_blank' href='/help'>Terms & Conditions</a>
            </div>
          </div>
        </div>
        <div className='pre-upload-bottom'>
          <button className='upload-button-large' onClick={onClickButton}>I'm done adding Photos, continue</button>
        </div>
      </div>
    </div>
  );
}

export default UploadDropzoneWithPreview;
