import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';


export default function NotFound(): ReactElement {
  return (
    <div className='page-container text-center not-found'>
      <SearchIcon className='not-found-icon'></SearchIcon>
      <h2>Sorry, this page is not found.</h2>
      <h3>Return <Link className='not-found-link' to={`/`}>home</Link></h3>
    </div>
  )
}
