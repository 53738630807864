export const createCheckoutSession = (): JQuery.jqXHR => (
  $.ajax({
    method: 'POST',
    url: '/api/create_checkout_session',
  })
);

export const stripeOnboard = (): JQuery.jqXHR => (
  $.ajax({
    method: 'POST',
    url: '/api/stripe_onboard'
  })
);

export const getStripeAccount = (): JQuery.jqXHR => (
  $.ajax({
    method: 'GET',
    url: '/api/stripe_account',
  })
);
