import UploadPhotos1 from 'images/uploader_guide/upload-photos-1.png';
import UploadPhotos2 from 'images/uploader_guide/upload-photos-2.png';
import UploadPhotos3 from 'images/uploader_guide/upload-photos-3.png';

const UploadingPhotosData = () => {
  return(
    <div className='article-content-sections'>
      <ul>
        <li>Locate the upload icon on the top Navigation bar</li>
        <img src={UploadPhotos1} alt='Uploading Photos Img1' />
        <li>Click on the “Browse” button or drag and drop photos into the gray rectangular box</li>
        <li>Browse: A new window will appear to locate your images on your computer</li>
        <img src={UploadPhotos2} alt='Uploading Photos Img2' />
        <li>Before uploading photos be sure that they:</li>
        <ul className='listed-items'>
          <li>- Were taken at the same location</li>
          <li>- Are of a single activity</li>
          <li>- Contain EXIF data</li>
        </ul>
        <br />
        <li>Photo Requirements:</li>
        <ul className='listed-items'>
          <li>- File type: JPEG</li>
          <li>- Minimum resolution: 1080 pixels (short edge)</li>
          <li>- Maximum file size per photo: 30mb</li>
          <li>- Compliant with Terms & Conditions</li>
        </ul>
        <br />
        <p>*Photos that do not meet photo requirements will not be available for upload and will encounter an error message</p>
        <img src={UploadPhotos3} alt='Uploading Photos Img3' />
        <li>When you are done importing photos to upload, click on the “I’m done adding photos, continue” button</li>
        <p>You will now be guided through the process of tagging your photos with information that will make them easy to search starting with Location.</p>
      </ul>
    </div>
  )
}

export default UploadingPhotosData
