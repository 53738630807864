import * as LineItemApiUtil from '../util/api/line_item_util';
import * as CartApiUtil from '../util/api/cart_util';

// action types
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';

// action creators
export const removeLineItem = (lineItem) => ({
  type: REMOVE_LINE_ITEM,
  lineItem,
});

export const receiveOrder = (order) => ({
  type: RECEIVE_ORDER,
  order,
});

export const clearOrder = () => ({
  type: CLEAR_ORDER,
});

// thunk action creator
export const deleteLineItem = (lineItem) => (dispatch) => (
  LineItemApiUtil.deleteLineItem(lineItem)
    .then((order) => dispatch(receiveOrder(order)))
);

export const createLineItem = (lineItem) => (dispatch) => (
  LineItemApiUtil.createLineItem(lineItem)
    .then((order) => dispatch(receiveOrder(order)))
);

export const fetchCart = () => (dispatch) => (
  CartApiUtil.fetchCart()
    .then((cart) => dispatch(receiveOrder(cart)))
);
