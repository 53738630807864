import { ComponentPropsWithRef, forwardRef, ReactElement } from 'react';

interface InputProps extends ComponentPropsWithRef<'input'> {
  name: string;
}

export default forwardRef<HTMLInputElement, InputProps>(
  function Input(
    {
      className = 'input',
      name,
      type = 'text',
      children,
      ...props
    }: InputProps,
    ref
  ): ReactElement {
    return (
      <div className={className}>
        <label className={`${className}__label`} htmlFor={name}>
          {children}
        </label>
        <input
          className={`${className}__control`}
          name={name}
          ref={ref}
          type={type}
          {...props}
        />
      </div>
    );
  }
);
