import { connect } from 'react-redux';
import SearchMap from './search_map';
import {
  setSearchLocation,
  searchPhotos,
} from '../../actions/search_actions';
import { asArray } from './../../reducers/selectors';

const mapStateToProps = (state) => ({
  searchLocation: state.ui.searchParams.location,
  locations: state.entities.locations.collection,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchLocation: locationId => dispatch(setSearchLocation(locationId)),
  searchPhotos: () => dispatch(searchPhotos()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMap);
