import Ostrich from 'images/ostrich.jpg';

const NoResults = () => {
  return (
    <div className='stream no-results'>
      <h2>Oops!</h2>
      <p>No results were found for your search<br />Please modify your search and try again</p>
      <img style={{ width: '30%' }} src={Ostrich} />
    </div>
  )
};

export default NoResults;
