import React from 'react';

const communicationUseData = {
    rows: [
        {
            title: "Use of Communication Services",
            content: "The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, \"Communication Services\"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service."
            +
            "<br/>"
            +
            "<br/>"
            +
            "By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations."
            +
            "<br/>"
            +
            "<br/>"
            +
            "WunderPics, Inc. has no obligation to monitor the Communication Services. However, WunderPics, Inc. reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. WunderPics, Inc. reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever."
            +
            "<br/>"
            +
            "<br/>"
            +
            "WunderPics, Inc. reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in WunderPics, Inc.'s sole discretion."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. WunderPics, Inc. does not control or endorse the content, messages or information found in any Communication Service and, therefore, WunderPics, Inc. specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized WunderPics, Inc. spokespersons, and their views do not necessarily reflect those of WunderPics, Inc.."
            +
            "<br/>"
            +
            "<br/>"
            +
            "Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials."
        }
    ]
}

export default communicationUseData;