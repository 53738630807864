import React from 'react';

const faqData = {
  title: "FAQ",
  rows: [
    {
      title: "What is WunderPics",
      content: "WunderPics is a geospatial photo marketplace for action sports photographers to sell their photos and for athletes to buy photos."
    },
    {
      title: "How is WunderPics different from other photo websites?",
      content: "WunderPics is a photo marketplace in which you can search for photos to buy based on their location, date, and activity. WunderPics is not a stock photo site, but rather a place where someone would come to search for an image of themself doing what they love. Professional photographers from around the world use WunderPics to post their photos for sale of people doing awesome activities such as surfing, snowboarding, and rock climbing."
    },
    {
      title: "What type of images should I upload?",
      content: "We want your best shots of people doing awesome things! The creation of WunderPics was inspired by the missed connections between action sports participants and the photographers documenting them. No more handing out business cards or trying to remember someone’s handle."
        +
        "<br />"
        +
        "<br />"
        +
        "WunderPics isn’t a place for your photography portfolio but rather a place for the most recent session you shot. We encourage photographers to upload action shots of people doing what they love as these are the most likely to be bought."
    },
    {
      title: "What is a personal use license?",
      content: "All photos sold on WunderPics are licensed for personal use only. This means that they cannot be used for commercial purposes such as selling a product or promoting a service. Using photos bought on WunderPics for commercial purposes is illegal and subjecting to copyright infringement. Personal use includes posting photos on a personal social media account and printing photos to keep for one’s self."
    },
    {
      title: "Do I still own the rights to my images?",
      content: "Yes, WunderPics does not take any ownership of your images."
    },
    {
      title: "Is WunderPics free?",
      content: "Yes, WunderPics offers free accounts for buyers and sellers. Our free account allows photographers to upload up to 15GB of photos to publish for sale. We offer paid memberships that increase your upload limit. For more information visit our membership page."
    },
    {
      title: "Does WunderPics take any commission?",
      content: "WunderPics takes a commission of 15% from each sale. Fortunately photographers have the ability to set the price of any photo that they publish for sale."
    },
    {
      title: "Is there a limit to how many images I can upload?",
      content: "It depends on the size of your photos. You can upload up to 15GB of photos with a free account and even more with a paid membership. For more information visit our membership page."
    },
    {
      title: "Do you allow refunds?",
      content: "Since photos are instantly available for download after a purchase is completed, we do not allow refunds."
    },
    {
      title: "Why did my photos fail to upload?",
      content: "Your photos may have failed to upload because they did not meet our minimum photo requirements."
        +
        "<br/>"
        +
        "<br/>"
        +
        "Minimum Photo Requirements:"
        +
        "<br/>"
        +
        "• File type: JPEG"
        +
        "<br/>"
        +
        "• Minimum resolution: 1200 horizontal pixels"
        +
        "<br/>"
        +
        "• Maximum file size: 30mb"
        +
        "<br/>"
        +
        "• Compliant with Terms & Conditions"
    },
    {
      title: "How do I sell my photos?",
      content: "In order to sell photos, you must create an account. You can then upload photos and publish them for sale. In order to get paid you must connect a bank account"
    },
    {
      title: "How do I buy photos?",
      content: "Although you do not need an account to purchase photos from WunderPics, we highly recommend creating one to take advantage of all of the features the website has to offer. In order to purchase photos from Wunderpics, simply search for a photo that you would like to purchase and add it to your cart. Check out using a valid payment method and your photo will be ready to download."
    }]
}

const styles = {
  titleTextColor: "black",
  titleTextSize: '25px',
  rowTitleColor: 'black',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '15px',
  rowContentPaddingBottom: '15px',
  rowContentColor: 'grey',
}

export { faqData, styles };
