import { RefObject, useEffect, useRef, useState } from 'react';

export default function useSelectedDescendants<
  ParentNode extends Element = HTMLElement,
  Descendant extends Element = HTMLElement
>(
  selectors: string
): [RefObject<ParentNode>, NodeListOf<Descendant> | undefined | null] {
  const parentRef = useRef<ParentNode>(null);
  const [descendants, setDescendants] = useState<
    NodeListOf<Descendant> | undefined | null
  >(null);

  useEffect(() => {
    const parent = parentRef.current;

    const updateSelectedDescendants = () => {
      setDescendants(parent?.querySelectorAll(selectors));
    };

    updateSelectedDescendants();

    const domObserver = new MutationObserver(() => {
      updateSelectedDescendants();
    });

    if (parent) {
      domObserver.observe(parent, { childList: true });
    }

    return () => {
      if (parent) {
        domObserver.disconnect();
      }
    };
  }, [selectors, parentRef]);

  return [parentRef, descendants];
}
