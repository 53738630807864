import {
  ChangeEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAlert } from 'react-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select, { OptionTypeBase } from 'react-select';
import { Modal, ModalRef } from '../../components/library';
import useActivities from '../../hooks/use_activities';
import { Photo, UploadBatch } from '../../types';
import { destroyPhotos, updatePhotos } from '../../util/api/photo/photo_api_util';
import { getUploadBatchPhotos } from '../../util/api/upload_batch_api_util';
import { useLocations } from '../cartography/hooks';
import GenericLoading from '../generic/generic_loading';
import SEO from '../generic/seo';
import CalendarIcon from '../icons/calendar_icon';
import DollarSignIcon from '../icons/dollar_sign_icon';
import InfoIcon from '../icons/info_icon';
import LocationIcon from '../icons/location_icon';
import SelectAllIcon from '../icons/select_all_icon';
import SurfingIcon from '../icons/surfing_icon';
import TagsIcon from '../icons/tags_icon';
import WhiteXIcon from '../icons/white_x_icon';
import GridPhoto from './grid_photo';
import SetDateAndTime from './set_date_and_time';
import SetLocation from './set_location';
import SinglePhotoModal from './single_photo_modal';

export default function UploadManager(): ReactElement {
  const alert = useAlert();
  const history = useHistory();
  const { activities, selectOptionActivities } = useActivities();
  const { locations } = useLocations();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [batchPhotos, setBatchPhotos] = useState<Photo[]>([]);
  const [bulkPrice, setBulkPrice] = useState<string>('');
  const [bulkTags, setBulkTags] = useState<string>('');
  const [uploadBatch, setUploadBatch] = useState<UploadBatch>();
  const [selectedPhotos, setSelectedPhotos] = useState<{ [key: number]: boolean }>({});
  const [batchLocationValue, setBatchLocationValue] = useState({});
  const [newLocationValue, setNewLocationValue] = useState<OptionTypeBase>({});
  const [batchActivityValue, setBatchActivityValue] = useState<OptionTypeBase>({});
  const [newActivityValue, setNewActivityValue] = useState<OptionTypeBase>({});
  const [photoForModal, setPhotoForModal] = useState('');

  const activityModalRef = useRef<ModalRef>(null);
  const locationModalRef = useRef<ModalRef>(null);
  const dateAndTimeModalRef = useRef<ModalRef>(null);
  const photoModalRef = useRef<ModalRef>(null);

  const query = new URLSearchParams(useLocation().search);
  const batchId = query.get('batchId');
  const numSelected = Object.keys(selectedPhotos).length;
  const tagRegex = /^[a-zA-Z0-9_,]*$/;

  useEffect(() => {
    if (!batchId) {
      history.replace('/dashboard/uploads');
      return;
    }

    getUploadBatchPhotos(batchId).
      then(batch => {
        if (batch.found) {
          setBatchPhotos(batch.photos);
          setUploadBatch(batch.uploadBatch);
          setNewLocationValue({ value: batch.uploadBatch.locationId, label: batch.uploadBatch.locationName });
          setBatchLocationValue({ value: batch.uploadBatch.locationId, label: batch.uploadBatch.locationName });
          setNewActivityValue({ value: batch.uploadBatch.activityId, label: batch.uploadBatch.activityName });
          setBatchActivityValue({ value: batch.uploadBatch.activityId, label: batch.uploadBatch.activityName });
        } else {
          history.replace('/dashboard/uploads');
          return;
        }
      })
      .then(() => setIsLoading(false));
  }, [batchId]);

  const selectPhoto = (photoId: number) => {
    return () => {
      if (selectedPhotos[photoId]) {
        const newSelectedPhotos = Object.assign({}, selectedPhotos);
        delete newSelectedPhotos[photoId];
        setSelectedPhotos(newSelectedPhotos);
      } else {
        setSelectedPhotos(Object.assign({}, selectedPhotos, { [photoId]: true }));
      }
    }
  }

  const selectAllPhotos = () => {
    const newSelectedPhotos: { [key: number]: boolean } = {};
    batchPhotos.forEach((photo) => {
      newSelectedPhotos[photo.id] = true
    });
    setSelectedPhotos(newSelectedPhotos);
  }

  const updateBulkPrice: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBulkPrice(e.target.value);
  }

  const updateBulkTags: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (tagRegex.test(e.target.value))
      setBulkTags(e.target.value);
  }

  const clearSelectedPhotos = () => {
    setSelectedPhotos({});
  }

  const handleUpdatePhotosTags = () => {
    const ids = Object.keys(selectedPhotos);
    const newTags = bulkTags.split(',').filter(Boolean);

    if (!ids.length)
      return;

    updatePhotos({ batchId, ids, newTags }).
      then((batch) => {
        alert.success(`Updated tags for ${ids.length} photos.`);
        setBulkTags('');
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
      });
  }

  const handleUpdatePhotosPrice = () => {
    const ids = Object.keys(selectedPhotos);
    if (!ids.length)
      return;

    updatePhotos({ batchId, ids, price: bulkPrice })
      .then((batch) => {
        alert.success(`Updated price for ${ids.length} photos.`);
        setBulkPrice('');
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
      });
  }

  const cleanBulkPrice = () => {
    if (bulkPrice) {
      const price = parseFloat(bulkPrice);
      const cleanPrice = price.toFixed(2);
      setBulkPrice(cleanPrice);
    }
  }

  const openActivityModal = useCallback(
    () => activityModalRef.current?.open(),
    [activityModalRef]
  );

  const closeActivityModal = useCallback(
    () => {
      activityModalRef.current?.close(),
        setNewActivityValue(batchActivityValue);
    },
    [activityModalRef]
  );

  const openLocationModal = useCallback(
    () => locationModalRef.current?.open(),
    [locationModalRef]
  );

  const closeLocationModal = useCallback(
    () => {
      locationModalRef.current?.close();
      setNewLocationValue(batchLocationValue);
    },
    [locationModalRef, batchLocationValue]
  );

  const openDateAndTimeModal = useCallback(
    () => dateAndTimeModalRef.current?.open(),
    [dateAndTimeModalRef]
  );

  const closeDateAndTimeModal = useCallback(
    () => dateAndTimeModalRef.current?.close(),
    [dateAndTimeModalRef]
  )

  const openPhotoModal = useCallback(
    () => photoModalRef.current?.open(),
    [photoModalRef]
  );

  const closePhotoModal = useCallback(
    () => photoModalRef.current?.close(),
    [photoModalRef]
  );

  const showPhotoModal = (photo) => {
    setPhotoForModal(photo);
    openPhotoModal();
  }

  const handleDeleteTag = (tag: string) => {
    const ids = Object.keys(selectedPhotos);
    updatePhotos({ batchId, ids, destroyTagName: tag })
      .then((batch) => {
        alert.show(`Removed tag: "${tag}" for ${ids.length} photos.`);
        setBulkPrice('');
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
      })
  }

  const handleDeletePhotos = () => {
    const ids = Object.keys(selectedPhotos);
    destroyPhotos({ batchId, ids: Object.keys(selectedPhotos) })
      .then((batch) => {
        alert.show(`Deleted ${ids.length} photos.`);
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
        setSelectedPhotos({});
      });
  }

  const tagsContent = () => {
    const currentTags: { [key: string]: number } = {};
    batchPhotos.forEach((photo) => {
      if (selectedPhotos[photo.id]) {
        photo.tags.forEach((tag) => {
          currentTags[tag] = (currentTags[tag] || 0) + 1;
        });
      }
    });

    return (
      <div className='col-12 text-center'>
        <span className='current-tags-header'>Current tags:</span>
        <div className='current-tags'>
          {!Object.keys(currentTags) ?
            `No tags for the current selection` :
            Object.keys(currentTags).map((tag, i) =>
              <span key={i}
                className={currentTags[tag] === numSelected ? 'tag all-tag' : 'tag'}
              >
                {tag}
                <a className='tag-delete' onClick={() => {
                  if (window.confirm(`Are you sure you want to delete the tag: "${tag}" from the selected photos?`)) handleDeleteTag(tag)
                }}>&#xd7;</a>
              </span>)
          }
        </div>
      </div>
    );
  }

  const handleSelectNewLocation = (location: OptionTypeBase) => {
    setNewLocationValue(location)
  }

  const handleSubmitLocation = () => {
    updatePhotos({ batchId, locationId: newLocationValue.value })
      .then((batch) => {
        alert.success(`Updated location to ${batch.uploadBatch.locationName} for ${batchPhotos.length} photos.`);
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
        setNewLocationValue({ value: batch.uploadBatch.locationId, label: batch.uploadBatch.locationName });
        setBatchLocationValue({ value: batch.uploadBatch.locationId, label: batch.uploadBatch.locationName })
      });
    closeLocationModal();
  }

  const handleSubmitActivity = () => {
    updatePhotos({ batchId, activityId: newActivityValue.value })
      .then((batch) => {
        alert.success(`Updated activity to ${batch.uploadBatch.activityName} for ${batchPhotos.length} photos.`);
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
        setNewActivityValue({ value: batch.uploadBatch.activityId, label: batch.uploadBatch.activityName });
        setBatchActivityValue({ value: batch.uploadBatch.activityId, label: batch.uploadBatch.activityName });
      })
    closeActivityModal();
  }

  const handleSubmitUpdateTimestamps = (delta) => {
    updatePhotos({ batchId, delta })
      .then(batch => {
        alert.success(`Updated date and time for ${batchPhotos.length} photos.`);
        setBatchPhotos(batch.photos);
        setUploadBatch(batch.uploadBatch);
      })

    closeDateAndTimeModal();
  }

  const sortedBatchPhotos = () => {
    return (
      batchPhotos.sort((a, b) => a.photoTimestamp - b.photoTimestamp)
    );
  }

  if (!batchId) {
    history.replace('/dashboard/uploads');
  }

  if (isLoading) {
    return <GenericLoading />;
  }

  return (
    <>
      <SEO title='Upload Manager' />
      <div className='upload-page-container upload-manager-container'>
        <div id='upload-photo-manager'>
          <div className='upload-sidebar'>
            <div className='upload-sidebar-header'>
              Upload Manager <a onClick={() => alert.show('info')}><InfoIcon /></a>
            </div>
            <div className='sidebar-editors'>
              <div className='sidebar-global-editor'>
                <div className='global-editor'>
                  <div className='row no-gutters'>
                    <div className='col-2'>
                      <LocationIcon className='location-icon' />
                    </div>
                    <div className='col-5'>
                      <h4>Location</h4>
                    </div>
                    <div className='col-5 edit-all'>
                      <button
                        className='btn btn-primary'
                        onClick={openLocationModal}
                      >
                        Edit All
                      </button>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    <div className='col-10 offset-2'>
                      <em>{uploadBatch.locationName}</em>
                    </div>
                  </div>
                </div>
                <div className='global-editor'>
                  <div className='row no-gutters'>
                    <div className='col-2'>
                      <CalendarIcon className='icon-gray-fill' />
                    </div>
                    <div className='col-5'>
                      <h4>Date &amp; Time</h4>
                    </div>
                    <div className='col-5 edit-all'>
                      <button
                        className='btn btn-primary'
                        onClick={openDateAndTimeModal}
                      >
                        Edit All
                      </button>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    <div className='col-10 offset-2'>
                      <em>{uploadBatch.captureBeginDisplay} - {uploadBatch.captureEndDisplay}</em>
                    </div>
                  </div>
                </div>
                <div className='global-editor'>
                  <div className='row no-gutters'>
                    <div className='col-2'>
                      <SurfingIcon className='icon-gray-fill' />
                    </div>
                    <div className='col-5'>
                      <h4>Activity</h4>
                    </div>
                    <div className='col-5 edit-all'>
                      <button
                        className='btn btn-primary'
                        onClick={openActivityModal}
                      >
                        Edit All
                      </button>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    <div className='col-10 offset-2'>
                      <em>{uploadBatch.activityName}</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sidebar-individual-editor'>
                <div className='individual-editor'>
                  <div className='row no-gutters'>
                    <div className='col-2'>
                      <DollarSignIcon className='icon-gray-fill' />
                    </div>
                    <div className='col-10'>
                      <h4>Price</h4>
                      <p>Update price for {numSelected} photos</p>
                      <input
                        className='form-control'
                        type='text'
                        value={bulkPrice}
                        onChange={updateBulkPrice}
                        onBlur={cleanBulkPrice}
                        placeholder='New Price'
                      />
                      <div className='text-right'>
                        <button
                          disabled={numSelected == 0 || !bulkPrice}
                          onClick={handleUpdatePhotosPrice}
                          className='btn btn-primary'
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='individual-editor'>
                  <div className='row no-gutters'>
                    <div className='col-2'>
                      <TagsIcon className='icon-gray-fill' />
                    </div>
                    <div className='col-10'>
                      <h4>Tags</h4>
                      <p>Update tags for {numSelected} photos</p>
                      <input
                        className='form-control'
                        type='text'
                        value={bulkTags}
                        onChange={updateBulkTags}
                        placeholder='Type tags here' />
                      <div className='text-right'>
                        <button
                          disabled={numSelected == 0 || !bulkTags}
                          className='btn btn-primary'
                          onClick={handleUpdatePhotosTags}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    {numSelected > 0 && tagsContent()}
                  </div>
                </div>
              </div>
            </div>
            <div className='sidebar-actions'>
              <p><strong>{numSelected}</strong> - Photos Selected</p>
              <button
                disabled={numSelected === 0}
                className='btn btn-secondary'
                onClick={() => {
                  if (window.confirm(`Are you sure you want to delete ${numSelected} photos?`)) handleDeletePhotos()
                }}
              >
                Delete Photos
              </button>
              <br />
              <Link to='/dashboard/uploads'>
                <button className='btn btn-primary'>Finish</button>
              </Link>
            </div>
          </div>
          <div className='upload-main'>
            <div className='upload-manager-toolbar'>
              <div className='tool-bar-filters'>
              </div>
              <div className='toolbar-right'>
                <span onClick={selectAllPhotos}>
                  <SelectAllIcon className='space-right'/>
                  Select All Photos
                </span>
                <span onClick={clearSelectedPhotos}>
                  <WhiteXIcon className='space-right' />
                  Clear Selection
                </span>
                <span>Showing {`${batchPhotos.length}/${batchPhotos.length}`} Photos</span>
              </div>
            </div>
            <div className='upload-photos'>
              {sortedBatchPhotos().map((photo, index) =>
                <GridPhoto
                  showPhotoModal={showPhotoModal}
                  key={index}
                  photo={photo}
                  selectPhoto={selectPhoto(photo.id)}
                  selected={selectedPhotos[photo.id] || false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className='activity-modal' ref={activityModalRef}>
        <h2>What is the Activity for these photos?</h2>
        <Select
          name='upload-activity'
          placeholder='Search Activity...'
          value={newActivityValue}
          className='upload-activity-select upload-select'
          classNamePrefix='upload-select'
          onChange={setNewActivityValue}
          options={selectOptionActivities}
        />
        <hr />
        <div className='modal-buttons'>
          <button
            className='btn'
            onClick={() => closeActivityModal()}
          >
            Close
          </button>
          <button
            className='btn'
            onClick={handleSubmitActivity}
          >
            Set Activity
          </button>
        </div>
      </Modal>
      <Modal className='location-modal' ref={locationModalRef}>
        <h2>Select a Location for your Photos</h2>
        <SetLocation
          value={newLocationValue || batchLocationValue}
          locations={locations}
          handleSelectLocation={handleSelectNewLocation}
          cancelClick={closeLocationModal}
          handleSubmit={handleSubmitLocation}
        />
      </Modal>
      <Modal className='date-and-time-modal' ref={dateAndTimeModalRef}>
        <h2>Date &amp; Time</h2>
        <SetDateAndTime
          photos={batchPhotos}
          cancelClick={closeDateAndTimeModal}
          updatePhotos={handleSubmitUpdateTimestamps}
        />
      </Modal>
      <SinglePhotoModal
        closePhotoModal={closePhotoModal}
        photo={photoForModal}
        modalRef={photoModalRef}
      />
    </>
  );
}
