import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Footer from './../footer';
import FollowedSearches from './followed_searches';
import PastOrders from './past_orders';
import SalesReport from './sales_report';
import Uploads from './uploads';
import DashboardHome from './home/dashboard_home';
import DashboardCarousel from './dashboard_carousel';

const Dashboard = (props) => {

  const { match } = props;

  return (
    <>
      <div id='dashboard' className='main-inner'>
        <DashboardCarousel />
        <div id='dashboard-pane'>
          <nav>
            <NavLink activeClassName='dashboard-tab-active' to={match.url + '/home'}>Home</NavLink>
            <NavLink activeClassName='dashboard-tab-active' to={match.url + '/followed-searches'}>Followed Searches</NavLink>
            <NavLink activeClassName='dashboard-tab-active' to={match.url + '/past-orders'}>Past Orders</NavLink>
            <NavLink activeClassName='dashboard-tab-active' to={match.url + '/sales-reports'}>Sales Report</NavLink>
            <NavLink activeClassName='dashboard-tab-active' to={match.url + '/uploads'}>Uploads</NavLink>
          </nav>
        </div>
        <div id='dashboard-display'>
          <Switch>
            <Route exact path={match.url + '/home'} component={DashboardHome} />
            <Route exact path={match.url + '/followed-searches'} component={FollowedSearches} />
            <Route exact path={match.url + '/past-orders'} component={PastOrders} />
            <Route exact path={match.url + '/sales-reports'} component={SalesReport} />
            <Route exact path={match.url + '/uploads'} component={Uploads} />
            <Redirect to={match.url + '/home'} />
          </Switch>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
