import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import DateTools from '../../util/date_tools';
import GroupImagesIcon from '../icons/group_images_icon';
import MorePhotosLeft from '../icons/more_photos_left';
import UploadPhotoCard from './upload_photo_card';

export default function SetDateAndTime({
  photos,
  cancelClick,
  updatePhotos,
}): ReactElement {

  const [firstPhoto, setFirstPhoto] = useState({});
  const [stagedFirstPhoto, setStagedFirstPhoto] = useState({});
  const [lastPhoto, setLastPhoto] = useState('');
  const [newFirstPhoto, setNewFirstPhoto] = useState('');
  const [newLastPhoto, setNewLastPhoto] = useState('');
  const [displayEditor, setDisplayEditor] = useState(false);
  const [displayDelta, setDisplayDelta] = useState(false);
  const [delta, setDelta] = useState(0);
  const moreThanOnePhoto = photos.length > 1;

  useEffect(() => {
    const sortedPhotos = photos.sort((a, b) => (a.photoTimestamp > b.photoTimestamp) ? 1 : -1);
    setFirstPhoto(sortedPhotos[0]);
    setStagedFirstPhoto(sortedPhotos[0]);
    setLastPhoto(sortedPhotos[sortedPhotos.length - 1]);
  }, []);

  const handleSelectStagedFirstPhoto = (jsDate) => {
    setStagedFirstPhoto(
      DateTools.createParsedTimesAndObject(jsDate.toISOString())
    );
  }

  const revertStagedPhoto = () => {
    setDisplayDelta(false);
    setDelta(0);
    setStagedFirstPhoto(
      DateTools.createParsedTimesAndObject(firstPhoto.photoTimestamp)
    );
  }

  const updateDeltaAndTimes = () => {
    setDisplayDelta(true);

    const deltaInMillis = DateTools.calculateDeltaInMilliseconds(
      firstPhoto.photoTimestamp,
      stagedFirstPhoto.photoTimestamp,
    );

    setNewFirstPhoto(
      DateTools.addDeltaMilliseconds(firstPhoto.photoTimestamp, deltaInMillis)
    );

    setNewLastPhoto(
      DateTools.addDeltaMilliseconds(lastPhoto.photoTimestamp, deltaInMillis)
    );

    setDelta(deltaInMillis);
  }

  const renderDeltaDisplay = () => {
    const { shifts, plusOrMinus } = DateTools.getShifts(firstPhoto.photoTimestamp, newFirstPhoto.photoTimestamp);
    const plusOrMinusClass = classNames('plus-or-minus', {
      'red': (plusOrMinus === '- minus'),
      'green': (plusOrMinus === '+ plus'),
    });

    const nonZeroShifts = Object.entries(shifts.values).
      filter(([_, value]) => value > 0).
      map(([timeUnit, value]) => value > 0 ? `${value.toFixed(0)} ${timeUnit}` : null).join(', ');

    return (
      <div>
        <span className={plusOrMinusClass}>{plusOrMinus}</span>
        <span>
           {nonZeroShifts}
        </span>
      </div>
    )
  }

  const handleSetNewTimeRange = () => {
    updatePhotos(delta);
  }

  const firstPhotoDate = new Date(firstPhoto.photoTimestamp);
  const stagedFirstPhotoDate = new Date(stagedFirstPhoto.photoTimestamp);

  return (
      displayEditor ?
        <>
          <h4>Correct the capture time of your <strong>first</strong> photo to shift the capture times of all photos</h4>
          <div className='row time-edit'>
            <div className='col-6'>
              <div className='time-edit-first-photo'>
                <div>
                  <label>First Photo</label>
                  <div>
                    <div>
                      <img src={firstPhoto.thumbnailUrl} />
                    </div>
                    <MorePhotosLeft />
                  </div>
                </div>
                <p>+{photos.length - 1}<br/>More Photos</p>
              </div>
              <div className='time-edit-table'>
                <table>
                  <tbody>
                    <tr>
                      <td>Original Time:</td>
                      <td>
                        <DatePicker
                          readOnly
                          formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
                          showTimeInput
                          className='original-time-input'
                          calendarClassName='wup-datepicker'
                          dateFormat='MMMM d, yyyy h:mm aa'
                          selected={firstPhotoDate}
                          timeCaption='Start Time'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Corrected Time:</td>
                      <td>
                        <DatePicker
                          popperPlacement='top-start'
                          showPopperArrow={false}
                          formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
                          showTimeInput
                          className='corrected-time-input'
                          calendarClassName='wup-datepicker'
                          dateFormat='MMMM d, yyyy h:mm aa'
                          selected={stagedFirstPhotoDate}
                          onChange={handleSelectStagedFirstPhoto}
                          timeCaption='Start Time'
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='time-edit-buttons'>
                <button
                  onClick={revertStagedPhoto}
                  className='btn'
                >
                  Revert to original time
                </button>
                <button
                  onClick={updateDeltaAndTimes}
                  className='btn'
                  disabled={firstPhotoDate.getDate() === stagedFirstPhotoDate.getDate()}
                >
                  Apply Correction
                </button>
              </div>
            </div>
            <div className='col-6'>
              {displayDelta &&
                <div className='pre-upload-time-preview'>
                  <h5>New Capture Times</h5>
                  <div className='new-range'>
                    <div className='range-date'>
                      {newFirstPhoto.parsedDate}<br />
                      {newFirstPhoto.parsedTime}<br />
                    </div>
                    {newLastPhoto.parsedDate &&
                      <>
                        <div className='new-range-divider'></div>
                        <div className='range-date'>
                          {newLastPhoto.parsedDate}<br />
                          {newLastPhoto.parsedTime}<br />
                        </div>
                      </>
                    }
                  </div>
                  <div className='delta-display'>
                    <p>Capture time of all photos shifted</p>
                    {renderDeltaDisplay()}
                  </div>
                </div>}
            </div>
          </div>
          <hr />
          <div className='modal-buttons'>
            <button
              className='btn'
              onClick={() => setDisplayEditor(false)}
            >
              Cancel
            </button>
            <button
              className='btn'
              disabled={delta === 0}
              onClick={handleSetNewTimeRange}
            >
              Confirm, set new time range
            </button>
          </div>
        </> :
        <>
          <h4>We have determined that these photos were taken at this time. Is this correct?</h4>
          <div className='row pre-upload-time-check no-gutters'>
            {moreThanOnePhoto ?
            <>
              <div className='col-4'>
                <UploadPhotoCard
                  photo={firstPhoto}
                  photoCardLabel='First photo taken:'
                />
              </div>
              <div className='photo-card-connect col-4'>
                <GroupImagesIcon />
                <p>
                  <strong>
                    +{photos.length-2}<br/>
                    More Photos
                  </strong>
                </p>
              </div>
              <div className='col-4'>
                <UploadPhotoCard
                  photo={lastPhoto}
                  // timezone={timezone}
                  photoCardLabel='Last photo taken:'
                />
              </div>
            </> :
            <UploadPhotoCard
              photo={firstPhoto}
              photoCardLabel='Photo taken at:'
            />}
          </div>
          <hr />
          <div className='modal-buttons'>
            <button
              onClick={cancelClick}
              className='btn'
            >
              Yes, Date &amp; Time are correct
            </button>
            <button
              onClick={() => setDisplayEditor(true)}
              className='btn'
            >
              No, Edit Date &amp; Time
            </button>
          </div>
        </>
  );
}
