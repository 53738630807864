import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Heading } from '../library';
import { runWithdrawalJob } from '../../util/api/jobs_api_util';

interface SalesSummaryHeaderProps {
  photosSold: number;
  totalProfit: string;
  balance: string;
}

export default function SalesSummaryHeader({
  photosSold,
  totalProfit,
  balance,
}: SalesSummaryHeaderProps): ReactElement {

  const alert = useAlert();
  const {
    stripeDetailsSubmitted
  } = useSelector(state => state.session);

  const handleWithdrawalClick = () => {
    runWithdrawalJob()
      .then(() => alert.success(
        'Withdraw request submitted! Please wait 1-2 days before attempting to withdraw again.', {timeout:30000}))
      .catch(() => alert.error('Something went wrong. Please contact support.'));
  }

  return (
    <div className='row'>
      <div className='col-4'>
        <div className='dashboard-card'>
          <div className='sales-card'>
            <Heading level={3}>Total Photos Sold</Heading>
            <span className='large'>{photosSold}</span>
          </div>
        </div>
      </div>
      <div className='col-4'>
        <div className='dashboard-card'>
          <div className='sales-card'>
            <Heading level={3}>Total Profit</Heading>
            <span className='large'>{totalProfit}</span>
          </div>
        </div>
      </div>
      <div className='col-4'>
        <div className='dashboard-card'>
        <div className='sales-card'>
            <Heading level={3}>Balance</Heading>
            <span className='large zero-balance'>{balance}</span><br/>
            {!stripeDetailsSubmitted && <Link to='/settings/payout' className='blue-button'>Connect Bank Account</Link>}
            {stripeDetailsSubmitted && balance !== '$0.00' &&
              <a
                className='blue-button'
                onClick={handleWithdrawalClick}
              >
                Withdraw Balance
              </a>}
          </div>
        </div>
      </div>
    </div>
  );
}
