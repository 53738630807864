import React from 'react';

const BuyerGuideData = {
  title: "Buyer Guide",
  rows: [
    {
      title: "Searching for photos",
      content: "In order to search for photos you must start with entering a Location. Once you have entered a location, you can refine your search by filtering the Date & Time, Activity, Keyword Tags, and Photographer. If your search does not yield results, try the “Follow Search” feature to be notified when new images get uploaded."
    },
    {
      title: "Follow a search",
      content:
        <div>
          Photographers upload photos at varying rates sometimes immediately after they have finished shooting and sometimes days later. We recommend using the “Follow Search” function to stay up to date when photographers upload new images. <br /><br />After submitting a filtered search of a location that matches the times in which you were doing a particular activity, simply click the “Follow Search” button and you will be notified every time a photographer uploads new photos for sale.<br /><br />You can access your Followed Searches by clicking the “Followed Searches” tab in your Dashboard.
        </div>
    },
    {
      title: "Share a search",
      content: <div>Want to share some great photos you found? Click the “Share Search” button to get a shareable link to your filtered search results.ack and edit your uploads, by accessing the Upload Manager tab in your dashboard.
      </div>
    },
    {
      title: "Purchasing photos",
      content: <div>In order to purchase a photo click on the photo to see a larger preview. In this expanded view you can add the photo to your cart by clicking the cart symbol. When you are ready to check out, click the cart symbol in the upper right corner of the top bar to be taken to your cart. Checkout using a valid payment method and your photo will be ready to download.
      </div>
    },
    {
      title: "Download previously purchased photos",
      content: <div>You can access and download your previously purchased photos by clicking the “Purchased Photos” tab in your Dashboard.
      </div>
    },
    {
      title: "Share a photo",
      content: "You can share individual photos, by clicking on the Share button in the expanded view of a photo. This will give you a shareable link to a single photo."
    },
    {
      title: "Flag an inappropriate photo",
      content: "After setting the Location, Date & Time, and Activity, you will be taken to the Upload Manager where you can set the price and tag keywords for individual photos. You can also continue to edit the Location, Date & Time, and Activity."
    },
    {
      title: "Set the price of photos",
      content: "You can flag an inappropriate photo, but clicking the flag symbol in the expanded view of a photo. You will be prompted to select the reason why you are flagging the photo. For more information on what is an inappropriate photo please read our Terms & Conditions"
    }
  ]
}

const BuyerGuideStyles = {
  titleTextColor: "black",
  titleTextSize: '25px',
  rowTitleColor: 'black',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '15px',
  rowContentPaddingBottom: '15px',
  rowContentColor: 'grey',
}

export { BuyerGuideData, BuyerGuideStyles };
