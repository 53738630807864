import React from 'react';

const LocationsTable = ({ locations }) => (
  <div className='locations-table'>
    <h3>Recent Locations</h3>
    <table className='table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>City</th>
          <th>Region</th>
          <th>Country</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Timezone</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((loc,i) => (
          <tr key={i}>
            <td>{loc.name}</td>
            <td>{loc.city}</td>
            <td>{loc.region}</td>
            <td>{loc.country}</td>
            <td>{loc.lat}</td>
            <td>{loc.lng}</td>
            <td>{loc.hours_offset} {loc.timezone_abbreviation} {loc.timezone_name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default LocationsTable;
