import classNames from 'classnames';
import MagnifyGray from 'images/icons/magnify-gray.svg';
import SurfGray from 'images/icons/surfing-gray.svg';
import React from 'react';
import { withAlert } from 'react-alert';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';
import DateTools from '../../util/date_tools';
import ClearXIcon from '../icons/clear_x_icon';
import SearchArrow from './../icons/search_arrow';
import WupDropdown from './wup_dropdown';

const ActivityControl = ({ children, ...props }) => {
  const { onActivityClear, displayClear } = props.selectProps;

  return (
    <components.Control {...props}>
      {displayClear && <ClearXIcon className='clear-x-search activity-x' onClick={onActivityClear} />}
      {children}
    </components.Control>
  );
};

const LocationControl = ({ children, ...props }) => {
  const { onLocationClear, displayClear } = props.selectProps;

  return (
    <components.Control {...props}>
      {displayClear && <ClearXIcon className='clear-x-search' onClick={onLocationClear} />}
      {children}
    </components.Control>
  );
}

class SearchBar extends React.Component {
  locationStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#555555',
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '3rem 0px 0px 3rem',
      boxShadow: 'none',
      borderColor: '#AEAEAE',
      color: '#555555',
      padding: '0.4rem',
      height: '46px',
      borderRight: 'none',
      borderWidth: '2px',
      '&:focus': {
        borderColor: '#AEAEAE',
      },
      '&:hover': {
        borderColor: '#AEAEAE',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
      fontWeight: '700',
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 0 0 3rem',
      backgroundImage: `url('${MagnifyGray}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '1.2rem',
      backgroundSize: '16px',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '1000',
      border: '2px solid #000',
    }),
  };

  activityStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#555555',
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0px 3rem 3rem 0px',
      boxShadow: 'none',
      borderColor: '#AEAEAE',
      color: '#555555',
      padding: '0.4rem',
      height: '46px',
      borderWidth: '2px',
      borderLeft: 'none',
      '&:focus': {
        borderColor: '#AEAEAE',
      },
      '&:hover': {
        borderColor: '#AEAEAE',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
      fontWeight: '700',
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 0 0 2.1rem',
      backgroundImage: `url('${SurfGray}')`,
      backgroundSize: '14px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0.6rem',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '1000',
      border: '2px solid #000',
    }),
  };

  searchBarStyles = {
    display: 'flex',
  };

  constructor(props) {
    super(props);

    this.dateTimeDropdown = React.createRef();
    this.searchButton = React.createRef();
    this.renderDateDropdown = this.renderDateDropdown.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.handleSelectTime = this.handleSelectTime.bind(this);
    this.handleSelectTimeEnd = this.handleSelectTimeEnd.bind(this);
    this.handleSelectActivity = this.handleSelectActivity.bind(this);
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.focusSearchButton = this.focusSearchButton.bind(this);
    this.onActivityClear = this.onActivityClear.bind(this);
    this.onLocationClear = this.onLocationClear.bind(this);
    this.onReceiveClearSearchDateAndTime = this.onReceiveClearSearchDateAndTime.bind(this);
  }

    /**
   * Ex: Wed Apr 07 2021 00:00:00 GMT-0700 (Pacific Daylight Time)
   *
   * @param {Date string} value new Date().toString()
   */
  handleSelectDate(value) {
    const isoDate = value.toISOString();
    this.props.receiveSearchDate({
      label: DateTools.formatSearchDate(isoDate),
      display: DateTools.formatDisplayDate(value.toISOString()),
      value: value,
    });
    this.props.receiveSearchString();
    this.focusSearchButton();
  }

  handleSelectTime(value) {
    if (!value)
      return;

      this.props.receiveSearchTime({
      value,
      label: DateTools.formatLabelTime(value.toISOString())
    });
    this.focusSearchButton();
  }

  handleSelectTimeEnd(value) {
    if (!value)
      return;

    this.props.receiveSearchTimeEnd({
      value,
      label: DateTools.formatLabelTime(value.toISOString())
    });
    this.focusSearchButton();
  }

  async handleSelectActivity(activity) {
    this.props.receiveSearchActivity(activity);
    this.props.receiveSearchString();
    this.focusSearchButton();

    this.handleSearch();
  }

  async handleSelectLocation(location) {
    if (location) {
      await this.props.setSearchLocation(location.value);
      this.props.receiveSearchString();
      this.focusSearchButton();

      this.handleSearch();
    }
  }

  handleSearch() {
    if (!this.props.searchLocation.value) {
      this.props.alert.show(`Please select a location to search`);
      return;
    }

    if (this.props.location.pathname !== '/search') {
      this.props.history.push('/search?' + this.props.searchString);
    } else {
      this.props.searchPhotos();
    }
  }

  focusSearchButton() {
    this.searchButton.current.focus();
  }

  onActivityClear() {
    this.props.receiveClearSearchActivity();
  }

  onLocationClear() {
    this.props.receiveClearSearchLocation();
  }

  onReceiveClearSearchDateAndTime() {
    this.props.receiveClearSearchDateAndTime();
  }

  renderDateDropdown() {
    return (
      <div className='search-bar-date-and-time'>
        <div className='search-bar-date'>
          <DatePicker
            formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
            inline
            calendarClassName='wup-datepicker'
            dateFormat="yyyy-MM-dd"
            selected={this.props.searchDate.value}
            onChange={this.handleSelectDate}
            timeCaption={'Start Time'}
          />
        </div>
        <div className='search-bar-time'>
          <div className='search-bar-time-date'>{this.props.searchDate.display}</div>
          <div className='search-time-inputs'>
            <DatePicker
              selected={this.props.searchTime.value}
              onChange={this.handleSelectTime}
              className='wup-datepicker first-time-input'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mmaa"
            />
            <DatePicker
              selected={this.props.searchTimeEnd.value}
              onChange={this.handleSelectTimeEnd}
              className='wup-datepicker second-time-input'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mmaa"
              filterTime={(time) =>
                this.props.searchTime.value?.getTime() < time.getTime() }
            />
          </div>
          <div className='row no-gutters'>
            <div className='col'>
              Start
            </div>
            <div className='col'>
              End
            </div>
          </div>
          <button
            onClick={async () => {
              await this.dateTimeDropdown.current.handleClickOutside();
              this.handleSearch();
            }}
            className='btn btn-primary'
          >
            Confirm
          </button>
        </div>
      </div>
    )
  }


  // handleCloseModal() {
  //   useCallback(
  //     () => modalRef.current?.close(),
  //     [modalRef]
  //   );
  // }

  // handleOpenModal() {
  //   useCallback(
  //     () => modalRef.current?.open(),
  //     [modalRef]
  //   );
  // }

  render() {
    const {
      searchLocation,
      locations,
      searchActivity,
      activities,
      searchDate,
      newSearchParams,
    } = this.props;

    const searchBarButtonClasses = classNames('search-bar-go', {
      'new-search-params': this.props.searchLocation.value && newSearchParams,
    });
    const searchDateClasses = classNames({
      'search-date-active': Boolean(searchDate.value),
    });

    const mql = window.matchMedia('(max-device-width: 481px)');
    const isMobile = mql.matches;

    return (
      <>
        <div
          className='search-bar'
          style={this.searchBarStyles}
        >
          <Select
            styles={this.locationStyles}
            placeholder='Where were you...'
            value={searchLocation.value ? searchLocation : null}
            className='search-location-select search-bar-pill'
            classNamePrefix='search-location-select'
            onChange={this.handleSelectLocation}
            onLocationClear={this.onLocationClear}
            displayClear={!!searchLocation.value}
            options={locations}
            components={{ Control: LocationControl }}
          />
          {/* {isMobile ?
            <>
              <span className='search-border-left'></span>
              <div className='search-bar-date-label' onClick={() => {}}>
                <span className={searchDateClasses}>{searchDate.display}</span>
                {searchDate.value && <ClearXIcon className='clear-x-search' onClick={this.onReceiveClearSearchDateAndTime} />}
              </div>
              <span className='search-border-right'></span>
            </> : */}
          <WupDropdown
            ref={this.dateTimeDropdown}
            className='search-bar-date search-bar-pill'
            label={
              <>
                <span className='search-border-left'></span>
                <div className='search-bar-date-label'>
                  <span className={searchDateClasses}>{searchDate.display}</span>
                  {searchDate.value && <ClearXIcon className='clear-x-search' onClick={this.onReceiveClearSearchDateAndTime} />}
                </div>
                <span className='search-border-right'></span>
              </>
            }
            dropdown={this.renderDateDropdown()}
            callBack={() => this.searchButton.current.focus()}
            contentWrapperClassName='search-bar-date-and-time-content'
          />
          <Select
            value={searchActivity.value ? searchActivity : null}
            placeholder='Activity'
            styles={this.activityStyles}
            className='wup-select-dropdown search-bar-pill'
            classNamePrefix='search-activity-select'
            onChange={this.handleSelectActivity}
            displayClear={!!searchActivity.value}
            options={activities}
            onActivityClear={this.onActivityClear}
            components={{ Control: ActivityControl }}
          />
          <button ref={this.searchButton} className={searchBarButtonClasses} onClick={this.handleSearch}>
            <SearchArrow className='search-bar-arrow' />
          </button>
        </div>
        <button
          onClick={this.handleSearch}
          className='mobile-search-button'
        >
          Search
        </button>

        {/* <Modal className="calendar-modal" ref={modalRef}>
          <form className="photo-modal__form">
            <Heading level={1}>Image Properties</Heading>
            <Input autoFocus name="name">
              Name
            </Input>
            <Input
              className="input--price"
              name="price"
              type="number"
              step="0.01"
              placeholder="0.00"
            >
              Price
            </Input>
            <div className="photo-modal__tags">
              <label className="input__label">Tags</label>
              <span>None</span>
            </div>
            <Panel>
              <Metadata className="static-data">
                <Detail label="Location:">location</Detail>
                <Detail label="Activity:">activity</Detail>
                <Detail label="Time taken:">time taken</Detail>
                <Detail label="Dimensions:">dimensions</Detail>
                <Detail label="File Type:">filetype</Detail>
              </Metadata>
            </Panel>
            <Button className="photo-modal__button" onClick={handleCloseModal}>
              Close
            </Button>
          </form>
          <div className="photo-modal__image">image container</div>
        </Modal> */}
      </>
    )
  }
}

export default withRouter(withAlert()(SearchBar));
