// action types
export const RECEIVE_FOCUSED_PHOTO = 'ui/receiveFocusedPhoto';
export const RECEIVE_USER_ON_UPLOADING = 'ui/receiveOnUserUploading';
export const RECEIVE_USER_LEAVING_UPLOAD = 'ui/receiveUserLeavingUpload';
export const RECEIVE_BANNER_HEIGHT = 'ui/receiveBannerHeight';

// action creators
export const receiveFocusedPhoto = (photoId) => ({
  type: RECEIVE_FOCUSED_PHOTO,
  payload: photoId,
});

export const receiveUserOnUpload = () => ({
  type: RECEIVE_USER_ON_UPLOADING,
});

export const receiveUserLeavingUpload = () => ({
  type: RECEIVE_USER_LEAVING_UPLOAD,
});

export const receiveBannerHeight = (height) => ({
  type: RECEIVE_BANNER_HEIGHT,
  height,
});
